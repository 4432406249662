import submissions from "./submissions";
import initialDecisions from "./initialDecisions";
import timeToInitialDecisions from "./timeToDecision";
import rejects from "./rejects";
import accept from "./accept";
import finalDecision from "./finalDecision";
import timeToFinalDecision from "./timeToFinalDecision";
import reviewers from "./reviewers";
import acceptFinalDecisions from "./acceptFinalDecisions";
import rejectFinalDecisions from "./rejectFinalDecisions";
import timeInPeerReview from "./timeInPeerReview";
import rejectionRate from "./rejectionRate";
import numberOfEditors from "./numberOfEditors";
import editorAssignments from "./editorAssignments";
import numberOfCompletedReviews from "./numberOfCompletedReviews";
import numberOfInvitations from "./numberOfInvitations";
import recommendationDecision from "./recommendationDecision";
import AssignmentInitialDecision from "./AssignmentInitialDecision";
import finalRecommendationDecision from "./finalRecommendationDecision";
import invitedToAgreed from "./invitedToAgreed";
import invitedToCompleted from "./invitedToCompleted";
import agreedToCompleted from "./agreedToCompleted";
import initialRecommendationDecisionCompleted from "./initialRecommendationDecisionCompleted";
import totalTimeWithPeerReviewers from "./totalTimeWithPeerReviewers";
import numberOfReviewers from "./numberOfReviewers";
import numberOfUniqueReviewersInvited from "./numberOfUniqueReviewersInvited";
import numberOfReviewersInvitedPerManuscripts from "./numberOfReviewersInvitedPerManuscripts";
import timeFromEditorAssignmentToFirstRreviewerIinvited from "./timeFromEditorAssignmentToFirstRreviewerIinvited";
import initialDecisionCompleted from "./initialDecisionCompleted";
import timeFromFirstReviewerInvitationToLastReviewerInvitation from "./timeFromFirstReviewerInvitationToLastReviewerInvitation"
// import timeToDraftSynthesisLetter from "./timeToDraftSynthesisLetter"
import timeToDecisionLetter from "./timeToDecisionLetter"
import timeToCompleteSynthesisDecisionLetter from "./timeToCompleteSynthesisDecisionLetter"
// import timeFromLastReviewCompletedToStartOfDraftSynthesisLetter from "./timeFromLastReviewCompletedToStartOfDraftSynthesisLetter"
import timeToDraftSynthLetter from "./timeToDraftSynthLetter";
import timeToCompleteDecisionLetter from "./timeToCompleteDecisionLetter";
import aeAssignments from './aeAssignments/index';
import timeFromEditorAssignmentToEditorAssignmentDecision from './timeFromEditorAssignmentToEditorAssignmentDecision/index.js';
import aeChangedBeforeDecisionLetter from "./aeChangedBeforeDecisionLetter";
import timeFromSubmissionToAEAssignment from "./timeFromSubmissionToAEAssignment";
import timeFromPCORIFinalReviewToFinalAcceptance from './timeFromPCORIFinalReviewToFinalAcceptance';
import numberOfSubmissions from "./numberOfSubmissions";
import submissionsReturnedToAuthor from "./submissionsReturnedToAuthor"
import numberOfDecisions from "./numberOfDecisions"
import numberOfUniqueReviewers from './numberOfUniqueReviewers';
import timeForAuthorCorrections from "./timeForAuthorCorrections"
import timeForAuthorRevisions from "./timeForAuthorRevisions";
import numberOfManuscripts from './numberOfManuscripts/index'
import initialDecisionsBasedOnDecisionDate from "./initialDecisionsBasedOnDecisionDate"
import initialDecisionsBasedOnSubmissionDate from "./initialDecisionsBasedOnSubmissionDate"
import numberOfConsultingEditorsRequested from "./numberOfConsultingEditorsRequested"
import timeToInitialDecisionsBasedOnDecisionDate from "./timeToInitialDecisionsBasedOnDecisionDate"
import finalDecisionsBasedOnSubmissionDate from "./finalDecisionsBasedOnSubmissionDate"
import finalDecisionsBasedOnDecisionDate from "./finalDecisionsBasedOnDecisionDate"
import timeToInitialDecisionsBasedOnSubmissionDate from "./timeToInitialDecisionsBasedOnSubmissionDate"
import submissionsPassedInitialQC from "./submissionsPassedInitialQC"
import rejectedInitialBasedOnDecisionDate from "./rejectedInitialBasedOnDecisionDate"
import rejectedFinalBasedOnDecisionDate from "./rejectedFinalBasedOnDecisionDate"
import rejectedInitialBasedOnSubmissionDateQC from "./rejectedInitialBasedOnSubmissionDateQC"

const chartNamesList = {
  submissions,
  initialDecisions,
  rejects,
  accept,
  finalDecision,
  timeToInitialDecisions,
  reviewers,
  timeToFinalDecision,
  acceptFinalDecisions,
  rejectFinalDecisions,
  timeInPeerReview,
  rejectionRate,
  numberOfEditors,
  numberOfCompletedReviews,
  numberOfInvitations,
  recommendationDecision,
  AssignmentInitialDecision,
  finalRecommendationDecision,
  invitedToAgreed,
  invitedToCompleted,
  agreedToCompleted,
  initialRecommendationDecisionCompleted,
  totalTimeWithPeerReviewers,
  numberOfReviewers,
  numberOfUniqueReviewersInvited,
  numberOfReviewersInvitedPerManuscripts,
  editorAssignments,
  timeFromEditorAssignmentToFirstRreviewerIinvited,
  initialDecisionCompleted,
  timeFromFirstReviewerInvitationToLastReviewerInvitation,
  // timeToDraftSynthesisLetter,
  timeToDecisionLetter,
  timeToCompleteSynthesisDecisionLetter,
  // timeFromLastReviewCompletedToStartOfDraftSynthesisLetter,
  timeToDraftSynthLetter,
  timeToCompleteDecisionLetter,
  aeAssignments,
  timeFromEditorAssignmentToEditorAssignmentDecision,
  timeFromSubmissionToAEAssignment,
  timeFromPCORIFinalReviewToFinalAcceptance,
  aeChangedBeforeDecisionLetter,
  numberOfSubmissions,
  submissionsReturnedToAuthor,
  numberOfDecisions,
  numberOfUniqueReviewers,
  timeForAuthorCorrections,
  timeForAuthorRevisions,
  numberOfManuscripts,
  primaryCategory: submissions,
  initialDecisionsBasedOnDecisionDate,
  initialDecisionsBasedOnSubmissionDate,
  numberOfConsultingEditorsRequested,
  timeToInitialDecisionsBasedOnDecisionDate,
  finalDecisionsBasedOnSubmissionDate,
  finalDecisionsBasedOnDecisionDate,
  timeToInitialDecisionsBasedOnSubmissionDate,
  submissionsPassedInitialQC,
  rejectedInitialBasedOnDecisionDate,
  rejectedFinalBasedOnDecisionDate,
  rejectedInitialBasedOnSubmissionDateQC
};
// 0 for ScholarOne
// 1 for Editorial Manager

const allReportTypesList = [
  { "key": "Submissions & Decisions CSV" },
  { "key": "Select (required)", "value": 0, "name": "none" },
  { "key": "Submissions", "value": 1, "name": "submissions" },
  { "key": "Initial Decisions", "value": 2, "name": "initialDecisions" },
  { "key": "% Reject - initial decisions", "value": 3, "name": "rejects" },
  { "key": "% Accept - initial decisions", "value": 4, "name": "accept" },
  { "key": "Final Decisions", "value": 5, "name": "finalDecision" },
  { "key": "Time to Initial Decision", "value": 6, "name": "timeToInitialDecisions" },
  { "key": "Time to Final Decision", "value": 10, "name": "timeToFinalDecision" },
  { "key": "% Accept - Final Decisions", "value": 11, "name": "acceptFinalDecisions" },
  { "key": "% Rejected - Final Decisions", "value": 22, "name": "rejectFinalDecisions" },
  { "key": "Editor rejection rate - initial decisions", "value": 13, "name": "rejectionRate" },
  { "key": "Number of Editors", "value": 14, "name": "numberOfEditors" },
  { "key": "Time from Editor Assignment to Initial Decision", "value": 17, "name": "recommendationDecision" },
  { "key": "Time from initial editor assignment to final decision", "value": 18, "name": "finalRecommendationDecision" },
  { "key": "Time from last review completed to initial decision", "value": 23, "name": "initialRecommendationDecisionCompleted" },
  { "key": "Number of unique reviewers invited", "value": 26, "name": "numberOfUniqueReviewersInvited" },
  { "key": "Number of reviewers invited per manuscript", "value": 27, "name": "numberOfReviewersInvitedPerManuscripts" },
  { "key": "Editor assignments", "value": 28, "name": "editorAssignments" },
  { "key": "TFR (Time from editor assignment to first reviewer invited)", "value": 30, "name": "timeFromEditorAssignmentToFirstRreviewerIinvited" },
  { "key": "Time to complete an individual review", "value": 12, "name": "timeInPeerReview" },
  { "key": "Number of completed reviews", "value": 15, "name": "numberOfCompletedReviews" },
  { "key": "Number of responses to invitations", "value": 16, "name": "numberOfInvitations" },
  { "key": "Invited to agreed conversion rate", "value": 19, "name": "invitedToAgreed" },
  { "key": "Invited to completed conversion rate", "value": 20, "name": "invitedToCompleted" },
  { "key": "Agreed to completed conversion rate", "value": 21, "name": "agreedToCompleted" },
  { "key": "Total time with peer reviewers", "value": 24, "name": "totalTimeWithPeerReviewers" },
  { "key": "Number of reviewers", "value": 25, "name": "numberOfReviewers" },
  { "key": "Time from initial editor assignment to final decision", "value": 18, "name": "finalRecommendationDecision" },
  { "key": "Time from Editor Assignment to Initial Decision", "value": 31, "name": "AssignmentInitialDecision", "type": "standard" },
  { "key": "Time from last review completed to initial decision", "value": 32, "name": "initialDecisionCompleted", "type": "standard1" },
  { "key": "Initial Decisions based on decision date", "value": 52, "name": "initialDecisionsBasedOnDecisionDate" },
  { "key": "Initial Decisions based on submission date", "value": 53, "name": "initialDecisionsBasedOnSubmissionDate" },
  { "key": "Time to Initial Decisions based on decision date", "value": 54, "name": "timeToInitialDecisionsBasedOnDecisionDate" },
  { "key": "Time to Initial Decisions based on submission date", "value": 57, "name": "timeToInitialDecisionsBasedOnSubmissionDate" },
  { "key": "% Rejected-Initial based on decision date", "value": 59, "name": "rejectedInitialBasedOnDecisionDate" },
  { "key": "% Rejected-Initial based on submission date (submissions that passed initial qc)", "value": 61, "name": "rejectedInitialBasedOnSubmissionDateQC" },
  { "key": "Final decisions based on decision date", "value": 56, "name": "finalDecisionsBasedOnDecisionDate" },
  { "key": "Final decisions based on submission date", "value": 55, "name": "finalDecisionsBasedOnSubmissionDate" },
  { "key": "% Rejected-Final based on decision date", "value": 60, "name": "rejectedFinalBasedOnDecisionDate" },
  { "key": "Submissions: Passed Initial QC", "value": 58, "name": "submissionsPassedInitialQC" },
  { "key": "Time from PCORI final review to final acceptance", "value": 42, "name": "timeFromPCORIFinalReviewToFinalAcceptance" },
  { "key": "Time from AE assignment to first reviewer invited", "value": 30, "name": "timeFromEditorAssignmentToFirstRreviewerIinvited" },
  { "key": "Time from first reviewer invitation to last reviewer invitation", "value": 33, "name": "timeFromFirstReviewerInvitationToLastReviewerInvitation" },
  { "key": "Time to draft synthesis letter", "value": 35, "name": "timeToDraftSynthLetter" },
  { "key": "Time to complete synthesis decision letter", "value": 38, "name": "timeToCompleteSynthesisDecisionLetter" },
  { "key": "Time to complete decision letter", "value": 37, "name": "timeToCompleteDecisionLetter" },
  { "key": "AE assignments", "value": 39, "name": "aeAssignments" },
  { "key": "Time from AE assignment to AE decision", "value": 40, "name": "timeFromEditorAssignmentToEditorAssignmentDecision" },
  { "key": "AE changed before decision letter", "value": 43, "name": "aeChangedBeforeDecisionLetter" },
  { "key": "Time from submission to AE assignment", "value": 41, "name": "timeFromSubmissionToAEAssignment" },
  { "key": "Number of submissions", "value": 44, "name": "numberOfSubmissions" },
  { "key": "Submissions returned to author", "value": 45, "name": "submissionsReturnedToAuthor" },
  { "key": "Number of decisions", "value": 46, "name": "numberOfDecisions" },
  { "key": "Number of unique reviewers", "value": 47, "name": "numberOfUniqueReviewers" },
  { "key": "Time for author corrections", "value": 48, "name": "timeForAuthorCorrections" },
  { "key": "Number of manuscripts", "value": 49, "name": "numberOfManuscripts" },
  { "key": "Time For author revisions", "value": 50, "name": "timeForAuthorRevisions" },
  { "key": "Number of consulting editors requested", "value": 51, "name": "numberOfConsultingEditorsRequested" }
]


const reportTypesList = {
  0: [
    { key: "Submissions & Decisions CSV" },
    { key: "Select (required)", value: 0, name: "none" },
    { key: "Submissions", value: 1, name: "submissions" },
    { key: "Initial Decisions", value: 2, name: "initialDecisions" },
    { key: "% Reject - initial decisions", value: 3, name: "rejects" },
    { key: "% Accept - initial decisions", value: 4, name: "accept" },
    { key: "Final Decisions", value: 5, name: "finalDecision" },
    { key: "Time to Initial Decision", value: 6, name: "timeToInitialDecisions" },
    { key: "Time to Final Decision", value: 10, name: "timeToFinalDecision" },
    { key: "% Accept - Final Decisions", value: 11, name: "acceptFinalDecisions", },
    { key: "% Rejected - Final Decisions", value: 22, name: "rejectFinalDecisions", },

    { key: "Editor & Reviewer CSV" },
    { key: "Editor rejection rate - initial decisions", value: 13, name: "rejectionRate", },
    { key: "Number of Editors", value: 14, name: "numberOfEditors" },
    { key: "Time from Editor Assignment to Initial Decision", value: 17, name: "recommendationDecision", },
    { key: "Time from initial editor assignment to final decision", value: 18, name: "finalRecommendationDecision", },
    { key: "Time from last review completed to initial decision", value: 23, name: "initialRecommendationDecisionCompleted", },
    { key: "Number of unique reviewers invited", value: 26, name: "numberOfUniqueReviewersInvited", },
    { key: "Number of reviewers invited per manuscript", value: 27, name: "numberOfReviewersInvitedPerManuscripts", },
    { key: "Editor assignments", value: 28, name: "editorAssignments" },
    { key: "TFR (Time from editor assignment to first reviewer invited)", value: 30, name: "timeFromEditorAssignmentToFirstRreviewerIinvited", },

    { key: "Time to complete an individual review", value: 12, name: "timeInPeerReview", },
    { key: "Number of completed reviews", value: 15, name: "numberOfCompletedReviews", },
    { key: "Number of responses to invitations", value: 16, name: "numberOfInvitations", },
    { key: "Invited to agreed conversion rate", value: 19, name: "invitedToAgreed", },
    { key: "Invited to completed conversion rate", value: 20, name: "invitedToCompleted", },
    { key: "Agreed to completed conversion rate", value: 21, name: "agreedToCompleted", },
    { key: "Total time with peer reviewers", value: 24, name: "totalTimeWithPeerReviewers" }, // 15, 12 21 20 19 16
    { key: "Number of reviewers", value: 25, name: "numberOfReviewers" },
  ],
  1: [
    { key: "Submissions & Decisions CSV" },
    { key: "Select (required)", value: 0, name: "none" },
    { key: "Submissions", value: 1, name: "submissions" },
    { key: "Initial Decisions", value: 2, name: "initialDecisions" },
    { key: "% Reject - initial decisions", value: 3, name: "rejects" },
    { key: "% Accept - initial decisions", value: 4, name: "accept" },
    { key: "Final Decisions", value: 5, name: "finalDecision" },
    { key: "Time to Initial Decision", value: 6, name: "timeToInitialDecisions", },
    { key: "Time to Final Decision", value: 10, name: "timeToFinalDecision" },
    { key: "% Accept - Final Decisions", value: 11, name: "acceptFinalDecisions", },
    { key: "% Rejected - Final Decisions", value: 22, name: "rejectFinalDecisions", },

    { key: "Editor & Reviewer CSV" },
    { key: "Editor rejection rate - initial decisions", value: 13, name: "rejectionRate", },
    { key: "Number of Editors", value: 14, name: "numberOfEditors" },
    { key: "Time from initial editor assignment to final decision", value: 18, name: "finalRecommendationDecision", },
    { key: "Number of unique reviewers invited", value: 26, name: "numberOfUniqueReviewersInvited", },
    { key: "Number of reviewers invited per manuscript", value: 27, name: "numberOfReviewersInvitedPerManuscripts", },
    { key: "Editor assignments", value: 28, name: "editorAssignments" },
    { key: "TFR (Time from editor assignment to first reviewer invited)", value: 30, name: "timeFromEditorAssignmentToFirstRreviewerIinvited", },
    { key: "Time from Editor Assignment to Initial Decision", value: 31, name: "AssignmentInitialDecision", type: "standard", },
    { key: "Time from last review completed to initial decision", value: 32, name: "initialDecisionCompleted", type: "standard1", },

    // { key: "Time from Editor Assignment to Initial Decision", value: 17, name: "recommendationDecision" },
    // { key: "Time from last review completed to initial decision", value: 23, name: "initialRecommendationDecisionCompleted" }, 25, 15, 24,12,21, 20, 19,16
    // { key: "Reviewers", value: 8, name: "reviewers" },
    { key: "Time to complete an individual review", value: 12, name: "timeInPeerReview", },
    { key: "Number of completed reviews", value: 15, name: "numberOfCompletedReviews", },
    { key: "Number of responses to invitations", value: 16, name: "numberOfInvitations", },
    { key: "Invited to agreed conversion rate", value: 19, name: "invitedToAgreed", },
    { key: "Invited to completed conversion rate", value: 20, name: "invitedToCompleted", },
    { key: "Agreed to completed conversion rate", value: 21, name: "agreedToCompleted", },
    { key: "Total time with peer reviewers - initial decision", value: 24, name: "totalTimeWithPeerReviewers", },
    { key: "Number of reviewers", value: 25, name: "numberOfReviewers" },
    // { key: "Time from First Reviewer Invitation to Last Reviewer Invitation", value: 33, name: "timeFromFirstReviewerInvitationToLastReviewerInvitation" },
    // { key: "Time to complete synthesis letter", value: 34, name: "timeToDraftSynthesisLetter" },
    // { key: "Time to Decision Letter", value: 35, name: "timeToDecisionLetter" },
  ],
  2: [
    { key: "Submissions & Decisions CSV" },
    { key: "Select (required)", value: 0, name: "none" },
    { key: "Submissions", value: 1, name: "submissions" },
    // { key: "Initial Decisions", value: 2, name: "initialDecisions" },
    { key: "Initial Decisions based on decision date", value: 52, name: "initialDecisionsBasedOnDecisionDate" },
    { key: "Initial Decisions based on submission date", value: 53, name: "initialDecisionsBasedOnSubmissionDate" },
    { key: "Time to Initial Decisions based on decision date", value: 54, name: "timeToInitialDecisionsBasedOnDecisionDate" },
    { key: "Time to Initial Decisions based on submission date", value: 57, name: "timeToInitialDecisionsBasedOnSubmissionDate" },
    { key: "% Rejected-Initial based on decision date", value: 59, name: "rejectedInitialBasedOnDecisionDate" },
    { key: "% Rejected-Initial based on submission date (submissions that passed initial qc)", value: 61, name: "rejectedInitialBasedOnSubmissionDateQC" },
    { key: "Final decisions based on decision date", value: 56, name: "finalDecisionsBasedOnDecisionDate" },
    { key: "Final decisions based on submission date", value: 55, name: "finalDecisionsBasedOnSubmissionDate" },
    { key: "% Rejected-Final based on decision date", value: 60, name: "rejectedFinalBasedOnDecisionDate" },
    { key: "Submissions: Passed Initial QC", value: 58, name: "submissionsPassedInitialQC" },
    // { key: "Time to Initial Decision", value: 6, name: "timeToInitialDecisions", },
    // { key: "Primary category", value: 52, name: "primaryCategory", },

  ],
  3: [
    { key: "Editor & Reviewer CSV" },
    { key: "Select (required)", value: 0, name: "none" },
    { key: "Submissions", value: 1, name: "submissions" },
    { key: "Time to Initial Decision", value: 6, name: "timeToInitialDecisions" },
    { key: "Final Decisions", value: 5, name: "finalDecision" },
    { key: "Time from PCORI final review to final acceptance", value: 42, name: "timeFromPCORIFinalReviewToFinalAcceptance", },
    { key: "Time to Final Decision", value: 10, name: "timeToFinalDecision" },
    { key: "Number of responses to invitations", value: 16, name: "numberOfInvitations", },
    { key: "Invited to agreed conversion rate", value: 19, name: "invitedToAgreed", },
    { key: "Invited to completed conversion rate", value: 20, name: "invitedToCompleted", },
    { key: "Agreed to completed conversion rate", value: 21, name: "agreedToCompleted", },
    { key: "Time to complete an individual review", value: 12, name: "timeInPeerReview", },
    { key: "Time from AE assignment to first reviewer invited", value: 30, name: "timeFromEditorAssignmentToFirstRreviewerIinvited", },
    { key: "Time from first reviewer invitation to last reviewer invitation", value: 33, name: "timeFromFirstReviewerInvitationToLastReviewerInvitation" },
    { key: "Time to draft synthesis letter", value: 35, name: "timeToDraftSynthLetter" },
    { key: "Time to complete synthesis decision letter", value: 38, name: 'timeToCompleteSynthesisDecisionLetter' },
    { key: "Time to complete decision letter", value: 37, name: "timeToCompleteDecisionLetter" },
    { key: "AE assignments", value: 39, name: "aeAssignments" },
    { key: "Time from AE assignment to AE decision", value: 40, name: "timeFromEditorAssignmentToEditorAssignmentDecision" },
    { key: "AE changed before decision letter", value: 43, name: "aeChangedBeforeDecisionLetter", },
    { key: "Number of reviewers invited per manuscript", value: 27, name: "numberOfReviewersInvitedPerManuscripts", },
    // { key: "Time from last review completed to start of draft synthesis letter", value: 34, name: "timeFromLastReviewCompletedToStartOfDraftSynthesisLetter"},
    // { key: "Time to decision letter", value: 36, name: "timeToDraftSynthesisLetter" },
    { key: "Time from submission to AE assignment", value: 41, name: "timeFromSubmissionToAEAssignment", },
    { key: "Total time with peer reviewers", value: 24, name: "totalTimeWithPeerReviewers", },
    { key: "Number of submissions", value: 44, name: "numberOfSubmissions" },
    { key: "Submissions returned to author", value: 45, name: "submissionsReturnedToAuthor" },
    { key: "Number of decisions", value: 46, name: "numberOfDecisions", },
    { key: "Number of unique reviewers", value: 47, name: "numberOfUniqueReviewers", },
    { key: "Time for author corrections", value: 48, name: "timeForAuthorCorrections" },
    { key: "Number of manuscripts", value: 49, name: "numberOfManuscripts", },
    { key: "Time For author revisions", value: 50, name: "timeForAuthorRevisions", },
    { key: "Number of consulting editors requested", value: 51, name: "numberOfConsultingEditorsRequested" },
  ],
};

const allVariables = [
  { key: "Select (required)", value: 0, name: "none" },
  { key: "Countries", value: 2, name: "countries", hideEjp: true },
  { key: "Continents", value: 3, name: "continents", hideEjp: true },
  { key: "Continents with China breakout", value: 4, name: "continentsWithChinaBreakout" },
  { key: "Section/Category", value: 6, name: "sectionCategory", hideEjp: true, hideS1: true, hideEM: false, },
  { key: "Decision types", value: 2, name: "decisionTypes" },
  { key: "Revision Number", value: 2, name: "revisionNumber" },
  { key: "Manuscript types", value: 2, name: "manuscriptType" },
  { key: "Time from Editor Assignment to Initial Decision", value: 1, name: "timeToInitialDecisionFrEdAss", },
  { key: "Time period", value: 1, name: "time" },
  { key: "Editor's country", value: 2, name: "countryEditor" },
  { key: "TFR (Time from editor assignment to first reviewer invited)", value: 3, name: "editAssignFirstRevInvited" },
  { key: "Time from Editor Assignment to Initial Decision", value: 4, name: "assignmentInitialDecision" },
  { key: "Time from Last Review Completed to Initial Decision", value: 5, name: "initialDecisionCompleted" },
  { key: "Number of unique reviewers invited", value: 6, name: "numberOfUniqueReviewersInvited" },
  { key: "Number of reviewers invited per manuscrip", value: 7, name: "numberOfReviewersInvitedPerManuscripts" },
  { key: "Editors", value: 1, name: "editors" },
  { key: "Response to Invitations", value: 1, name: "responseToInvitations", },
  { key: "Outcome of invitation - completed", value: 1, name: "outcomeOfInvitationsSbm", },
  { key: "Outcome of invitation – agree/decline", value: 3, name: "outcomeOfInvitationsAgreeDecline", },
  { key: "Initial Decisions", value: 3, name: "initialDecisions", hideEM: true, },
  { key: "Continent - Reviewer", value: 3, name: "continents" },
  { key: "Associate Editor", value: 1, name: "associateEditors", },
  { key: "Reviewer type", value: 3, name: "reviewerType", },
  { key: "Letter Stage", value: 2, name: "letterStage", },
  { key: "Review Type", value: 8, name: "reviewType", },
  { key: "Submission type", value: 7, name: "submissionType", hideEjp: true, hideS1: true, hideEM: true, },
  { key: "highestRevisionNumber", value: 6, name: 'highestRevisionNumber', hideS1: true, hideEM: true, },
  { key: "Stage name", value: 2, name: "stageName", hideS1: true, hideEM: true, },
  { key: "Total returns to author before AE/PCORI assignment", value: 10, name: 'totalReturnsToAuthorBeforeAEPCORIAssignment', hideS1: true, hideEM: true, },
  { key: "Frequency of occurrence", value: 2, name: "frequencyOfOccurrence", hideEjp: true, hideS1: true, hideEM: true, },
  { key: "Primary category", value: 8, name: "primaryCategory", },
  { key: "Associate Editor requesting CE", value: 2, name: "associateEditorRequestingCE", hideS1: true, hideEM: true, hideEjp: true },
]

const variablesList = {
  none: {
    variablesList1: [{ key: "Select (required)", value: 0, name: "none" }],
    variablesList2: [{ key: "Select (required)", value: 0, name: "none" }],
  },
  timeToDecisionLetter: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Associate Editor", value: 1, name: "associateEditors", },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ]
  },
  timeFromFirstReviewerInvitationToLastReviewerInvitation: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none", hideEM: true },
      { key: "Associate Editor", value: 1, name: "associateEditors", hideEM: true },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ]
  },
  timeToDraftSynthLetter: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Associate Editor", value: 1, name: "associateEditors", },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ]
  },
  timeToDraftSynthesisLetter: {
    variablesList1: [
      { key: "all", value: 0, name: "none" },
      { key: "Associate Editor", value: 1, name: "associateEditors", },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Associate Editor", value: 1, name: "associateEditors", },
      { key: "Letter Stage", value: 2, name: "letterStage", },
    ]
  },
  timeToCompleteDecisionLetter: {
    variablesList1: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Associate Editor", value: 1, name: "associateEditors", },
      { key: "Revision Number", value: 2, name: "revisionNumber" },
    ],
    variablesList2: [
      { key: "Associate Editor", value: 1, name: "associateEditors", },
      { key: "Letter Stage", value: 2, name: "letterStage", },
      { key: "Revision Number", value: 3, name: "revisionNumber" },
    ]
  },
  aeAssignments: {
    variablesList1: [
      { key: "Associate Editor", value: 1, name: "associateEditors", },
    ],
    variablesList2: [
      { key: "Revision Number", value: 2, name: "revisionNumber" },
    ]
  },
  timeFromEditorAssignmentToEditorAssignmentDecision: {
    variablesList1: [
      { key: "Associate Editor", value: 1, name: "associateEditors", },
    ],
    variablesList2: [
      { key: "Revision Number", value: 2, name: "revisionNumber" },
    ]
  },
  timeToCompleteSynthesisDecisionLetter: {
    variablesList1: [
      { key: "Associate Editor", value: 1, name: "associateEditors", },
      { key: "Revision Number", value: 2, name: "revisionNumber", hideRv: true },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Associate Editor", value: 1, name: "associateEditors", },
    ]
  },
  submissions: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Countries", value: 2, name: "countries", hideRv: true },
      { key: "Continents", value: 3, name: "continents", hideRv: true },
      { key: "Continents with China breakout", value: 4, name: "continentsWithChinaBreakout", hideS1: true, hideEM: true,  hideRv: true,},
      { key: "Manuscript types", value: 4, name: "manuscriptType", hideEjp: true, hideRv: true },
      { key: "Revision Number", value: 5, name: "revisionNumber", hideEjp: true, hideS1: true, hideEM: true, },
      { key: "Section/Category", value: 6, name: "sectionCategory", hideEjp: true, hideS1: true, hideEM: false, hideRv: true },
      { key: "Primary category", value: 8, name: "primaryCategory", hideS1: true, hideEM: true,  hideRv: true,},
    ],
    variablesList1ejp: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Primary category", value: 2, name: "primaryCategory", hideS1: true, hideEM: true, },
      { key: "Countries", value: 3, name: "countries", },
      { key: "Continents", value: 4, name: "continents" },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time", hideEjp: true },
      { key: "Countries", value: 2, name: "countries", hideEjp: true },
      { key: "Continents", value: 3, name: "continents", hideEjp: true },
      { key: "Manuscript types", value: 4, name: "manuscriptType", hideEjp: true, },
      { key: "Section/Category", value: 6, name: "sectionCategory", hideEjp: true, },
      { key: "Revision Number", value: 5, name: "revisionNumber", hideEjp: true, },
      { key: "Submission type", value: 7, name: "submissionType", hideEjp: true, hideS1: true, hideEM: true, },
      { key: "Primary category", value: 8, name: "primaryCategory", hideS1: true, hideEM: true, },
      { key: "Continents with China breakout", value: 9, name: "continentsWithChinaBreakout", hideRv: true, hideS1: true, hideEM: true },
    ],
    variablesList2ejp: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Manuscript types", value: 1, name: "manuscriptType", },
      { key: "Primary category", value: 2, name: "primaryCategory", hideS1: true, hideEM: true, },
      { key: "Countries", value: 3, name: "countries" },
      { key: "Continents", value: 4, name: "continents", hideEjp: true },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
      { key: "Revision Number", value: 6, name: "revisionNumber" },
    ],
  },
  submissionsPassedInitialQC: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Primary category", value: 2, name: "primaryCategory", hideS1: true, hideEM: true, },
      { key: "Countries", value: 3, name: "countries", },
      { key: "Continents", value: 4, name: "continents" },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Manuscript types", value: 1, name: "manuscriptType", },
      { key: "Primary category", value: 2, name: "primaryCategory", hideS1: true, hideEM: true, },
      { key: "Countries", value: 3, name: "countries" },
      { key: "Continents", value: 4, name: "continents", hideEjp: true },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
      { key: "Revision Number", value: 6, name: "revisionNumber" },
    ],
  },
  invitedToAgreed: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" }
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Reviewer type", value: 3, name: "reviewerType", hideS1: true, hideEM: true, hideEjp: true },
    ],
  },
  invitedToCompleted: {
    variablesList1: [{ key: "Time period", value: 1, name: "time" }],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Review Type", value: 8, name: "reviewType", },
    ],
  },
  agreedToCompleted: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" }
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Reviewer type", value: 3, name: "reviewerType", hideS1: true, hideEM: true, hideEjp: true },
    ],
  },
  initialDecisions: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
      { key: "Countries", value: 3, name: "countries" },
      { key: "Continents", value: 4, name: "continents" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
      // { key: "Countries", value: 3, name: "countries" }
    ],
  },
  initialDecisionsBasedOnDecisionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
      { key: "Countries", value: 3, name: "countries" },
      { key: "Continents", value: 4, name: "continents" },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
    ],
  },
  timeToInitialDecisionsBasedOnDecisionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Decision types", value: 2, name: "decisionTypes" }
    ],
  },
  timeToInitialDecisionsBasedOnSubmissionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Decision types", value: 2, name: "decisionTypes" }
    ],
  },
  initialDecisionsBasedOnSubmissionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Decision types", value: 4, name: "decisionTypes" },
      { key: "Countries", value: 2, name: "countries" },
      { key: "Continents", value: 3, name: "continents" },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
    ],
  },
  finalDecisionsBasedOnSubmissionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
      { key: "Countries", value: 3, name: "countries" },
      { key: "Continents", value: 4, name: "continents" },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
    ],
  },
  finalDecisionsBasedOnDecisionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
      { key: "Countries", value: 3, name: "countries" },
      { key: "Continents", value: 4, name: "continents", },
      { key: "Continents with China breakout", value: 5, name: "continentsWithChinaBreakout" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
    ],
  },
  rejects: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Countries", value: 2, name: "countries" },
      { key: "Manuscript types", value: 3, name: "manuscriptType" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Countries", value: 2, name: "countries" },
    ],
  },
  finalDecision: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Manuscript types", value: 2, name: "manuscriptType", hideS1: true, hideEM: false, hideRv: true },
      { key: "Countries", value: 3, name: "countries", hideRv: true },
      { key: "Continents", value: 4, name: "continents", hideRv: true },
      // { key: "Decision types", value: 2, name: "decisionTypes" }
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      // { key: "Time period", value: 1, name: "time" },
      { key: "Decision types", value: 2, name: "decisionTypes" },
      { key: "Manuscript types", value: 3, name: "manuscriptType", hideS1: true, hideEM: false, },
      { key: "Countries", value: 4, name: "countries" },
      { key: "Continents", value: 5, name: "continents", hideEjp: true },
      { key: "Highest revision number", value: 6, name: 'highestRevisionNumber', hideS1: true, hideEM: true, },
    ],
  },
  timeToFinalDecision: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Revision Number", value: 2, name: "revisionNumber", hideRv: true },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      // { key: "Time period", value: 1, name: "time" },
      // { key: "Decision types", value: 2, name: "decisionTypes" }
    ],
  },
  timeFromPCORIFinalReviewToFinalAcceptance: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ],
  },
  numberOfDecisions: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Revision Number", value: 5, name: "revisionNumber", hideEjp: true, },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Decision type", value: 2, name: "decisionTypes" },
    ],
  },
  numberOfManuscripts: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Decision type", value: 1, name: "decisionTypes" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Frequency of occurrence", value: 2, name: "frequencyOfOccurrence", hideEjp: true, hideS1: true, hideEM: true, },
    ],
  },
  acceptFinalDecisions: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Manuscript types", value: 2, name: "manuscriptType" },
      { key: "Countries", value: 3, name: "countries" },
      // { key: "Decision types", value: 2, name: "decisionTypes" }
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      // { key: "Decision types", value: 2, name: "decisionTypes" }
    ],
  },
  rejectFinalDecisions: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Countries", value: 2, name: "countries" },
    ],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  accept: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
  },
  timeToInitialDecisions: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  finalRecommendationDecision: {
    variablesList1: [{ key: "Time period", value: 1, name: "time" }],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  rejectionRate: {
    variablesList1: [
      { key: "Time from Editor Assignment to Initial Decision", value: 1, name: "timeToInitialDecisionFrEdAss", },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Editors", value: 1, name: "editors" },
    ],
  },
  numberOfEditors: {
    variablesList1: [
      { key: "Time period", value: 1, name: "time" },
      { key: "Editor's country", value: 2, name: "countryEditor" },
      { key: "Time from editor assignment to first reviewer invited", value: 3, name: "editAssignFirstRevInvited" },
      { key: "Time from Editor Assignment to Initial Decision ", value: 4, name: "assignmentInitialDecision", },
      { key: "Time from Last Review Completed to Initial Decision", value: 5, name: "initialDecisionCompleted", },
      { key: "Number of unique reviewers invited", value: 6, name: "numberOfUniqueReviewersInvited", },
      { key: "Number of reviewers invited per manuscrip", value: 7, name: "numberOfReviewersInvitedPerManuscripts"},
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ]
  },
  timeFromEditorAssignmentToFirstRreviewerIinvited: {
    variablesList1: [
      { key: "Associate Editor", value: 1, name: "associateEditors", hideS1: true, hideEM: true, hideEjp: true },
      { key: "Editor", value: 1, name: "editors", hideRv: true },
    ],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  editorAssignments: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Editors", value: 1, name: "editors" },
    ],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  reviewers: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Response to Invitations", value: 1, name: "responseToInvitations", },
      // { key: "Time in Peer Review", value: 8, name: "timeInPeerReview" },
    ],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  numberOfCompletedReviews: {
    variablesList1: [{ key: "Time period", value: 1, name: "time" }],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  numberOfInvitations: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Outcome of invitation - completed", value: 1, name: "outcomeOfInvitationsSbm", },
      { key: "Time period", value: 2, name: "time" },
      { key: "Reviewer type", value: 3, name: "reviewerType", hideS1: true, hideEM: true, hideEjp: true },
    ],
    variablesList2: [
      { key: "None", value: 0, name: "none" },
      { key: "Outcome of invitation – agree/decline", value: 1, name: "outcomeOfInvitationsAgreeDecline", },
      { key: "Outcome of invitation - completed", value: 2, name: "outcomeOfInvitationsSbm", },
    ],
  },
  timeFromSubmissionToAEAssignment: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Revision Number", value: 5, name: "revisionNumber", hideEjp: true, },
    ],
    variablesList2: [
      { key: "None", value: 0, name: "none" },
    ],
  },
  aeChangedBeforeDecisionLetter: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Associate Editor", value: 1, name: "associateEditors", },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Revision Number", value: 2, name: "revisionNumber" },
    ],
  },
  timeForAuthorRevisions: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Revision Number", value: 1, name: "revisionNumber", hideEjp: true, },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Stage Name", value: 2, name: 'stageName', hideS1: true, hideEM: true, },
    ],
  },
  timeInPeerReview: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Reviewer type", value: 2, name: "reviewerType", hideS1: true, hideEM: true, hideEjp: true },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Reviewer type", value: 3, name: "reviewerType", hideS1: true, hideEM: true, hideEjp: true },
    ],
  },
  totalTimeWithPeerReviewers: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  recommendationDecision: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time", hideEM: true },
      { key: "Editors", value: 2, name: "editors", hideEM: true },
      { key: "Initial Decisions", value: 3, name: "initialDecisions", hideEM: true, },
      { key: "Manuscript types", value: 4, name: "manuscriptType", hideEM: true, },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Section/Category", value: 1, name: "sectionCategory" },
    ],
  },
  AssignmentInitialDecision: {
    variablesList1: [
      { key: "Time period", value: 1, name: "time", hideEM: false },
      { key: "Editors", value: 2, name: "editors" },
      { key: "Initial Decisions", value: 3, name: "initialDecisions" },
      { key: "Manuscript types", value: 4, name: "manuscriptType" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Section/Category", value: 1, name: "sectionCategory" },
    ],
  },
  initialDecisionCompleted: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Editors", value: 2, name: "editors" },
      { key: "Initial Decisions", value: 3, name: "initialDecisions" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Editors", value: 2, name: "editors" },
      { key: "Initial Decisions", value: 3, name: "initialDecisions" },
      { key: "Section/Category", value: 4, name: "sectionCategory" },
    ],
  },
  initialRecommendationDecisionCompleted: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time", hideEM: true },
      { key: "Editors", value: 2, name: "editors" },
      { key: "Initial Decisions", value: 3, name: "initialDecisions" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Initial Decisions", value: 1, name: "initialDecisions" },
      { key: "Editors", value: 2, name: "editors" },
    ],
  },
  numberOfReviewers: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time Period", value: 2, name: "time", hideS1: true },
      { key: "Countries-Reviewer", value: 1, name: "countries" },
      { key: "Continent - Reviewer", value: 3, name: "continents" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Countries-Reviewer", value: 1, name: "countries" },
      { key: "Continent - Reviewer", value: 3, name: "continents" },
      { key: "Time Period", value: 2, name: "time" },
    ],
  },
  numberOfUniqueReviewersInvited: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Editors", value: 2, name: "editors" },
    ],
    variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  },
  numberOfReviewersInvitedPerManuscripts: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Editors", value: 1, name: "editors", hideRv: true },
      { key: "Associate Editor", value: 1, name: "associateEditors", hideEM: true, hideS1: true },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Review Type", value: 8, name: "reviewType", },
    ],
  },
  numberOfSubmissions: {
    variablesList1: [
      { key: "Revision Number", value: 2, name: "revisionNumber" },
    ],
    variablesList2: [
      { key: "Total returns to author before AE/PCORI assignment", value: 10, name: 'totalReturnsToAuthorBeforeAEPCORIAssignment', hideS1: true, hideEM: true, },
    ],
  },
  numberOfUniqueReviewers: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Reviewer type", value: 3, name: "reviewerType", },
    ]
  },
  // timeFromLastReviewCompletedToStartOfDraftSynthesisLetter: {
  //   variablesList1: [
  //     { key: "Select (required)", value: 0, name: "none" },
  //     { key: "Associate Editor", value: 1, name: "associateEditors" },
  //   ],
  //   variablesList2: [{ key: "None (optional)", value: 0, name: "none" }],
  // }
  submissionsReturnedToAuthor: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Revision number", value: 1, name: "revisionNumber", hideEM: true, hideS1: true },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Stage Name", value: 1, name: "stageName" },
    ],
  },
  timeForAuthorCorrections: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Revision number", value: 1, name: "revisionNumber", hideEM: true, hideS1: true },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Stage Name", value: 1, name: "stageName" },
    ],
  },
  numberOfConsultingEditorsRequested: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Stage name", value: 1, name: "stageName" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
      { key: "Associate Editor requesting CE", value: 2, name: "associateEditorRequestingCE" },
    ],
  },
  rejectedInitialBasedOnDecisionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Primary category", value: 2, name: "primaryCategory"},
      { key: "Countries", value: 3, name: "countries" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ],
  },
  rejectedFinalBasedOnDecisionDate: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Primary category", value: 2, name: "primaryCategory"},
      { key: "Countries", value: 3, name: "countries" },
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ],
  },
  rejectedInitialBasedOnSubmissionDateQC: {
    variablesList1: [
      { key: "Select (required)", value: 0, name: "none" },
      { key: "Time period", value: 1, name: "time" },
      { key: "Primary category", value: 3, name: "primaryCategory"},
    ],
    variablesList2: [
      { key: "None (optional)", value: 0, name: "none" },
    ],
  }
};

const chartTypeList = {
  // 0 stands for Scholar One
  // 1 stands for Editorial Manager
  // timeFromLastReviewCompletedToStartOfDraftSynthesisLetter: {
  //   associateEditors: {
  //     none: {
  //       0: [],
  //       1: [],
  //       2: [],
  //       // 3: ["TLRAE-BV01r", "TLRAE-T01r", "TLRAE-T02r","TLRAE-T03r", "TLRAE-T04r",]
  //     }
  //   },
  // },
  timeToDraftSynthesisLetter: {
    associateEditors: {
      none: {
        0: [],
        1: [],
        2: [],
        // 3: ["TCDLAELS-T96r"]
      },
      // letterStage: {
      //   3: ["TDLAELS-BVS01r", "TDLAELS-T01r", "TDLAELS-T02r"]
      // }
    },
    // revisionNumber: {
    //   associateEditors: {
    //     0: [],
    //     1: [],
    //     2: [],
    //     3: []
    //   }
    // }
  },
  timeToCompleteDecisionLetter: {
    none: {},
    associateEditors: {
      letterStage: {
        3: ["TCDLAELS-BVS01r", "TCDLAELS-T01r", "TCDLAELS-T02r", "TCDLAELS-T05r", "TCDLAELS-T06r", "TCDLAELS-T96r", "TCDLAELS-T99r", "TCDLAELS-T20r",]
      },
      revisionNumber: {
        3: ["TCDLAER#-CC01r", "TCDLAER#-T01r", "TCDLAER#-T04r", "TCDLAER#-CC05r", "TCDLAER#-T05r", "TCDLAER#-T08r", "TCDLAER#-T20r"]
      },
      none: {},
    },
    revisionNumber: {
      associateEditors: {
        3: ["TCDLR#AE-T97r"]
      },
      none: {}
    }
  },
  aeAssignments: {
    associateEditors: {
      revisionNumber: {
        3: ["#AAE-BVS01r", "#AAE-CC01r", "#AAE-T01r", "#AAE-T02r", "#AAE-T20r"]
      }
    }
  },
  timeFromEditorAssignmentToEditorAssignmentDecision: {
    associateEditors: {
      revisionNumber: {
        3: ["TAEDAER#-T77r", "TAEDAER#-T20r",]
      }
    }
  },
  timeToCompleteSynthesisDecisionLetter: {
    associateEditors: {
      none: {
        3: [
          "TCSLAE-BV01r", "TCSLAE-BV04r",
          // "TCSLAE-BV03r", "TCSLAE-BV02r", "TCSLAE-T01r", "TCSLAE-T02r", "TCSLAE-BV06r", "TCSLAE-BV07r", 
          "TCSLAE-T03r", "TCSLAE-T04r", "TCSLAE-BV05r", "TCSLAE-BV08r", "TCSLAE-BXV05r", "TCSLAE-T07r", "TCSLAE-T08r", "TCSLAE-T20r"
        ]
      },
    },
    revisionNumber: {
      none: {

      },
      associateEditors: {
        // 3: ["TCDLR#AE-T97r"]
      }
    }
  },
  timeToDraftSynthLetter: {
    associateEditors: {
      none: {
        3: [
          "TDSLAE-BV01r", "TDSLAE-BV04r",
          // "TDSLAE-BV02r", "TDSLAE-BV03r", 
          "TDSLAE-T03r", "TDSLAE-T04r",
          // "TDSLAE-BV06r", "TDSLAE-BV07r", 
          "TDSLAE-BV05r", "TDSLAE-BV08r", "TDSLAE-BXV05r",
          // "TDSLAE-T01r", "TDSLAE-T02r", 
          // "TDSLAE-T05r", "TDSLAE-T06r", 
          "TDSLAE-T07r", "TDSLAE-T08r", "TDSLAE-T20r",
        ]
      }
    }
  },
  timeToDecisionLetter: {
    associateEditors: {
      none: {
        0: [],
        1: [],
        2: [],
        3: []
      }
    }
  },
  timeFromFirstReviewerInvitationToLastReviewerInvitation: {
    associateEditors: {
      none: {
        0: [],
        1: [],
        2: [],
        3: ["TFLRAE-BV01r", "TFLRAE-BV04r", "TFLRAE-T03r", "TFLRAE-T04r", "TFLRAE-BV05r", "TFLRAE-BV08r", "TFLRAE-BXV05r", "TFLRAE-T07r", "TFLRAE-T08r", "TFLRAE-T20r"]
      }
    }
  },
  submissions: {
    time: {
      none: {
        0: ["STP-BV01s", "STP-BV02s", "STP-BH01s", "STP-L01s", "STP-T01s", "STP-T02s",],
        1: ["STP-BV01e", "STP-BV02e", "STP-BH01e", "STP-L01e", "STP-T01e", "STP-T02e",],
        2: ["STP-BV01p", "STP-BV02p", "STP-BH01p", "STP-L01p", "STP-T01p", "STP-T02p",],
        3: ["STP-BV01r", "STP-T01r", "STP-T02r", "STP-T20r",]
      },
      countries: {
        0: ["STPC-BVS01s", "STPC-BVS02s", "STPC-BHS01s", "STPC-CC01s", 515, "STPC-L01s", "STPC-W01s", "STPC-T01s", "STPC-T02s",],
        1: ["STPC-BVS01e", "STPC-BVS02e", "STPC-BHS01e", "STPC-CC01e", 515, "STPC-L01e", "STPC-W01e", "STPC-T01e", "STPC-T02e",],
        2: ["STPC-BVS01p", "STPC-BH01p", "STPC-CC01p", "STPC-T01p", "STPC-T02p",],
      },
      continents: {
        0: ["STPCT-BVS01s", "STPCT-BVS02s", "STPCT-BHS01s", "STPCT-CC01s", "STPCT-L01s", "STPCT-W01s", "STPCT-T01s", "STPCT-T02s"],
        1: ["STPCT-BVS01e", "STPCT-BVS02e", "STPCT-BHS01e", "STPCT-CC01e", "STPCT-L01e", "STPCT-W01e", "STPCT-T01e", "STPCT-T02e",],
        2: ["STPCT-BVS01p", "STPCT-BH01p", "STPCT-CC01p", "STPCT-T01p", "STPCT-T02p",],
      },
      continentsWithChinaBreakout: {
        2: ["STPCTC-BVS01p", "STPCTC-BHS01p", "STPCTC-CC01p", "STPCTC-T01p", "STPCTC-T02p",]
      },
      manuscriptType: {
        0: ["STPM-BVS01s", "STPM-BVS02s", "STPM-BHS01s", "STPM-CC01s", 534, "STPM-L01s", "STPM-W01s", "STPM-T01s", "STPM-T02s"],
        1: ["STPM-BVS01e", "STPM-BVS02e", "STPM-BHS01e", "STPM-CC01e", 534, "STPM-L01e", "STPM-W01e", "STPM-T01e", "STPM-T02e",],
        2: ["STPM-BVS01p", "STPM-BHS01p", "STPM-CC01p", "STPM-T01p", "STPM-T02p",],
      },
      revisionNumber: {
        0: ["STPR#-CC01s", "STPR#-T01s", "STPR#-T02s"],
        1: ["STPR#-CC01e", "STPR#-T01e", "STPR#-T02e"],
        2: ["STPR#-BVS01p", "STPR#-BHS01p", "STPR#-CC01p", "STPR#-T01p", "STPR#-T02p",],
        3: ["STPR#-BVS01r", "STPR#-CC01r", "STPR#-T01r", "STPR#-T02r", "STPR#-T20r",]
      },
      sectionCategory: {
        0: [],
        1: ["STPSC-BVS01e", "STPSC-BHS01e", "STPSC-CC01e", "STPSC-T01e", "STPSC-T02e",],
        2: [],
      },
      submissionType: {

      },
      primaryCategory: {
        0: [],
        1: [],
        2: ["STPPC-BVS01p", "STPPC-BHS01p", "STPPC-CC01p", "STPPC-T01p", "STPPC-T02p",],
      }
    },
    countries: {
      none: {
        0: ["SC-BV01s", "SC-BH01s", "SC-M01s", "SC-M02s", "SC-D01s", "SC-D02s", "SC-BB01s", "SC-T01s",],
        1: ["SC-BV01e", "SC-BH01e", "SC-M01e", "SC-M02e", "SC-D01e", "SC-D02e", "SC-BB01e", "SC-T01e",],
        2: ["SC-BV01p", "SC-BH01p", "SC-D01p", "SC-D02p", "SC-M01p", "SC-M02p", "SC-T01p", "SC-T01Bp", "SC-T02p", "SC-T02Bp",],
      },
      time: {
        0: ["SCTP-BVS01s", "SCTP-BVS02s", "SCTP-BHS01s", "SCTP-CC01s", 615, "SCTP-L01s", "SCTP-W01s", "SCTP-T01s", "SCTP-T02s"],
        1: ["SCTP-BVS01e", "SCTP-BVS02e", "SCTP-BHS01e", "SCTP-CC01e", 615, "SCTP-L01e", "SCTP-W01e", "SCTP-T01e",],
        2: [],
      },
      continents: {
        0: [],
        1: [],
        2: [],
      },
      manuscriptType: {
        0: ["SCM-BVS01s", "SCM-BVS02s", "SCM-BHS01s", "SCM-CC01s", 624, "SCM-SC01s", "SCM-T01s", "SCM-T02s",],
        1: ["SCM-BVS01e", "SCM-BVS02e", "SCM-BHS01e", "SCM-CC01e", 624, "SCM-SC01e", "SCM-T01e",],
        2: [],
      },
      revisionNumber: {
        0: [],
        1: [],
        2: [],
      },
      sectionCategory: {
        0: [],
        1: [],
        2: [],
      },
    },
    singleCountry: {
      none: {
        0: [],
        1: [],
        2: [],
      },
      time: {
        0: ["SCiTP-L01s", "SCiTP-BV01s", "SCiTP-BH01s", "SCiTP-BV02s", "SCiTP-T01s", 655, 656,],
        1: ["SCiTP-L01e", "SCiTP-BV01e", "SCiTP-BH01e", "SCiTP-BV02e", "SCiTP-T01e", 655, 656,],
        2: [],
      },
      countries: {
        0: [],
        1: [],
        2: [],
      },
      continents: {
        0: [],
        1: [],
        2: [],
      },
      manuscriptType: {
        0: [],
        1: [],
        2: [],
      },
      revisionNumber: {
        0: [],
        1: [],
      },
      sectionCategory: {
        0: [],
        1: [],
        2: [],
      },
    },
    continents: {
      none: {
        0: ["SCT-BV01s", "SCT-BH01s", "SCT-M01s", "SCT-D01s", 704, "SCT-T01s", "SCT-T03s"],
        1: ["SCT-BV01e", "SCT-BH01e", "SCT-M01e", "SCT-M02e", "SCT-D01e", 704, "SCT-T01e"],
        2: ["SCT-BV01p", "SCT-BH01p", "SCT-D01p", "SCT-T01p", "SCT-T02p"],
        // "SCT-D02e"
      },
      time: {
        0: ["SCTTP-BVS01s", "SCTTP-BVS02s", "SCTTP-BHS01s", "SCTTP-CC01s", "SCTTP-L01s", "SCTTP-W01s", "SCTTP-T01s", 715,],
        1: ["SCTTP-BVS01e", "SCTTP-BVS02e", "SCTTP-BHS01e", "SCTTP-CC01e", "SCTTP-L01e", "SCTTP-W01e", "SCTTP-T01e", 715,],
        2: [],
      },
      countries: {
        0: [],
        1: [],
        2: [],
      },
      manuscriptType: {
        0: ["SCTM-BVS01s", "SCTM-BVS02s", "SCTM-BHS01s", "SCTM-CC01s", "SCTM-SC01s", "SCTM-T01s", 725,],
        1: ["SCTM-BVS01e", "SCTM-BVS02e", "SCTM-BHS01e", "SCTM-CC01e", "SCTM-SC01e", "SCTM-T01e", 725,],
        2: [],
      },
      revisionNumber: {
        0: [],
        1: [],
        2: [],
      },
      sectionCategory: {
        0: [],
        1: [],
        2: [],
      },
    },
    continentsWithChinaBreakout: {
      none: {
        2: [
          "SCTC-BV01p",
          "SCTC-BH01p",
          "SCTC-D01p",
          "SCTC-T01p",
          "SCTC-T02p",
        ]
      }
    },
    manuscriptType: {
      none: {
        0: ["SM-BV01s", "SM-BH01s", "SM-D01s", "SM-D02s", "SM-BB01s", "SM-T01s"], // all Manuscript-types/None charts were disabled ***
        1: ["SM-BV01e", "SM-BH01e", "SM-D01e", "SM-D02e", "SM-BB01e", "SM-T01e"],
        2: [],
      },
      time: {
        0: ["SMTP-BVS01s", "SMTP-BVS02s", "SMTP-BHS01s", "SMTP-CC01s", 805, "SMTP-SC01s", "SMTP-T01s",],
        1: ["SMTP-BVS01e", "SMTP-BVS02e", "SMTP-BHS01e", "SMTP-CC01e", 805, "SMTP-SC01e", "SMTP-T01e",],
        2: [],
      },
      countries: {
        0: ["SMC-BVS01s", "SMC-BVS02s", "SMC-BHS01s", "SMC-CC01s", 815, "SMC-SC01s", "SMC-T01s",],
        1: ["SMC-BVS01e", "SMC-BVS02e", "SMC-BHS01e", "SMC-CC01e", 815, "SMC-SC01e", "SMC-T01e",],
        2: [],
      },
      continents: {
        0: ["SMCT-BVS01s", "SMCT-BVS02s", "SMCT-BHS01s", "SMCT-CC01s", 825, "SMCT-SC01s", "SMCT-T01s",],
        1: ["SMCT-BVS01e", "SMCT-BVS02e", "SMCT-BHS01e", "SMCT-CC01e", 825, "SMCT-SC01e", "SMCT-T01e",],
        2: [],
      },
      revisionNumber: {
        0: [],
        1: [],
        2: [],
      },
      sectionCategory: {
        0: [],
        1: [],
        2: [],
      },
    },
    revisionNumber: {
      none: {
        0: [],
        1: [],
        2: [],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      countries: {
        0: [],
        1: [],
        2: [],
      },
      continents: {
        0: [],
        1: [],
        2: [],
      },
      manuscriptType: {
        0: [],
        1: [],
        2: [],
      },
      sectionCategory: {
        0: [],
        1: [],
        2: [],
      },
      submissionType: {
        3: ["SR#ST-BVS01r", "SR#ST-CC01r", "SR#ST-T01r", "SR#ST-T02r", "SR#ST-T20r",]
      }
    },
    sectionCategory: {
      none: {
        0: [],
        1: ["SSC-BV01e", "SSC-T01e", "SSC-T02e"],
        2: [],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      countries: {
        0: [],
        1: [],
        2: [],
      },
      continents: {
        0: [],
        1: [],
        2: [],
      },
      manuscriptType: {
        0: [],
        1: [],
        2: [],
      },
      revisionNumber: {
        0: [],
        1: [],
        2: [],
      },
    },
    primaryCategory: {
      none: {
        2: ["SPC-BV01p", "SPC-BH01p", "SPC-D01p", "SPC-T01p", "SPC-T02p",]
      }
    },
  },
  submissionsPassedInitialQC: {
    time: {
      none: {
        2: ["SQCTP-BV01p", "SQCTP-BV02p", "SQCTP-BH01p", "SQCTP-L01p", "SQCTP-T01p", "SQCTP-T02p",],
      },
      manuscriptType: {
        2: ["SQCTPM-BVS01p", "SQCTPM-BHS01p", "SQCTPM-CC01p", "SQCTPM-T01p", "SQCTPM-T02p",],
      },
      primaryCategory: {
        2: ["SQCTPPC-BVS01p", "SQCTPPC-BHS01p", "SQCTPPC-CC01p", "SQCTPPC-T01p", "SQCTPPC-T02p",],
      },
      countries: {
        2: ["SQCTPC-BVS01p", "SQCTPC-BHS01p", "SQCTPC-CC01p", "SQCTPC-T01p", "SQCTPC-T02p",]
      },
      continents: {
        2: ["SQCTPCT-BVS01p", "SQCTPCT-BHS01p", "SQCTPCT-CC01p", "SQCTPCT-T01p", "SQCTPCT-T02p",]
      },
      continentsWithChinaBreakout: {
        2: ["SQCTPCTC-BVS01p", "SQCTPCTC-BHS01p", "SQCTPCTC-CC01p", "SQCTPCTC-T01p", "SQCTPCTC-T02p",]
      },
      revisionNumber: {
        2: ["SQCTPR#-BVS01p", "SQCTPR#-BHS01p", "SQCTPR#-CC01p", "SQCTPR#-T01p", "SQCTPR#-T02p",]
      },
    },
    primaryCategory: {
      none: {
        2: ["SQCPC-BV01p", "SQCPC-BH01p", "SQCPC-D01p", "SQCPC-T01p", "SQCPC-T02p",]
      }
    },
    countries: {
      none: {
        2: ["SQCC-BV01p", "SQCC-BH01p", "SQCC-D01p", "SQCC-D02p", "SQCC-M01p", "SQCC-M02p", "SQCC-T01p", "SQCC-T01Bp", "SQCC-T02p", "SQCC-T02Bp",],
      }
    },
    continents: {
      none: {
        2: ["SQCCT-BV01p", "SQCCT-BH01p", "SQCCT-D01p", "SQCCT-T01p", "SQCCT-T02p",]
      }
    },
    continentsWithChinaBreakout: {
      none: {
        2: ["SQCCTC-BV01p", "SQCCTC-BH01p", "SQCCTC-D01p", "SQCCTC-T01p", "SQCCTC-T02p",]
      }
    },
  },
  initialDecisions: {
    time: {
      none: {
        0: [],
        1: [],
        2: [],
      },
      decisionTypes: {
        0: ["DITPDT-BVS01s", "DITPDT-BVS02s", "DITPDT-BHS01s", "DITPDT-BHS02s", "DITPDT-CC01s", "DITPDT-L01s", "DITPDT-W01s", "DITPDT-T01s", "DITPDT-T02s",],
        1: ["DITPDT-BVS01e", "DITPDT-BVS02e", "DITPDT-BHS01e", "DITPDT-BHS02e", "DITPDT-CC01e", "DITPDT-L01e", "DITPDT-W01e", "DITPDT-T01e",],
        2: [],
      },
      countries: {
        0: [],
        1: [],
        2: [],
      },
    },
    decisionTypes: {
      none: {
        0: ["DIDT-DTV01s", "DIDT-DTH01s", "DIDT-DTV02s", "DIDT-DTH02s", "DIDT-D01s", "DIDT-BB01s",],
        1: ["DIDT-DTV01e", "DIDT-DTH01e", "DIDT-DTV02e", "DIDT-DTH02e", "DIDT-D01e", "DIDT-BB01e",],
        2: [],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      countries: {
        0: [],
        1: [],
        2: [],
      },
    },
    countries: {
      none: {
        0: [],
        1: [],
        2: [],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      decisionTypes: {
        0: ["DICDT-BVS01s", "DICDT-T01s", "DICDT-T02s"],
        1: ["DICDT-BVS01e", "DICDT-T01e", "DICDT-T02e"],
        2: [],
      },
    },
    continents: {
      none: {
        0: [],
        1: [],
        2: [],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      decisionTypes: {
        0: ["DICTDT-BVS01s", "DICTDT-T01s", "DICTDT-T02s"],
        1: ["DICTDT-BVS01e", "DICTDT-T01e", "DICTDT-T02e"],
        2: [],
      },
    },
    singleCountry: {
      none: {
        0: ["DICi-L01s", "DICi-BV01s", "DICi-BH01s", "DICi-D01s", "DICi-BB01s", "DICi-M01s", "DICi-M02s",],
        1: ["DICi-L01e", "DICi-BV01e", "DICi-BH01e", "DICi-D01e", "DICi-BB01e", "DICi-M01e", "DICi-M02e",],
        2: [],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      decisionTypes: {
        0: [],
        1: [],
        2: [],
      },
      countries: {
        0: [],
        1: [],
        2: [],
      },
    },
  },
  initialDecisionsBasedOnDecisionDate: {
    time: {
      none: {
      },
      decisionTypes: {
        2: ["DIdTPDT-BVS01p", "DIdTPDT-BVS02p", "DIdTPDT-BHS01p", "DIdTPDT-CC01p", "DIdTPDT-L01p", "DIdTPDT-T01p", "DIdTPDT-T02p"],
      },
    },
    decisionTypes: {
      none: {
        2: ["DIdDT-BV01p", "DIdDT-D01p", "DIdDT-T01p", "DIdDT-T02p"],
      },
    },
    countries: {
      none: {
      },
      decisionTypes: {
        2: ["DIdCDT-BVS01p", "DIdCDT-T01p", "DIdCDT-T02p",],
      },
    },
    continents: {
      none: {
      },
      decisionTypes: {
        2: ["DIdCTDT-BVS01p", "DIdCTDT-T01p", "DIdCTDT-T02p",],
      },
    },
    continentsWithChinaBreakout: {
      none: {

      },
      decisionTypes: {
        2: ["DIdCTCDT-BVS01p", "DIdCTCDT-T01p", "DIdCTCDT-T02p",]
      }
    }
  },
  timeToInitialDecisionsBasedOnDecisionDate: {
    time: {
      none: {
        2: ["TDIdTP-BV01p", "TDIdTP-BV02p", "TDIdTP-BV03p", "TDIdTP-BV04p", "TDIdTP-BH01p", "TDIdTP-L01p", "TDIdTP-T03p", "TDIdTP-T04p",
          "TDIdTP-BV05p", "TDIdTP-BV06p", "TDIdTP-BV07p", "TDIdTP-BV08p", "TDIdTP-BH05p", "TDIdTP-L05p", "TDIdTP-BXV05p", "TDIdTP-T07p",
          "TDIdTP-T08p",],
      },
      decisionTypes: {
        2: ["TDIdTPDT-BVS01p", "TDIdTPDT-BHS01p", "TDIdTPDT-L01p", "TDIdTPDT-T03p", "TDIdTPDT-T04p", "TDIdTPDT-BVS05p",
          "TDIdTPDT-BHS05p", "TDIdTPDT-L05p", "TDIdTPDT-T07p", "TDIdTPDT-T08p",]
      }
    },
  },
  timeToInitialDecisionsBasedOnSubmissionDate: {
    time: {
      none: {
        2: ["TDIsTP-BV01p", "TDIsTP-BV02p", "TDIsTP-BV03p", "TDIsTP-BV04p", "TDIsTP-BH01p", "TDIsTP-L01p", "TDIsTP-T03p", "TDIsTP-T04p",
          "TDIsTP-BV05p", "TDIsTP-BV06p", "TDIsTP-BV07p", "TDIsTP-BV08p", "TDIsTP-BH05p", "TDIsTP-L05p", "TDIsTP-BXV05p", "TDIsTP-T07p",
          "TDIsTP-T08p",],
      },
      decisionTypes: {
        2: ["TDIsTPDT-BVS01p", "TDIsTPDT-BHS01p", "TDIsTPDT-L01p", "TDIsTPDT-T03p", "TDIsTPDT-T04p", "TDIsTPDT-BVS05p",
          "TDIsTPDT-BHS05p", "TDIsTPDT-L05p", "TDIsTPDT-T07p", "TDIsTPDT-T08p",]
      }
    },
  },
  initialDecisionsBasedOnSubmissionDate: {
    time: {
      none: {
      },
      decisionTypes: {
        2: ["DIsTPDT-BVS01p", "DIsTPDT-BVS02p", "DIsTPDT-BHS01p", "DIsTPDT-CC01p", "DIsTPDT-L01p", "DIsTPDT-T01p", "DIsTPDT-T02p",],
      },
    },
    countries: {
      none: {
      },
      decisionTypes: {
        2: ["DIsCDT-BVS01p", "DIsCDT-T01p", "DIsCDT-T02p",],
      },
    },
    continents: {
      none: {
      },
      decisionTypes: {
        2: ["DIsCTDT-BVS01p", "DIsCTDT-T01p", "DIsCTDT-T02p",]
      },
    },
    decisionTypes: {
      none: {
        2: ["DIsDT-BV01p", "DIsDT-D01p", "DIsDT-T01p", "DIsDT-T02p",],
      },
    },
    continentsWithChinaBreakout: {
      none: {
      },
      decisionTypes: {
        2: ["DIsCTCDT-BVS01p", "DIsCTCDT-T01p", "DIsCTCDT-T02p",]
      }
    }
  },
  finalDecisionsBasedOnSubmissionDate: {
    time: {
      none: {
      },
      decisionTypes: {
        2: ["DFsTPDT-BVS01p", "DFsTPDT-BVS02p", "DFsTPDT-BHS01p", "DFsTPDT-CC01p", "DFsTPDT-L01p", "DFsTPDT-T01p", "DFsTPDT-T02p",],
      },
    },
    countries: {
      none: {
      },
      decisionTypes: {
        2: ["DFsCDT-BVS01p", "DFsCDT-BHS01p", "DFsCDT-CC01p", "DFsCDT-T01p", "DFsCDT-T02p",],
      },
    },
    continents: {
      none: {
      },
      decisionTypes: {
        2: ["DFsCTDT-BVS01p", "DFsCTDT-BHS01p", "DFsCTDT-CC01p", "DFsCTDT-T01p", "DFsCTDT-T02p",],
      },
    },
    decisionTypes: {
      none: {
        2: ["DFsDT-BV01p", "DFsDT-D01p", "DFsDT-T01p", "DFsDT-T02p"],
      },
    },
    continentsWithChinaBreakout: {
      none: {
      },
      decisionTypes: {
        2: ["DFsCTCDT-BVS01p", "DFsCTCDT-BHS01p", "DFsCTCDT-CC01p", "DFsCTCDT-T01p", "DFsCTCDT-T02p",]
      }
    }
  },
  finalDecisionsBasedOnDecisionDate: {
    time: {
      none: {
      },
      decisionTypes: {
        2: ["DFdTPDT-BVS01p", "DFdTPDT-BVS02p", "DFdTPDT-BHS01p", "DFdTPDT-CC01p", "DFdTPDT-L01p", "DFdTPDT-T01p", "DFdTPDT-T02p",],
      },
    },
    countries: {
      none: {
      },
      decisionTypes: {
        2: ["DFdCDT-BVS01p", "DFdCDT-BHS01p", "DFdCDT-CC01p", "DFdCDT-T01p", "DFdCDT-T02p",],
      },
    },
    continents: {
      none: {
      },
      decisionTypes: {
        2: ["DFdCTDT-BVS01p", "DFdCTDT-BHS01p", "DFdCTDT-CC01p", "DFdCTDT-T01p", "DFdCTDT-T02p",],
      },
    },
    continentsWithChinaBreakout: {
      none: {
      },
      decisionTypes: {
        2: ["DFdCTCDT-BVS01p", "DFdCTCDT-BHS01p", "DFdCTCDT-CC01p", "DFdCTCDT-T01p", "DFdCTCDT-T02p",],
      },
    },
    decisionTypes: {
      none: {
        2: ["DFdDT-BV01p", "DFdDT-D01p", "DFdDT-T01p", "DFdDT-T02p"],
      },
    },
  },
  rejects: {
    time: {
      none: {
        0: ["%RITP-BV01s", "%RITP-BH01s", "%RITP-L01s", "%RITP-T01s", "%RITP-T02s",],
        1: ["%RITP-BV01e", "%RITP-BH01e", "%RITP-L01e", "%RITP-T01e", "%RITP-T02e",],
      },
      countries: {
        0: [],
        1: [],
      },
    },
    countries: {
      none: {
        0: ["%RIC-BV01s", "%RIC-BH01s", "%RIC-M01s", "%RIC-M02s", "%RIC-D01s", "%RIC-BB01s", "%RIC-BB02s", "%RIC-M03s", "%RIC-T01s", "%RIC-T02s",],
        1: ["%RIC-BV01e", "%RIC-BH01e", "%RIC-M01e", "%RIC-M02e", "%RIC-D01e", "%RIC-BB01e", "%RIC-BB02e", "%RIC-M03e", "%RIC-T01e", "%RIC-T02e",],
      },
      time: {
        0: [],
        1: [],
      },
    },
    manuscriptType: {
      none: {
        0: ["%RIM-BV01s", "%RIM-BH01s", "%RIM-T01s", "%RIM-T02s"],
        1: ["%RIM-BV01e", "%RIM-BH01e", "%RIM-T01e", "%RIM-T02e"],
      },
      time: {
        0: [],
        1: [],
      },
      countries: {
        0: [],
        1: [],
      },
    },
    // singleCountry: {
    //     none: {
    //         0: [],
    //         1: []
    //     },
    //     time: {
    //         0: [],
    //         1: []
    //     },
    //     countries: {
    //         0: [],
    //         1: []
    //     }
    // },
  },
  accept: {
    time: {
      none: {
        0: ["%AITP-BV01s", "%AITP-BH01s", "%AITP-L01s", "%AITP-T01s", "%AITP-T02s",],
        1: ["%AITP-BV01e", "%AITP-BH01e", "%AITP-L01e", "%AITP-T01e", "%AITP-T02e",],
      },
    },
  },
  finalDecision: {
    time: {
      none: {
        0: [""],
        1: [""],
      },
      decisionTypes: {
        0: ["DFTPDT-BVS01s", "DFTPDT-BVS02s", "DFTPDT-BHS01", "DFTPDT-BHS02", "DFTPDT-CC01s", "DFTPDT-L01", "DFTPDT-W01", "DFTPDT-T01s",],
        1: ["DFTPDT-BVS01e", "DFTPDT-BVS02e", "DFTPDT-BHS01", "DFTPDT-BHS02", "DFTPDT-CC01e", "DFTPDT-L01", "DFTPDT-W01", "DFTPDT-T01e",],
      },
      manuscriptType: {
        0: [],
        1: ["DFTPM-BVS01e", "DFTPM-BHS01e", "DFTPM-CC01e", "DFTPM-T01e"],
      },
      countries: {
        0: ["DFTPC-BVS01s", "DFTPC-CC01s", "DFTPC-T01s", "DFTPC-T02s"]
      },
      continents: {
        0: ["DFTPCT-BVS01s", "DFTPCT-CC01s", "DFTPCT-T01s", "DFTPCT-T02s"]
      },
      highestRevisionNumber: {
        3: ['DFTPHR#-BVS01r', 'DFTPHR#-CC01r', 'DFTPHR#-T01r', 'DFTPHR#-T02r', 'DFTPHR#-T20r',]
      },
    },
    manuscriptType: {
      none: {
        0: [],
        1: ["DFM-BV01e", "DFM-D01e", "DFM-D02e", "DFM-BB01e", "DFM-T01e"],
      },
      decisionTypes: {
        0: [],
        1: [],
      },
    },
    countries: {
      none: {
        0: [],
        1: [],
        2: [],
      },
      decisionTypes: {
        0: ["DFCDT-BVS01s", "DFCDT-CC01s", "DFCDT-T01s", "DFCDT-T02s",],
        1: ["DFCDT-BVS01e", "DFCDT-T01e", "DFCDT-T02e"],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      manuscriptType: {
        0: [],
        1: [],
      },
    },
    continents: {
      none: {
        0: [],
        1: [],
        2: [],
      },
      decisionTypes: {
        0: ["DFCTDT-BVS01s", "DFCTDT-CC01s", "DFCTDT-T01s", "DFCTDT-T02s",],
        1: ["DFCTDT-BVS01e", "DFCTDT-T01e", "DFCTDT-T02e",],
      },
      time: {
        0: [],
        1: [],
        2: [],
      },
      manuscriptType: {
        0: [],
        1: [],
      },
    },

  },
  timeToFinalDecision: {
    time: {
      none: {
        0: ["TDFTP-BV01s", "TDFTP-BH01s", "TDFTP-L01s",  "TDFTP-T01s", "TDFTP-T02s", "TDFTP-T03s", "TDFTP-T04s", "TDFTP-BV05s", "TDFTP-L05s", "TDFTP-BXV05s", "TDFTP-T05s", "TDFTP-T06s", "TDFTP-T07s", "TDFTP-T08s",],
        1: ["TDFTP-BV01e", "TDFTP-BV02e", "TDFTP-BV03e", "TDFTP-BV04e", "TDFTP-BH01e", "TDFTP-L01e", "TDFTP-T01e", "TDFTP-T02e", "TDFTP-T03e", "TDFTP-T04e", "TDFTP-BV05e", "TDFTP-BV06e", "TDFTP-BV07e", "TDFTP-BV08e", "TDFTP-L05e", "TDFTP-BXV05e", "TDFTP-T05e", "TDFTP-T06e", "TDFTP-T07e", "TDFTP-T08e"],
        3: ["TDFTP-BV01r", "TDFTP-BV04r", "TDFTP-L01r", "TDFTP-T03r", "TDFTP-T04r", "TDFTP-BV05r", "TDFTP-BV08r", "TDFTP-L05r", "TDFTP-BXV05r", "TDFTP-T07r", "TDFTP-T08r", "TDFTP-T20r"]
      },
    },
    revisionNumber: {
      none: {
        0: ["TDFR#-BV01s", "TDFR#-T01s", "TDFR#-T02s", "TDFR#-T03s", "TDFR#-T04s", "TDFR#-BV05s", "TDFR#-BXV05s", "TDFR#-T05s", "TDFR#-T06s", "TDFR#-T07s", "TDFR#-T08s",],
        1: ["TDFR#-BV01e", "TDFR#-BV03e", "TDFR#-T01e", "TDFR#-T02e", "TDFR#-T03e", "TDFR#-T04e", "TDFR#-BV05e", "TDFR#-BV07e", "TDFR#-BXV05e", "TDFR#-T05e", "TDFR#-T06e", "TDFR#-T07e", "TDFR#-T08e",],
      },
    },
  },
  timeFromPCORIFinalReviewToFinalAcceptance: {
    time: {
      none: {
        3: ["TDFPTP-BV01r", "TDFPTP-BV04r", "TDFPTP-T03r", "TDFPTP-T04r", "TDFPTP-BV05r", "TDFPTP-BV08r", "TDFPTP-BXV05r", "TDFPTP-T07r", "TDFPTP-T08r", "TDFPTP-T20r"],
      },
    },
  },
  numberOfDecisions: {
    none: {},
    revisionNumber: {
      none: {},
      decisionTypes: {
        3: ["#DR#DT-BVS01r", "#DR#DT-CC01r", "#DR#DT-T01r", "#DR#DT-T02r", "#DR#DT-T20r",]
      }
    }
  },
  numberOfManuscripts: {
    none: {},
    decisionTypes: {
      none: {},
      frequencyOfOccurrence: {
        3: ["#MDTFO-CC01r", "#MDTFO-T01r", "#MDTFO-T02r", "#MDTFO-T20r"]
      }
    }
  },
  acceptFinalDecisions: {
    time: {
      none: {
        0: ["%AFTP-L01s", "%AFTP-T01s"],
        1: ["%AFTP-BV01e", "%AFTP-BH01e", "%AFTP-L01e", "%AFTP-L02e", "%AFTP-T01e", "%AFTP-T02e",],
      },
    },
    manuscriptType: {
      none: {
        0: ["%AFM-BV01s", "%AFM-BB01s", "%AFM-T01s"],
        1: ["%AFM-BB01e", "%AFM-T01e", "%AFM-T02e"],
      },
      time: {
        0: ["%AFMTP-T01s"],
        1: ["%AFMTP-T01e"],
      },
      countries: {
        0: [],
        1: [],
      }
    },
    countries: {
      none: {
        0: ["%AFC-BV01s", "%AFC-T01s"],
        1: ["%AFC-M01e", "%AFC-M02e"],
      },
      time: {
        0: [],
        1: [],
      },
    }
  },
  rejectFinalDecisions: {
    time: {
      none: {
        0: ["%RFTP-L01s", "%RFTP-T01s"],
        1: ["%RFTP-L01e", "%RFTP-T01e", "%RFTP-T02e"],
      },
    },
    countries: {
      none: {
        0: ["%RFC-BV01s", "%RFC-M01s", "%RFC-M02s", "%RFC-D01s", "%RFC-D02s", "%RFC-BB01s", "%RFC-T01s"],
        1: ["%RFC-BV01e", "%RFC-M01e", "%RFC-M02e", "%RFC-D01e", "%RFC-D02e", "%RFC-BB01e", "%RFC-T01e"],
      },
    },
  },
  timeToInitialDecisions: {
    time: {
      none: {
        0: ["TDITP-BV01s", "TDITP-BH01s", "TDITP-L01s", "TDITP-T01s", "TDITP-T02s", "TDITP-T03s", "TDITP-T04s", "TDITP-BV05s", "TDITP-L05s", "TDITP-BXV05s", "TDITP-T05s", "TDITP-T06s", "TDITP-T07s", "TDITP-T08s",],
        1: ["TDITP-BV01e", "TDITP-BH01e", "TDITP-L01e", "TDITP-T01e", "TDITP-T02e", "TDITP-T03e", "TDITP-T04e", "TDITP-BV05e", "TDITP-L05e", "TDITP-BXV05e", "TDITP-T05e", "TDITP-T06e", "TDITP-T07e", "TDITP-T08e",],
        // 2: ["TDITP-BV01p", "TDITP-BH01p", "TDITP-L01p", "TDITP-T01p", "TDITP-T02p", "TDITP-T03p", "TDITP-T04p", "TDITP-L05p", "TDITP-T07p", "TDITP-T06p",],
        3: ["TDITP-BV01r", "TDITP-BV04r", "TDITP-L01r", "TDITP-T03r", "TDITP-T04r", "TDITP-BV05r", "TDITP-BV08r", "TDITP-L05r", "TDITP-BXV05r", "TDITP-T07r", "TDITP-T08r", "TDITP-T20r",]
      },
    },
  },
  finalRecommendationDecision: {
    time: {
      none: {
        0: ["TDFATP-L01s", "TDFATP-T01s", "TDFATP-T02s"],
        1: ["TDFATP-L01e", "TDFATP-T01e", "TDFATP-T02e"],
      },
    },
  },
  AssignmentInitialDecision: {
    time: {
      none: {
        0: [],
        1: ["TDIATP-L01e", "TDIATP-T01e", "TDIATP-T02e"],
      },
      sectionCategory: {
        0: [],
        1: ["TDIATPSC-CC01e", "TDIATPSC-T01e", "TDIATPSC-T02e"],
      },
    },
    editors: {
      none: {
        0: [],
        1: ["TDIAE-BV01e", "TDIAE-BV02e", "TDIAE-BV03e", "TDIAE-BV04e", "TDIAE-BXV01e", "TDIAE-BB01e", "TDIAE-T01e", "TDIAE-T02e",],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
    initialDecisions: {
      none: {
        0: [],
        1: ["TDIADI-BV01e", "TDIADI-T01e", "TDIADI-T02e"],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
    manuscriptType: {
      none: {
        0: [],
        1: ["TDIAM-BV01e", "TDIAM-T01e", "TDIAM-T02e"],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
  },
  recommendationDecision: {
    time: {
      none: {
        0: ["TDIATP-L01s", "TDIATP-T01s", "TDIATP-T02s"],
        1: [],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
    editors: {
      none: {
        0: ["TDIAE-BV01s", "TDIAE-BV02s", "TDIAE-BV03s", "TDIAE-BV04s", "TDIAE-BXV01s", "TDIAE-BB01s", "TDIAE-T01s", "TDIAE-T02s"],
        1: ["TDIAE-BV01e", "TDIAE-BV02e", "TDIAE-BV03e", "TDIAE-BV04e", "TDIAE-BB01e", "TDIAE-T01e", "TDIAE-T02e", "TDIAE-BXV01e",],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
    initialDecisions: {
      none: {
        0: ["TDIADI-BV01s", "TDIADI-T01s", "TDIADI-T02s"],
        1: [],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
    manuscriptType: {
      none: {
        0: ["TDIAM-BV01s", "TDIAM-T01s", "TDIAM-T02s"],
        1: [],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
  },
  rejectionRate: {
    timeToInitialDecisionFrEdAss: {
      none: {
        0: [],
        1: [],
      },
      editors: {
        0: ["%RETDIAE-BB01s", "%RETDIAE-BB02s", "%RETDIAE-T01s", "%RETDIAE-T02s",],
        1: ["%RETDIAE-BB01e", "%RETDIAE-BB02e", "%RETDIAE-T01e", "%RETDIAE-T02e",],
      },
    },
  },
  numberOfEditors: {
    time: {
      none: {
        0: ["#ETP-BV01s", "#ETP-T01s", "#ETP-T02s"],
        1: ["#ETP-BV01e", "#ETP-T01e", "#ETP-T02e"],
      },
    },
    countryEditor: {
      none: {
        0: ["#ECE-M01s", "#ECE-M02s", "#ECE-D01s", "#ECE-D02s", "#ECE-T01s", "#ECE-T02s"],
        1: ["#ECE-M01e", "#ECE-M02e", "#ECE-D01e", "#ECE-D02e", "#ECE-T01e", "#ECE-T02e"],
      },
    },
    editAssignFirstRevInvited: {
      none: {
        0: ["#ETFR-H01s"],
        1: ["#ETFR-H01e"],
      },
    },
    assignmentInitialDecision: {
      none: {
        0: ['#ETDIA-H01s'],
        1: ['#ETDIA-H01e'],
      },
    },
    numberOfUniqueReviewersInvited: {
      none: {
        0: ['#E#UI-H01s'],
        1: ['#E#UI-H01e'],
      },
    },
    initialDecisionCompleted: {
      none: {
        0: ['#ETDIL-H01s'],
        1: ['#ETDIL-H01e'],
      },
    },
    numberOfReviewersInvitedPerManuscripts: {
      none: {
        0: ['#E#I-H01s'],
        1: ['#E#I-H01e'],
      },
    }
  },
  timeFromEditorAssignmentToFirstRreviewerIinvited: {
    editors: {
      none: {
        0: ["TFRE-BV01s", "TFRE-BV02s", "TFRE-BV03s", "TFRE-BV04s", "TFRE-T01s", "TFRE-T02s"],
        1: ["TFRE-BV01e", "TFRE-BV02e", "TFRE-BV03e", "TFRE-BV04e", "TFRE-T01e", "TFRE-T02e", "TFRE-T03e",],

      },
    },
    associateEditors: {
      none: {
        3: [
          'TFRAE-BV01r',
          // 'TFRAE-BV02r', 'TFRAE-BV03r', 
          'TFRAE-BV04r',
          // 'TFRAE-T01r', 'TFRAE-T02r', 
          'TFRAE-T03r', 'TFRAE-T04r', 'TFRAE-BV05r',
          // 'TFRAE-BV06r', 'TFRAE-BV07r', 
          'TFRAE-BV08r', "TFRAE-BXV05r",
          // 'TFRAE-T05r', 'TFRAE-T06r', 
          'TFRAE-T07r', 'TFRAE-T08r', "TFRAE-T20r"
        ]
      }
    }
  },
  editorAssignments: {
    editors: {
      none: {
        0: ["EAE-BV01s", "EAE-T01s", "EAE-T02s"],
        1: ["EAE-BV01e", "EAE-T01e", "EAE-T02e"],
      },
    },
  },
  numberOfInvitations: {
    reviewerType: {
      none: {
        0: [],
        1: [],
      },
      outcomeOfInvitationsSbm: {
        0: [],
        1: [],
        3: ["#RIRTOIC-BVS01r", "#RIRTOIC-T01r", "#RIRTOIC-T02r", "#RIRTOIC-T94r"]
      },
      outcomeOfInvitationsAgreeDecline: {
        0: [],
        1: [],
        3: ["#RIRTOIA-BVS01r", "#RIRTOIA-T01r", "#RIRTOIA-T02r"]
      }
    },
    time: {
      none: {
        0: ["#RITP-L02s"],
        1: ["#RITP-L02e", "#CRTP-L03e"],
      },
      outcomeOfInvitationsSbm: {
        0: ["#RITPOIC-BVS01s", "#RITPOIC-BVS02s", "#RITPOIC-CC01s", "#RITPOIC-T01s", "#RITPOIC-T02s",],
        1: ["#RITPOIC-BVS01e", "#RITPOIC-BVS02e", "#RITPOIC-CC01e", "#RITPOIC-T01e", "#RITPOIC-T02e",],
        3: ["#RITPOIC-BVS01r", "#RITPOIC-T01r", "#RITPOIC-T02r"],
      },
      outcomeOfInvitationsAgreeDecline: {
        0: ["#RITPOIA-L01s"],
        1: ["#RITPOIA-L01e"],
        3: ["#RITPOIA-BVS01r", "#RITPOIA-T01r", "#RITPOIA-T02r"]
      },

    },
    outcomeOfInvitationsSbm: {
      none: {
        0: ["#RIOIC-D01s", "#RIOIC-D02s", "#RIOIC-T01s", "#RIOIC-T02s"],
        1: ["#RIOIC-D01e", "#RIOIC-T01e", "#RIOIC-T02e"],
        3: ["#RIOIC-T91r", "#RIOIC-T92r", "#RIOIC-T20r"]
      },
      outcomeOfInvitationsAgreeDecline: {
        0: [],
        1: [],
      },
    },
  },
  timeFromSubmissionToAEAssignment: {
    time: {
      none: {
        3: ["TSAETP-L01r", "TSAETP-T03r", "TSAETP-T04r", "TSAETP-L05r", "TSAETP-BXV05r", "TSAETP-T07r", "TSAETP-T08r", "TSAETP-T20r"]
      }
    },
    revisionNumber: {
      none: {
        3: ["TSAER#-BV01r", "TSAER#-BV04r", "TSAER#-T03r", "TSAER#-T04r", "TSAER#-BV05r", "TSAER#-BV08r", "TSAER#-BXV05r", "TSAER#-T07r", "TSAER#-T08r", "TSAER#-T20r"]
      }
    }
  },
  aeChangedBeforeDecisionLetter: {
    associateEditors: {
      none: {},
      revisionNumber: {
        3: ["AECAER#-T20r"]
      }
    }
  },
  timeForAuthorRevisions: {
    none: {},
    revisionNumber: {
      none: {},
      stageName: {
        3: ["TARR#SN-CC01r", "TARR#SN-T03r", "TARR#SN-T04r", "TARR#SN-CC05r", "TARR#SN-BXV05r", "TARR#SN-T07r", "TARR#SN-T08r", "TARR#SN-T20r"]
      }
    }
  },
  numberOfCompletedReviews: {
    time: {
      none: {
        0: ["#CRTP-L03s", "#CRTP-T03s", "#CRTP-T04s"],
        1: ["#CRTP-L03e", "#CRTP-T03e", "#CRTP-T04e"],
      },
    },
  },
  timeInPeerReview: {
    time: {
      none: {
        0: [
          "TCRTP-BV01s",
          "TCRTP-BV02s",
          "TCRTP-BV03s",
          "TCRTP-BV04s",
          "TCRTP-L01s",
          "TCRTP-T01s",
          "TCRTP-T02s",
          "TCRTP-T03s",
          "TCRTP-T04s",
          "TCRTP-BV05s",
          "TCRTP-BV06s",
          "TCRTP-BV07s",
          "TCRTP-BV08s",
          "TCRTP-L05s",
          "TCRTP-BXV05s",
          "TCRTP-T05s",
          "TCRTP-T06s",
          "TCRTP-T07s",
          "TCRTP-T08s",
        ],
        1: [
          "TCRTP-L01e",
          "TCRTP-T01e",
          "TCRTP-T02e",
          "TCRTP-T03e",
          "TCRTP-T04e",
          "TCRTP-L05e",
          "TCRTP-BXV01e",
          "TCRTP-T05e",
          "TCRTP-T06e",
          "TCRTP-T07e"
        ],
        3: [
          "TCRTP-BV01r",
          // "TCRTP-BV02r", 
          // "TCRTP-BV03r", 
          "TCRTP-BV04r",
          "TCRTP-L01r",
          // "TCRTP-T01r", 
          // "TCRTP-T02r", 
          "TCRTP-T03r",
          "TCRTP-T04r",
          "TCRTP-BV05r",
          // "TCRTP-BV07r", 
          "TCRTP-BV08r",
          "TCRTP-L05r",
          "TCRTP-BXV05r",
          "TCRTP-T07r",
          "TCRTP-T08r",
          "TCRTP-T20r",
        ]
      },
      reviewerType: {
        3: [
          "TCRTPRT-L01r",
          "TCRTPRT-T03r",
          "TCRTPRT-T04r",
          "TCRTPRT-L05r",
          "TCRTPRT-T07r",
          "TCRTPRT-T08r",
          "TCRTPRT-T20r",
        ]
      }
    },
    reviewerType: {
      none: {
        3: [
          "TCRRT-BV01r",
          "TCRRT-BV04r",
          "TCRRT-L01r",
          "TCRRT-T03r",
          "TCRRT-T04r",
          "TCRRT-BV05r",
          "TCRRT-BV08r",
          "TCRRT-L05r",
          "TCRRT-BXV05r",
          "TCRRT-T07r",
          "TCRRT-T08r",
          "TCRRT-T20r",
        ]
      }
    }
  },
  totalTimeWithPeerReviewers: {
    time: {
      none: {
        0: ["TPRTP-BV01s", "TPRTP-BV02s", "TPRTP-BV03s", "TPRTP-BV04s", "TPRTP-L01s", "TPRTP-T01s", "TPRTP-T02s", "TPRTP-T03s", "TPRTP-T04s", "TPRTP-BV05s", "TPRTP-BV06s", "TPRTP-BV07s", "TPRTP-BV08s", "TPRTP-L05s", "TPRTP-BXV05s", "TPRTP-T05s", "TPRTP-T06s", "TPRTP-T07s", "TPRTP-T08s"],
        1: ["TPRTP-L01e", "TPRTP-T01e"],
        3: ["TPRTP-BV01r", "TPRTP-BV04r", "TPRTP-L01r", "TPRTP-T03r", "TPRTP-T04r", "TPRTP-BV05r", "TPRTP-BV08r", "TPRTP-L05r", "TPRTP-BXV05r", "TPRTP-T07r", "TPRTP-T08r", "TPRTP-T20r"]
      },
    },
  },
  invitedToAgreed: {
    time: {
      none: {
        0: ["IACTP-L01s", "IACTP-T01s", "IACTP-T02s"],
        1: ["IACTP-L01e"],
        3: ["IACTP-L01r", "IACTP-T01r", "IACTP-T02r",]
      },
      reviewerType: {
        3: ["IACTPRT-L01r", "IACTPRT-T01r", "IACTPRT-T02r",]
      }
    },
  },
  invitedToCompleted: {
    time: {
      none: {
        0: ["ICCTP-L01s", "ICCTP-T01s", "ICCTP-T02s"],
        1: ["ICCTP-L01e"],
        3: ["ICCTP-L01r", "ICCTP-T01r", "ICCTP-T02r"]
      },
      reviewType: {
        3: ["ICCTPRT-L01r", "ICCTPRT-T01r", "ICCTPRT-T02r"]
      }
    },
  },
  agreedToCompleted: {
    time: {
      none: {
        0: ["ACCTP-L01s", "ACCTP-T01s", "ACCTP-T02s"],
        1: ["ACCTP-L01e"],
        3: ["ACCTP-L01r", "ACCTP-T01r", "ACCTP-T02r",]
      },
      reviewerType: {
        3: ["ACCTPRT-L01r", "ACCTPRT-T01r", "ACCTPRT-T02r",]
      }
    },
  },
  initialDecisionCompleted: {
    time: {
      none: {
        0: [],
        1: ["TDILTP-L01e", "TDILTP-T01e", "TDILTP-T02e"],
      },
      editors: {
        0: [],
        1: [],
      },
      initialDecisions: {
        0: [],
        1: [],
      },
      sectionCategory: {
        0: [],
        1: ["TDILTPSC-BVS01e"],
      },
    },
    editors: {
      none: {
        0: [],
        1: ["TDILE-BV01e", "TDILE-BV02e", "TDILE-BV03e", "TDILE-BV04e", "TDILE-T01e", "TDILE-T02e"],
      },
      time: {
        0: [],
        1: [],
      },
      initialDecisions: {
        0: [],
        1: ["TDILEDI-CC01e", "TDILEDI-T01e"],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
    initialDecisions: {
      none: {
        0: [],
        1: [],
      },
      time: {
        0: [],
        1: [],
      },
      editors: {
        0: [],
        1: ["TDILDIE-CC01e", "TDILDIE-T01e"],
      },
      sectionCategory: {
        0: [],
        1: [],
      },
    },
  },
  initialRecommendationDecisionCompleted: {
    time: {
      none: {
        0: ["TDILTP-L01s", "TDILTP-T01s", "TDILTP-T02s"],
        1: [],
      },
      time: {
        0: [],
        1: [],
      },
      editors: {
        0: [],
        1: [],
      },
    },
    editors: {
      none: {
        0: ["TDILE-BV01s", "TDILE-BV02s", "TDILE-BV03s", "TDILE-BV04s", "TDILE-T01s", "TDILE-T02s"],
        1: ["TDILE-BV01e", "TDILE-BV02e", "TDILE-BV03e", "TDILE-BV04e", "TDILE-T01e", "TDILE-T02e"],
      },
      initialDecisions: {
        0: ["TDILEDI-CC01s", "TDILEDI-T01s"],
        1: [],
      },
    },
    initialDecisions: {
      none: {
        0: [],
        1: [],
      },
      editors: {
        0: ["TDILDIE-CC01s", "TDILDIE-T01s"],
        1: ["TDILDIE-CC01e", "TDILDIE-T01e"],
      },
    },
  },
  numberOfReviewers: {
    countries: {
      none: {
        0: ["#RCR-BV01s", "#RCR-D01s", "#RCR-D02s", "#RCR-M01s", "#RCR-M02s", "#RCR-T01s", "#RCR-T02s", "#RCR-BV03s", "#RCR-M03s", "#RCR-M04s", "#RCR-T03s", "#RCR-T04s",],
        1: ["#RCR-BV01e", "#RCR-M01e", "#RCR-M02e", "#RCR-D01e", "#RCR-D02e", "#RCR-T01e", "#RCR-T02e", "#RCR-BV03e", "#RCR-M03e", "#RCR-T03e", "#RCR-T04e"],
      },
      continents: {
        0: [],
        1: [],
      },
      time: {
        0: [],
        1: [],
      },
    },
    continents: {
      none: {
        0: ["#RCTR-BV01s", "#RCTR-D01s", "#RCTR-T01s", "#RCTR-T02s", "#RCTR-BV03s", "#RCTR-D03s", "#RCTR-T03s", "#RCTR-T04s",],
        1: ["#RCTR-BV01e", "#RCTR-D01e", "#RCTR-T01e", "#RCTR-T02e"],
        //,"#RCTR-D02e"
      },
      countries: {
        0: [],
        1: [],
      },
      time: {
        0: [],
        1: [],
      },
    },
    time: {
      none: {
        0: [],
        1: [],
      },
      countries: {
        0: [],
        1: [],
      },
      continents: {
        0: [],
        1: ["#RTPCTR-CC01e", "#RTPCTR-T01e"],
      },
    },
  },
  numberOfUniqueReviewersInvited: {
    editors: {
      none: {
        0: ["#UIE-BV01s", "#UIE-T01s", "#UIE-T02s"],
        1: ["#UIE-BV01e", "#UIE-T01e", "#UIE-T02e"],
      },
    },
  },
  numberOfReviewersInvitedPerManuscripts: {
    editors: {
      none: {
        0: ["#IE-BV01s", "#IE-T01s", "#IE-T02s"],
        1: ["#IE-BV01e", "#IE-T01e", "#IE-T02e", "#IE-BV05e", "#IE-BXV05e", "#IE-T05e", "#IE-T06e", "#IE-T07e", "#IE-T08e"],
      },
    },
    associateEditors: {
      none: {},
      reviewType: {
        3: ["#IAERT-CC01r", "#IAERT-T01r", "#IAERT-T02r", "#IAERT-T91r"]
      },
    },
  },
  numberOfSubmissions: {
    revisionNumber: {
      totalReturnsToAuthorBeforeAEPCORIAssignment: {
        3: ["#SR##R-CC01r", "#SR##R-T01r", "#SR##R-T02r", "#SR##R-T20r"]
      },
    },
  },
  numberOfUniqueReviewers: {
    time: {
      none: {},
      reviewerType: {
        3: ["#RTPRT-CC01r", "#RTPRT-T01r", "#RTPRT-T02r", "#RTPRT-T20r"],
      },
    },
  },
  submissionsReturnedToAuthor: {
    none: {},
    revisionNumber: {
      none: {},
      stageName: {
        3: ["SRAR#SN-BVS01r", "SRAR#SN-CC01r", "SRAR#SN-T01r", "SRAR#SN-T02r", "SRAR#SN-T20r"]
      }
    }
  },
  timeForAuthorCorrections: {
    none: {},
    revisionNumber: {
      none: {},
      stageName: {
        3: ['TACR#SN-CC01r', 'TACR#SN-T03r', 'TACR#SN-T04r', 'TACR#SN-CC05r', 'TACR#SN-T07r', 'TACR#SN-T08r', 'TACR#SN-T20r',]
      }
    }
  },
  numberOfConsultingEditorsRequested: {
    none: {

    },
    stageName: {
      none: {

      },
      associateEditorRequestingCE: {
        3: ["#CESNAE-BVS01r", "#CESNAE-CC01r", "#CESNAE-T01r", "#CESNAE-T02r", "#CESNAE-T71r", "#CESNAE-T20r",]
      }
    }
  },
  rejectedInitialBasedOnDecisionDate: {
    time: {
      none: {
        2: ["%RIdTP-BV01p", "%RIdTP-BH01p", "%RIdTP-L01p", "%RIdTP-T01p", "%RIdTP-T02p",],
      },
    },
    countries: {
      none: {
        2: ["%RIdC-BV01p", "%RIdC-BH01p", "%RIdC-M01p", "%RIdC-M02p", "%RIdC-T01p", "%RIdC-T02p",]
      }
    },
    primaryCategory: {
      none: {
        2: ["%RIdPC-BV01p", "%RIdPC-BH01p", "%RIdPC-T01p", "%RIdPC-T02p",]
      }
    },
  },
  rejectedFinalBasedOnDecisionDate: {
    time: {
      none: {
        2: ["%RFdTP-BV01p", "%RFdTP-BH01p", "%RFdTP-L01p", "%RFdTP-T01p", "%RFdTP-T02p",],
      },
    },
    countries: {
      none: {
        2: ["%RFdC-BV01p", "%RFdC-BH01p", "%RFdC-M01p", "%RFdC-M02p", "%RFdC-T01p", "%RFdC-T02p",]
      }
    },
    primaryCategory: {
      none: {
        2: ["%RFdPC-BV01p", "%RFdPC-BH01p", "%RFdPC-T01p", "%RFdPC-T02p",]
      }
    },
  },
  rejectedInitialBasedOnSubmissionDateQC: {
    time: {
      none: {
        2: ["%RIsTPqc-BV01p", "%RIsTPqc-BH01p", "%RIsTPqc-L01p", "%RIsTPqc-T01p", "%RIsTPqc-T02p",],
      },
    },
    primaryCategory: {
      none: {
        2: ["%RIsPCqc-BV01p", "%RIsPCqc-BH01p", "%RIsPCqc-T01p", "%RIsPCqc-T02p",]
      }
    },
  }
};

export { chartNamesList, reportTypesList, variablesList, chartTypeList, allVariables, allReportTypesList };