import moment from "moment";
import * as actions from "store/actionTypes";
import * as data from "./data";
// import _ from "lodash";
import chartsSidebarConfig from "config/charts/sidebar.json";
import shortid from "shortid";
import {
  CHANGE_DEFAULT_CONFIGURATION_STP,
  // SET_SORT_SEGMENT_ZA,
  // SIDE_BAR_INCLUDE_CHANGE,
  // SIDE_BAR_INCLUDE_CHANGE_RETDIAE,
  SIDE_BAR_INCLUDE_CHANGE_SECOND,
} from "../../../../actionTypes";
// import { tree } from "d3";
import _ from "lodash";

function checkIfEndOfMonth(endDate) {
  // Use moment to check if endDate is equal to the end of the month
  const wantEndDate = moment(endDate)
  const isEndOfMonth = wantEndDate.isSame(wantEndDate.clone().endOf('month'), 'day');

  // Return 0 if it's the end of the month, otherwise return 1
  return isEndOfMonth ? 0 : 1;
}

function getQuarter(date) {
  return date.getFullYear() + "Q" + Math.ceil((date.getMonth() + 1) / 3);
}

function listQuarters(sDate, eDate) {
  // Ensure start is the earlier date;
  if (sDate > eDate) {
    var t = eDate;
    eDate = sDate;
    sDate = t;
  }

  // Copy input start date do don't affect original
  sDate = new Date(sDate);

  // Set to 2nd of month so adding months doesn't roll over
  // and not affected by daylight saving
  sDate.setDate(2);

  // Initialise result array with start quarter
  var startQ = getQuarter(sDate);
  var endQ = getQuarter(eDate);
  var result = [startQ];

  // List quarters from start to end
  while (startQ !== endQ) {
    sDate.setMonth(sDate.getMonth() + 3);
    startQ = getQuarter(sDate);
    result.push(startQ);
  }
  return result;
}
const { quartersData, monthsData } = data;
const EditorRoleNoEIC = [
  "TDIATPSC-CC01e",
  "TDIATP-L01e",
  "TDIATPSC-T01e",
  "TDIATPSC-T02e",
  "TDIAE-BV01e",
  "TDIAE-BV02e",
  "TDIAE-BV03e",
  "TDIAE-BV04e",
  "TDIAE-BXV01e",
  "TDIAE-T02e",
  "TDIAE-BB01e",
  "TDIAE-T01e",
  "TDIADI-BV01e",
  "TDIADI-T01e",
  "TDIADI-T01e",
  "TDIAM-BV01e",
  "TDIAM-T01e",
  "TDIAM-T02e",
  "TDILTP-L01e",
  "TDILTPSC-BVS01e",
  "TDILTP-T01e",
  "TDILTP-T02e",
  "TDFATP-L01e",
  "TDFATP-T01e",
  "TDFATP-T02e",
  "TDILE-BV01e",
  "TDSLAE-BV01r",
  "TDSLAE-BV02r",
  "TDSLAE-BV03r",
  "TDSLAE-BV04r",
  "TCDLAELS-T96r",
  "TAEDAER#-T77r",
  "TAEDAER#-T20r",
  "#AAE-BVS01r",
  "#AAE-CC01r",
  "#AAE-T01r",
  "#AAE-T02r",
  "#AAE-T20r",
  "#CESNAE-BVS01r",
  "#CESNAE-CC01r",
  "#CESNAE-T01r",
  "#CESNAE-T02r",
  "#CESNAE-T71r",
  "#CESNAE-T20r",
  "TCDLAELS-T99r",
  "TDSLAE-BV05r",
  "TDSLAE-BV06r",
  "TDSLAE-BV07r",
  "TDSLAE-BV08r",
  "TDSLAE-BXV05r",
  "TDSLAE-T01r",
  "TDSLAE-T02r",
  "TDSLAE-T03r",
  "TDSLAE-T04r",
  "TDSLAE-T05r",
  "TDSLAE-T06r",
  "TDSLAE-T07r",
  "TDSLAE-T08r",
  "TDSLAE-T20r",
  "TCDLAELS-T961r",
  "TCDLAELS-T99r",
  "TAEDAER#-T77r",
  "TAEDAER#-T20r",
  "TCDLAELS-BVS01r",
  "TCDLAELS-T01r",
  "TCDLAELS-T02r",
  "TCDLAER#-CC01r",
  "TCDLAER#-T01r",
  "TCDLAER#-T04r",
  "TCDLAER#-CC05r",
  "TCDLAER#-T05r",
  "TCDLAER#-T08r",
  "TCDLAER#-T20r",
  "TCSLAE-BV01r",
  // "TCSLAE-BV02r",
  // "TCSLAE-BV03r",
  "TCSLAE-BV04r",
  // "TCSLAE-T01r",
  // "TCSLAE-T02r",
  "TCSLAE-T03r",
  "TCSLAE-T04r",
  "TCSLAE-T07r",
  "TCSLAE-T08r",
  "TCSLAE-T20r",
  "TCDLAELS-T05r",
  "TCDLAELS-T06r",
  "TCDLAELS-T20r",
  "TCSLAE-BV05r",
  // "TCSLAE-BV06r",
  // "TCSLAE-BV07r",
  "TCSLAE-BV08r",
  "TCDLR#AE-T97r",
  "TLRAE-T01r",
  "TLRAE-T02r",
  "TLRAE-T03r",
  "TLRAE-T04r",
  "TLRAE-BV01r",
  "TDILE-BV02e",
  "TDILE-BV03e",
  "TDILE-BV04e",
  "TDILE-T01e",
  "TDILE-T02e",
  "TDILEDI-CC01e",
  "TDILEDI-T01e",
  "TDILDIE-CC01e",
  "TDILDIE-T01e",
  "TFRE-BV01e",
  "TFRAE-BV01r",
  "TFRAE-BV02r",
  "TFRAE-BV03r",
  "TFRAE-BV04r",
  "TFRAE-BV05r",
  "TFRAE-BV06r",
  "TFRAE-BV07r",
  "TFRAE-BV08r",
  "TFRAE-T20r",
  "TFLRAE-BV01r",
  "TFLRAE-BV02r",
  "TFLRAE-BV03r",
  "TFLRAE-BV04r",
  "TFLRAE-BV05r",
  "TFLRAE-BV06r",
  "TFLRAE-BV07r",
  "TFLRAE-BV08r",
  "TFLRAE-BXV05r",
  "TFRAE-BXV05r",
  "TCSLAE-BXV05r",
  "TFLRAE-T01r",
  "TFLRAE-T02r",
  "TFLRAE-T03r",
  "TFLRAE-T04r",
  "TFLRAE-T05r",
  "TFLRAE-T06r",
  "TFLRAE-T07r",
  "TFLRAE-T08r",
  "TFLRAE-T20r",
  "TFRE-BV02e",
  "TFRE-BV03e",
  "TFRE-BV04e",
  "TFRE-T03e",
  "TFRE-T01e",
  "TFRE-T02e",
  "%RETDIAE-BB01e",
  "%RETDIAE-BB02e",
];
const defaultState = {
  showCreateGroupModal: false,
  showUnGroupModal: false,
  initialDecisionModalValues: {
    accept: false,
    reject: false,
  },
  config: {
    Editors: true,
    Reviewers: true,
    "Article flags": true,
    "Stage name": true,
    "Letter Stages": true,
    "Manuscript types": true,
    "Editorial Status": true,
    "Section Category": true,
    "Manuscript versions": true,
    "Decision types": true,
    Countries: true,
    Continents: false,
    "Date range": true,
    "Show time as": true,
    "Dates for Graph": {
      "Year on year": true,
      Year: true,
      "Previous years": false,
      "Previous quarters": true,
      "Previous months": true,
    },
  },
  editors: {
    title: "Editor Name",
    radioChecked: 0,
    radioName: "editors",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  articleFlags: {
    title: "Article flags",
    radioChecked: 0,
    radioName: "article flags",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  stageName: {
    title: "Stage name",
    radioChecked: 0,
    radioName: "stage name",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  letterStages: {
    title: "Letter Stages",
    radioChecked: 0,
    radioName: "letter stages",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  primaryCategory: {
    title: "Primary Category",
    radioChecked: 0,
    radioName: "primary category",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  initialDecisions: {
    title: "Initial Decisions",
    radioChecked: 0,
    radioName: "initial decisions",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  publicationType: {
    title: "Publication Type",
    radioChecked: 0,
    radioName: "publication type",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  currentStage: {
    title: "Current Stage",
    radioChecked: 0,
    radioName: "Current Stage",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  reviewers: {
    title: "Reviewers",
    radioChecked: 0,
    radioName: "reviewers",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  manuscriptTypes: {
    title: "Manuscript types",
    radioChecked: 0,
    radioName: "manuscript types",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,

    renamedFields: {},
    groups: [],
  },
  editorialStatus: {
    title: "Editorial Status",
    radioChecked: 0,
    radioName: "editorial status",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,

    renamedFields: {},
    groups: [],
  },
  sectionCategory: {
    title: "Section/Category",
    radioChecked: 0,
    radioName: "section category",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  manuscriptVersions: {
    title: "Manuscript versions",
    radioChecked: 0,
    radioName: "manuscript versions",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  decisionTypes: {
    title: "Decision types",
    initialDecisionsTitle: "Initial decisions",
    finalDecisions: "Final decisions",
    radioChecked: 0,
    radioName: "decision types",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
    renameName: {},
  },
  initialDecisionTypes: {
    title: "Initial Decision Type",
    initialDecisionsTitle: "Initial decisions",
    radioChecked: 0,
    radioName: "initial decision type",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
    renameName: {},
  },
  finalDecisionType: {
    title: "Final Decision Type",
    finalDecisionsTitle: "Final decisions",
    radioChecked: 0,
    radioName: "final decision type",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
    renameName: {},
  },
  editorRoleType: {
    title: "Editor Role",
    radioChecked: 0,
    radioName: "editor role",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  reviewerInvitationType: {
    title: "Outcome of Invitation",
    radioChecked: 0,
    radioName: "reviewer invitation",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  reviewerRoleType: {
    title: "Reviewer Role",
    radioChecked: 0,
    radioName: "reviewer role",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  userFlagsType: {
    title: "User Flags",
    radioChecked: 0,
    radioName: "user flags",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  reviewerPeopleIDType: {
    title: "Reviewer ID Number",
    radioChecked: 0,
    radioName: "reviewer ID number",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  reviewerIsBoardMemberType: {
    title: "Board Member",
    radioChecked: 0,
    radioName: "board member",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  countries: {
    title: "Countries",
    countryManuscript: "Country - Manuscript",
    countryEditor: "Country - Editor",
    countryReviewer: "Country - Reviewer",
    radioChecked: 0,
    radioName: "countries",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
    renamedFields: {},
  },
  // countryReviewer: {
  //     title: "Country - Reviewer",
  //     countryManuscript: 'Country - Manuscript',
  //     countryEditor: 'Country - Editor',
  //     countryReviewer: 'Country - Reviewer',
  //     radioChecked: 0,
  //     radioName: "countries",
  //     search: "",
  //     searchList: [],
  //     focus: false,
  //     list: [],
  //     locked: false,
  // },
  continents: {
    title: "Continents",
    radioChecked: 0,
    radioName: "continents",
    search: "",
    searchList: [],
    focus: false,
    list: [],
    locked: false,
  },
  //    continentsReviewer : {
  //         title: "Continents - Reviewer",
  //         radioChecked: 0,
  //         radioName: "continentsReviewer",
  //         search: "",
  //         searchList: [],
  //         focus: false,
  //         list: [],
  //         locked: false,
  //     },
  dateRange: {
    title: "Date range",
    radioChecked: 0,
    radioName: "date range",
    entireStart: "",
    entireEnd: "",
    start: "",
    end: "",
  },
  customYearRange: {
    title: "year Range",
    radioChecked: -1,
    radioName: "customYearRange",
    customDate: "",
  },
  defaultYearRange: {
    title: "year Range",
    radioChecked: 1,
    radioName: "defaultYearRange",
    start: "",
    end: "",
  },
  dateRangeX: {
    title: "Date range",
    radioChecked: -1,
    radioName: "date range",
    entireStart: "",
    entireEnd: "",
    start: "",
    end: "",
  },
  dataForPrevious: {
    title: "Previous year",
    radioChecked: -1,
    radioName: "dates for previous",
    specificYear: {
      id: 1,
      title: "Year",
      name: "Single Year",
      selectedItem: {},
      list: [],
    },
  },
  showTimeAs: {
    title: "Show time as",
    radioChecked: 0,
    radioName: "Show time as",
  },
  datesForGraph: {
    title: "Show time as",
    radioChecked: 0,
    radioName: "dates for graph",
    datesForGraphList: [
      { id: 3, title: "Year", name: "Single Year", selectedItem: {}, list: [] },
      // {id: 0, title: "Previous years", selectedItem: datesForGraphList[1].list[0], list: datesForGraphList[1].list},
      {
        id: 1,
        title: "Previous quarters",
        name: "Most recent",
        selectedItem: {},
        list: [],
      },
      {
        id: 2,
        title: "Previous months",
        name: "Most recent",
        selectedItem: {},
        list: [],
      },
    ],
  },
  mostRecentYears: {
    title: "include all dates year",
    radioChecked: 5,
    radioName: "most recent years",
    mostRecentYearsSelect: {
      option: { value: "1", key: "1" },
      list: [
        { value: "1", key: "1" },
        { value: "2", key: "2" },
        { value: "3", key: "3" },
        { value: "4", key: "4" },
        { value: "5", key: "5" },
        { value: "6", key: "6" },
        { value: "7", key: "7" },
        { value: "8", key: "8" },
        { value: "9", key: "9" },
        { value: "10", key: "10" },
        { value: "11", key: "11" },
        { value: "12", key: "12" },
        { value: "13", key: "13" },
        { value: "14", key: "14" },
        { value: "15", key: "15" },
        { value: "16", key: "16" },
      ],
    },
  },
  mostRecentQuarters: {
    title: "most recent quarters",
    radioChecked: -1,
    radioName: "most recent quarters",
    mostRecentQuartersSelect: {
      option: { value: "1", key: "1" },
      list: [
        { value: "1", key: "1" },
        { value: "2", key: "2" },
        { value: "3", key: "3" },
        { value: "4", key: "4" },
        { value: "5", key: "5" },
        { value: "6", key: "6" },
        { value: "7", key: "7" },
        { value: "8", key: "8" },
        { value: "9", key: "9" },
        { value: "10", key: "10" },
        { value: "11", key: "11" },
        { value: "12", key: "12" },
        { value: "13", key: "13" },
        { value: "14", key: "14" },
        { value: "15", key: "15" },
        { value: "16", key: "16" },
      ],
    },
  },
  mostRecentMonth: {
    title: "most recent month",
    radioChecked: -1,
    radioName: "most recent month",
    mostRecentMonthSelect: {
      option: { value: "1", key: "1" },
      list: [
        { value: "1", key: "1" },
        { value: "2", key: "2" },
        { value: "3", key: "3" },
        { value: "4", key: "4" },
        { value: "5", key: "5" },
        { value: "6", key: "6" },
        { value: "7", key: "7" },
        { value: "8", key: "8" },
        { value: "9", key: "9" },
        { value: "10", key: "10" },
        { value: "11", key: "11" },
        { value: "12", key: "12" },
        { value: "13", key: "13" },
        { value: "14", key: "14" },
        { value: "15", key: "15" },
        { value: "16", key: "16" },
      ],
    },
  },
  startYearData: {
    radioChecked: -1,
    radioName: "start year data",
    startYearDataSelect: {
      option: { value: "1", key: "Jan. 1 - Dec. 31" },
      list: [
        { value: "1", key: "Jan. 1 - Dec. 31" },
        { value: "2", key: "Apr. 1 - Mar. 31" },
        { value: "3", key: "July. 1 - June. 30" },
        { value: "4", key: "Oct. 1 - Sept. 30" },
      ],
    },
  },
  yearStart: {
    radioChecked: {
      value: "1",
      key: "Jan. 1 - Dec. 31",
    },
  },
  date: {
    start: "",
    end: "",
  },
  data: {
    paletteColors: ["#e83223", "#ef9135", "#f9da49", "#67d143", "#469ff8"],
    data_order_sort: {
      id: 0,
      text: "Values (descending)",
      value: "descValue",
    },
    separate_sort_value: {
      id: 2,
      text: "Text (A-Z)",
      value: "ascLegend",
    },
    changed_colors: [],
    fileName: "",
    reportType: 1,
    chartType: "",
    editors: [],
    reviewers: [],
    articleFlags: 0,
    stageName: 0,
    letterStages: 0,
    primaryCategory: 0,
    initialDecisions: 0,
    publicationType: 0,
    currentStage: 0,
    manuscriptType: 0,

    editorRoleType: 0,
    finalDecisionType: 0,
    initialDecisionTypes: 0,
    reviewerRoleType: 0,
    userFlagsType: 0,
    reviewerPeopleIDType: 0,
    reviewerIsBoardMemberType: 0,
    reviewerInvitationType: 0,

    selectedFirstInitialDecisionTypes: [],
    selectedFirstFinalDecisionType: [],
    selectedFirstEditorRoleType: [],
    selectedFirstReviewerRoleType: [],
    selectedFirstReviewerPeopleIDType: [],
    selectedFirstReviewerIsBoardMemberType: [],

    selectedFirstManTypes: [],
    selectedSecondManTypes: [],
    selectedSecondEditStatus: [],
    selectedFirstEditStatus: [],
    selectedSecondSecCategory: [],
    selectedFirstSecCategory: [],
    selectedDecisionTypes: [],
    selectedFirstCountries: [],
    selectedSecondCountries: [],
    selectedFirstCountryReviewer: [],
    selectedSecondCountryReviewer: [],
    selectedFirstInvitationReviewer: [],
    selectedSecondInvitationReviewer: [],
    selectedFirstArticleFlags: [],
    selectedSecondArticleFlags: [],
    selectedFirstLetterStages: [],
    selectedSecondLetterStages: [],
    selectedFirstPrimaryCategory: [],
    selectedSecondPrimaryCategory: [],
    selectedFirstInitialDecisions: [],
    selectedSecondInitialDecisions: [],
    selectedFirstPublicationType: [],
    selectedSecondPublicationType: [],
    selectedFirstCurrentStage: [],
    selectedSecondCurrentStage: [],
    revisionType: 0,
    choosenRevisionNumbers: [],
    revisionNumber: -1,
    decisionType: 0,
    countryType: 0,
    dateType: 0,
    quarter: 1,
    startYearRadio: 0,
    month: 1,
    year: 2020,
    dateRangeType: 0,
    startDate: new Date(),
    endDate: new Date(),
    activeContent: null,
    zeroAcceptCountRadio: null,
    zeroRejectCountRadio: null,
    continents: [],
    previusCheck: {},
    // continentsReviewer:[],
    mostRecentYearsSelect: { value: "1", key: "1" },
    mostRecentQuartersSelect: { value: "1", key: "1" },
    mostRecentMonthSelect: { value: "1", key: "1" },
    mostRecentStartYearSelect: { value: "1", key: "Jan. 1 - Dec. 31" },
    manuscriptSystem: 0,
    includeManuscriptsWithNoReviews: true,
  },
  selectedPrevItemSet: {},
  tableSorting: {
    id: 0,
    type: "asc",
  },
};
let defaultData = {};

const unnecessaryFields = [
  "dateRange",
  "datesForGraph",
  "date",
  "data",
  "selectedPrevItemSet",
  "config",
];

const dataConfigs = Object.keys(defaultState).filter(
  (i) => !unnecessaryFields.includes(i)
);

function combineGroupValues(list) {
  let combinedArray = [];
  list.forEach(el => {
    if (el.groupValues) {
      combinedArray = [...combinedArray, ...el.groupValues];
    }
  });
  return combinedArray;
}

function calculateGroupTotalCount(currentGroupValues, combinedArray) {
  return currentGroupValues.reduce((sum, item) => {
    return combinedArray.includes(item.text) ? sum : sum + item.count;
  }, 0);
}

function updateListWithNewGroup(list, newCurrentGroupValues, combinedArray, newName) {
  return list.map(item => {
    if (newCurrentGroupValues.includes(item.text) && !combinedArray.includes(item.text)) {
      return {
        ...item,
        hide: true,
        groupName: newName,
      };
    }
    return item;
  });
}


function fillLockedData(state, resetData) {
  const newResetData = { ...resetData };
  dataConfigs.forEach((item) => {
    if (state[item]?.locked) {
      newResetData[item] = {
        ...state[item],
        searchList: [...state[item].searchList],
        list: [...state[item].list],
        locked: true,
      };
      const firs = includeNameItem(item);
      const second = excludeNameItem(item);
      const name = nameChange(item);
      const renameType = renameName(item);
      if (item === "manuscriptVersions") {
        newResetData.data = {
          ...resetData.data,
          [firs]: [...state.data[firs]],
          [name]: state.data[name],
        };
      } else {
        newResetData.data = {
          ...resetData.data,
          [second]: [...state.data[second]],
          [firs]: [...state.data[firs]],
          [name]: state.data[name],
          [renameType]: state.data[renameType],
        };
      }
    }
  });
  if (state.dateRange.locked) {
    newResetData.dateRange = { ...state.dateRange };
    newResetData.dateRangeX = { ...state.dateRangeX };
    newResetData.mostRecentQuarters = {
      ...state.mostRecentQuarters,
      mostRecentQuartersSelect: {
        ...state.mostRecentQuarters.mostRecentQuartersSelect,
        option: state.data.mostRecentQuartersSelect,
      },
    };
    newResetData.mostRecentMonth = {
      ...state.mostRecentMonth,
      mostRecentMonthSelect: {
        ...state.mostRecentMonth.mostRecentMonthSelect,
        option: state.data.mostRecentMonthSelect,
      },
    };
    newResetData.startYearData = {
      ...state.startYearData,
      startYearDataSelect: {
        ...state.startYearData.startYearDataSelect,
        option: state.data.startYearDataSelect,
      },
    };
    newResetData.mostRecentYears = {
      ...state.mostRecentYears,
      mostRecentYearsSelect: {
        ...state.mostRecentYears.mostRecentYearsSelect,
        option: state.data.mostRecentYearsSelect,
      },
    };
    newResetData.data.quarter = state.data.quarter;
    newResetData.data.month = state.data.month;
    newResetData.mostRecentQuartersSelect = {
      ...state.mostRecentQuartersSelect,
    };
    newResetData.mostRecentMonthSelect = { ...state.mostRecentMonthSelect };
    newResetData.mostRecentYearsSelect = { ...state.mostRecentYearsSelect };
    newResetData.startYearDataSelect = { ...state.startYearDataSelect };
    newResetData.dataForPrevious = { ...state.dataForPrevious };
    newResetData.date = { ...state.date };
    newResetData.datesForGraph = { ...state.datesForGraph };
    newResetData.data = {
      ...newResetData.data,
      dataForPrevious: state.data.dataForPrevious,
      mostRecentQuartersSelect: state.data.mostRecentQuartersSelect,
      mostRecentMonthSelect: state.data.mostRecentMonthSelect,
      startYearDataSelect: state.data.startYearDataSelect,
      mostRecentYearsSelect: state.data.mostRecentYearsSelect,
      dateRangeType: state.data.dateRangeType,
      startDate: state.data.startDate,
      endDate: state.data.endDate,
      year: state.data.year,
      dateType: state.data.dateType,
    };
  }
  return newResetData;
}

function fillData(list, type, data, isCustomUpload) {
  if (data?.locked && data?.list.length > 0 && !isCustomUpload) {
    return [...data?.list];
  }

  return (
    list &&
    list.map((item) => {
      let clonedItem = { ...item };
      clonedItem.exclude = false;
      clonedItem.include =
        type === "manuscriptVersions" && clonedItem.text === "R0";

      return clonedItem;
    })
  );
}

function renameName(name) {
  switch (name) {
    case "countries":
      return "countriesRenamedFields"; //done on back
    case "manuscriptTypes":
      return "manuscriptTypeRenamedFields"; //done on back
    case "editorialStatus":
      return "editorialStatusRenamedFields"; //done on back
    case "sectionCategory":
      return "sectionCategoryRenamedFields"; //done on back
    case "manuscriptVersions":
      return "revisionTypeRenamedFields";
    case "initialDecisionTypes":
      return "initialDecisionTypesRenamedFields";
    case "finalDecisionType":
      return "finalDecisionTypeRenamedFields";
    case "editorRoleType":
      return "editorRoleTypeRenamedFields";
    case "reviewerRoleType":
      return "reviewerRoleTypeRenamedFields";
    case "userFlagsType":
      return "userFlagsTypeRenamedFields";
    case "reviewerInvitationType":
      return "reviewerInvitationTypeRenamedFields";
    case "reviewerPeopleIDType":
      return "reviewerPeopleIDTypeRenamedFields";
    case "reviewerIsBoardMemberType":
      return "reviewerIsBoardMemberTypeRenamedFields";
    case "editors":
      return "editorsRenamedFields";
    case "articleFlags":
      return "articleFlagsRenamedFields";
    case "stageName":
      return "stageNameRenamedFields"
    case "letterStages":
      return "letterStagesRenamedFields";
    case "decisionTypes":
      return "decisionTypeRenamedFields";
    case "primaryCategory":
      return "primaryCategoryRenamedFields";
    case "initialDecisions":
      return "initialDecisionsRenamedFields";
    case "publicationType":
      return "publicationTypeRenamedFields";
    case "currentStage":
      return "currentStageRenamedFields";
    default:
      return name;
  }
}

function nameChange(name) {
  switch (name) {
    case "manuscriptTypes":
      return "manuscriptType";
    case "editorialStatus":
      return "editorialStatus";
    case "sectionCategory":
      return "sectionCategory";
    case "manuscriptVersions":
      return "revisionType";
    case "decisionTypes":
      return "decisionType";
    case "articleFlags":
      return "articleFlags";
    case "stageName":
      return "stageName";
    case "letterStages":
      return "letterStages";
    case "primaryCategory":
      return "primaryCategory";
    case "initialDecisions":
      return "initialDecisions";
    case "publicationType":
      return "publicationType";
    case "currentStage":
      return "currentStage";
    case "countries":
      return "countryType";
    case "dateRange":
      return "dateRangeType";
    case "datesForGraph":
      return "dateType";
    case "initialDecisionTypes":
      return "initialDecisionTypes";
    case "finalDecisionType":
      return "finalDecisionType";
    case "editorRoleType":
      return "editorRoleType";
    case "reviewerRoleType":
      return "reviewerRoleType";
    case "userFlagsType":
      return "userFlagsType";
    case "reviewerInvitationType":
      return "reviewerInvitationType";
    case "reviewerPeopleIDType":
      return "reviewerPeopleIDType";
    case "reviewerIsBoardMemberType":
      return "reviewerIsBoardMemberType";
    default:
      return name;
  }
}

function excludeNameItem(name) {
  switch (name) {
    case "manuscriptTypes":
      return "selectedSecondManTypes";
    case "initialDecisionTypes":
      return "selectedSecondInitialDecisionTypes";
    case "finalDecisionType":
      return "selectedSecondFinalDecisionType";
    case "editorRoleType":
      return "selectedSecondEditorRoleType";
    case "reviewerRoleType":
      return "selectedSecondReviewerRoleType";
    case "userFlagsType":
      return "selectedSecondUserFlagsType";
    case "reviewerInvitationType":
      return "selectedSecondInvitationReviewer";
    case "reviewerPeopleIDType":
      return "selectedSecondReviewerPeopleIDType";
    case "reviewerIsBoardMemberType":
      return "selectedSecondReviewerIsBoardMemberType";
    case "editorialStatus":
      return "selectedSecondEditStatus";
    case "sectionCategory":
      return "selectedSecondSecCategory";
    case "countries":
      return "selectedSecondCountries";
    case "decisionTypes":
      return "selectedDecisionTypes";
    case "articleFlags":
      return "selectedSecondArticleFlags";
    case "stageName":
      return "selectedSecondStageName";
    case "letterStages":
      return "selectedSecondLetterStages";
    case "continents":
      return "continents";
    case "primaryCategory":
      return "selectedSecondPrimaryCategory";
    case "initialDecisions":
      return "selectedSecondInitialDecisions";
    case "publicationType":
      return "selectedSecondPublicationType";
    case "currentStage":
      return "selectedSecondCurrentStage";
    // case "continentsReviewer":
    //     return "continentsReviewer";
    // case "countryReviewer":
    //     return "selectedSecondCountryReviewer";
    default:
      return name;
  }
}

function includeNameItem(name) {
  switch (name) {
    case "manuscriptTypes":
      return "selectedFirstManTypes";
    case "initialDecisionTypes":
      return "selectedFirstInitialDecisionTypes";
    case "finalDecisionType":
      return "selectedFirstFinalDecisionType";
    case "editorRoleType":
      return "selectedFirstEditorRoleType";
    case "reviewerRoleType":
      return "selectedFirstReviewerRoleType";
    case "userFlagsType":
      return "selectedFirstUserFlagsType";
    case "reviewerPeopleIDType":
      return "selectedFirstReviewerPeopleIDType";
    case "reviewerIsBoardMemberType":
      return "selectedFirstReviewerIsBoardMemberType";
    case "reviewerInvitationType":
      return "selectedFirstInvitationReviewer";
    case "editorialStatus":
      return "selectedFirstEditStatus";
    case "sectionCategory":
      return "selectedFirstSecCategory";
    case "countries":
      return "selectedFirstCountries";
    case "manuscriptVersions":
      return "choosenRevisionNumbers";
    case "decisionTypes":
      return "selectedDecisionTypes";
    case "articleFlags":
      return "selectedFirstArticleFlags";
    case "stageName":
      return "selectedFirstStageName";
    case "letterStages":
      return "selectedFirstLetterStages"
    case "continents":
      return "continents";
    case "primaryCategory":
      return "selectedFirstPrimaryCategory"
    case "initialDecisions":
      return "selectedFirstInitialDecisions";
    case "publicationType":
      return "selectedFirstPublicationType"
    case "currentStage":
      return "selectedFirstCurrentStage"
    // case "continentsReviewer":
    //     return "continentsReviewer"
    // case "countryReviewer":
    //     return "selectedFirstCountryReviewer";
    default:
      return name;
  }
}

function handleNewData(checked, changedName, name, state) {
  let editors = [...state.editors.list];
  let reviewers = [...state.reviewers.list];
  let continents = [...state.continents.list];
  //let continentsReviewer = [...state.continentsReviewer.list]

  if (checked === 1) {
    editors = editors.filter((el) => el.include).map((el) => el.text);
    reviewers = reviewers.filter((el) => el.include).map((el) => el.text);
    continents = continents.filter((el) => el.include).map((el) => el.text);
    // continentsReviewer = continentsReviewer.filter(el => el.include).map(el => el.text);
  } else if (checked === 2) {
    editors = editors.filter((el) => !el.exclude).map((el) => el.text);

    const filteredReviewers = reviewers.filter((el) => el.include);
    if (filteredReviewers.length > 0) {
      reviewers = filteredReviewers.map((el) => el.text);
    } else {
      reviewers = reviewers.map((el) => el.text);
    }

    continents = continents.filter((el) => !el.exclude).map((el) => el.text);
    // continentsReviewer = continentsReviewer.filter(el => !el.exclude).map(el => el.text);
  } else {
    editors = editors.map((el) => el.text);
    reviewers = reviewers.map((el) => el.text);
    continents = continents.map((el) => el.text);
    // continentsReviewer = continentsReviewer.map(el => el.text);
  }

  if (name === "continents") {
    return { ...state.data, continents };
  } else if (name === "editors") {
    return { ...state.data, editors };
  } else if (name === "reviewers") {
    return { ...state.data, reviewers };
  }
  return { ...state.data, [changedName]: checked };
}

function fillDataToState(data, state, reportType) {
  const {
    max_date,
    min_date,
    editors,
    articleFlags,
    letterStages,
    primaryCategory,
    initialDecisions,
    publicationType,
    currentStage,
    stageName,
    reviewers,
    manuscriptTypes,
    initialDecisionTypes,
    finalDecisionType,
    editorRoleType,
    reviewerRoleType,
    userFlagsType,
    reviewerPeopleIDType,
    reviewerIsBoardMemberType,
    editorialStatus,
    sectionCategory,
    revisionNumbers,
    decisions,
    countries,
    continents,
    journalName,
    //continentsReviewer,
    isCustomUpload,
  } = data;
  editorRoleType.forEach((e, index) => {
    let chartCode = localStorage.getItem("chartCode");
    if (EditorRoleNoEIC.includes(chartCode)) {
      if (e.text === "Editor in Chief") {
        editorRoleType.splice(index, 1);
      }
    }
  });
  initialDecisionTypes.forEach((e, index) => {
    let chartCode = localStorage.getItem("chartCode");
    if (EditorRoleNoEIC.includes(chartCode)) {
      if (e.text[0] === "[" || e.text.includes("[")) {
        initialDecisionTypes.splice(index, 1);
      }
    }
  });
  const entireStart = moment.utc(min_date).format("MM/DD/YYYY");
  const entireEnd = moment.utc(max_date).format("MM/DD/YYYY");
  const minYear = moment.utc(min_date).year();
  const maxYear = moment.utc(max_date).year();
  let datesForGraphList = [...state.datesForGraph.datesForGraphList];
  let year = maxYear;
  datesForGraphList[0] = {
    id: 3,
    title: "Year",
    name: "Single Year",
    list: [],
  };
  datesForGraphList[0].selectedItem = { key: maxYear, value: 1 };
  datesForGraphList[1] = {
    id: 1,
    title: "Previous quarters",
    name: "Most recent",
    selectedItem: quartersData.list[0],
    list: quartersData.list,
  };
  datesForGraphList[2] = {
    id: 2,
    title: "Previous months",
    name: "Most recent",
    selectedItem: monthsData.list[0],
    list: monthsData.list,
  };
  for (let i = 0; i <= maxYear - minYear; i++) {
    datesForGraphList[0].list.push({ value: i + 1, key: maxYear - i });
  }
  if (!reportType) {
    reportType = state.data.reportType;
  }

  const REV_COMPLETED = "Review completed";
  const REV_DEC_INVIT = "Reviewer declined";
  const REV_UNASSIGN = "Reviewer unassigned";
  const REV_UNINVIT = "Reviewer uninvited";
  const REV_NOT_RESPONDED = "Reviewer did not respond";

  const reviewerInvitationType = [
    { id: 1, text: REV_COMPLETED, count: 0 },
    { id: 2, text: REV_DEC_INVIT, count: 0 },
    { id: 3, text: REV_UNASSIGN, count: 0 },
    { id: 4, text: REV_NOT_RESPONDED, count: 0 },
    { id: 5, text: REV_UNINVIT, count: 0 },
  ];

  return {
    ...state,
    editors: {
      ...state.editors,
      list: fillData(editors, "editors", state.editors, isCustomUpload),
      searchList: fillData(editors, null, state.editors, isCustomUpload),
      locked: isCustomUpload ? false : state.editors.locked,
    },
    articleFlags: {
      ...state.articleFlags,
      list: fillData(
        articleFlags,
        "articleFlags",
        state.articleFlags,
        isCustomUpload
      ),
      searchList: fillData(
        articleFlags,
        null,
        state.articleFlags,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.articleFlags.locked,
    },
    stageName: {
      ...state.stageName,
      list: fillData(
        stageName,
        "stageName",
        state.stageName,
        isCustomUpload
      ),
      searchList: fillData(
        stageName,
        null,
        state.stageName,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.stageName.locked,
    },
    letterStages: {
      ...state.letterStages,
      list: fillData(
        letterStages,
        "letterStages",
        state.letterStages,
        isCustomUpload
      ),
      searchList: fillData(
        letterStages,
        null,
        state.letterStages,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.letterStages.locked,
    },
    primaryCategory: {
      ...state.primaryCategory,
      list: fillData(
        primaryCategory,
        "primaryCategory",
        state.primaryCategory,
        isCustomUpload
      ),
      searchList: fillData(
        primaryCategory,
        null,
        state.primaryCategory,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.primaryCategory.locked,
    },
    initialDecisions: {
      ...state.initialDecisions,
      list: fillData(
        initialDecisions,
        "initialDecisions",
        state.initialDecisions,
        isCustomUpload
      ),
      searchList: fillData(
        initialDecisions,
        null,
        state.initialDecisions,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.initialDecisions.locked,
    },
    publicationType: {
      ...state.publicationType,
      list: fillData(
        publicationType,
        "publicationType",
        state.publicationType,
        isCustomUpload
      ),
      searchList: fillData(
        publicationType,
        null,
        state.publicationType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.publicationType.locked,
    },
    currentStage: {
      ...state.currentStage,
      list: fillData(
        currentStage,
        "currentStage",
        state.currentStage,
        isCustomUpload
      ),
      searchList: fillData(
        currentStage,
        null,
        state.currentStage,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.currentStage.locked,
    },
    reviewers: {
      ...state.reviewers,
      list: fillData(reviewers, "reviewers", state.reviewers, isCustomUpload),
      searchList: fillData(reviewers, null, state.reviewers, isCustomUpload),
      locked: isCustomUpload ? false : state.reviewers.locked,
    },
    manuscriptTypes: {
      ...state.manuscriptTypes,
      list: fillData(
        manuscriptTypes,
        "manuscripts",
        state.manuscriptTypes,
        isCustomUpload
      ),
      searchList: fillData(
        manuscriptTypes,
        null,
        state.manuscriptTypes,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.manuscriptTypes.locked,
    },
    initialDecisionTypes: {
      ...state.initialDecisionTypes,
      list: fillData(
        initialDecisionTypes,
        "initialDecisionTypes",
        state.initialDecisionTypes,
        isCustomUpload
      ),
      searchList: fillData(
        initialDecisionTypes,
        null,
        state.initialDecisionTypes,
        isCustomUpload
      ),
    },
    finalDecisionType: {
      ...state.finalDecisionType,
      list: fillData(
        finalDecisionType,
        "finalDecisionType",
        state.finalDecisionType,
        isCustomUpload
      ),
      searchList: fillData(
        finalDecisionType,
        null,
        state.finalDecisionType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.finalDecisionType.locked,
    },
    editorRoleType: {
      ...state.editorRoleType,
      list: fillData(
        editorRoleType,
        "editorRoleType",
        state.editorRoleType,
        isCustomUpload
      ),
      searchList: fillData(
        editorRoleType,
        null,
        state.editorRoleType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.editorRoleType.locked,
    },
    reviewerRoleType: {
      ...state.reviewerRoleType,
      list: fillData(
        reviewerRoleType,
        "reviewerRoleType",
        state.reviewerRoleType,
        isCustomUpload
      ),
      searchList: fillData(
        reviewerRoleType,
        null,
        state.reviewerRoleType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.reviewerRoleType.locked,
    },
    userFlagsType: {
      ...state.userFlagsType,
      list: fillData(
        userFlagsType,
        "userFlagsType",
        state.userFlagsType,
        isCustomUpload
      ),
      searchList: fillData(
        userFlagsType,
        null,
        state.userFlagsType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.userFlagsType.locked,
    },
    reviewerInvitationType: {
      ...state.reviewerInvitationType,
      list: fillData(
        reviewerInvitationType,
        "reviewerInvitationType",
        state.reviewerInvitationType,
        isCustomUpload
      ),
      searchList: fillData(
        reviewerInvitationType,
        null,
        state.reviewerInvitationType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.reviewerInvitationType.locked,
    },
    reviewerPeopleIDType: {
      ...state.reviewerPeopleIDType,
      list: fillData(
        reviewerPeopleIDType,
        "reviewerPeopleIDType",
        state.reviewerPeopleIDType,
        isCustomUpload
      ),
      searchList: fillData(
        reviewerPeopleIDType,
        null,
        state.reviewerPeopleIDType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.reviewerPeopleIDType.locked,
    },
    reviewerIsBoardMemberType: {
      ...state.reviewerIsBoardMemberType,
      list: fillData(
        reviewerIsBoardMemberType,
        "reviewerIsBoardMemberType",
        state.reviewerIsBoardMemberType,
        isCustomUpload
      ),
      searchList: fillData(
        reviewerIsBoardMemberType,
        null,
        state.reviewerIsBoardMemberType,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.reviewerIsBoardMemberType.locked,
    },
    editorialStatus: {
      ...state.editorialStatus,
      list: fillData(
        editorialStatus,
        "editorialStatus",
        state.editorialStatus,
        isCustomUpload
      ),
      searchList: fillData(
        editorialStatus,
        null,
        state.editorialStatus,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.editorialStatus.locked,
    },
    sectionCategory: {
      ...state.sectionCategory,
      list: fillData(
        sectionCategory,
        "sectionCategory",
        state.sectionCategory,
        isCustomUpload
      ),
      searchList: fillData(
        sectionCategory,
        null,
        state.sectionCategory,
        isCustomUpload
      ),
      locked: isCustomUpload ? false : state.sectionCategory.locked,
    },
    manuscriptVersions: {
      ...state.manuscriptVersions,
      list: fillData(revisionNumbers, "manuscriptVersions"),
      searchList: fillData(revisionNumbers),
    },
    decisionTypes: {
      ...state.decisionTypes,
      list: fillData(decisions, "decisions", state.decisions, isCustomUpload),
      searchList: fillData(decisions, null, state.decisions, isCustomUpload),
    },
    countries: {
      ...state.countries,
      list: fillData(countries, "countries", state.countries, isCustomUpload),
      searchList: fillData(countries, null, state.countries, isCustomUpload),
      locked: isCustomUpload ? false : state.countries.locked,
    },
    // countryReviewer: {
    //     ...state.countryReviewer,
    //     list: fillData(countries, 'countryReviewer', state.countryReviewer, isCustomUpload),
    //     searchList: fillData(countries, null, state.countryReviewer, isCustomUpload),
    //     locked: isCustomUpload ? false : state.countryReviewer.locked,
    // },
    continents: {
      ...state.continents,
      list: fillData(
        continents,
        "continents",
        state.continents,
        isCustomUpload
      ),
      searchList: fillData(continents, null, state.continents, isCustomUpload),
      locked: isCustomUpload ? false : state.continents.locked,
    },
    // continentsReviewer:{
    //     ...state.continentsReviewer,
    //     list: fillData(continents, 'continentsReviewer', state.continentsReviewer, isCustomUpload),
    //     searchList: fillData(continents, null, state.continentsReviewer, isCustomUpload),
    //     locked: isCustomUpload ? false : state.continentsReviewer.locked,
    // },
    dateRange: {
      ...state.dateRange,
      entireStart,
      entireEnd,
      start: entireStart,
      end: entireEnd,
    },
    dataForPrevious: {
      ...state.dataForPrevious,
    },
    datesForGraph: {
      ...state.datesForGraph,
      datesForGraphList,
    },
    date: {
      ...state.date,
      start: entireStart,
      end: entireEnd,
    },
    data: {
      ...state.data,
      reviewers: reviewers && reviewers.map((el) => el.text),
      selectedSecondManTypes:
        manuscriptTypes && manuscriptTypes.map((el) => el.text),
      selectedSecondEditStatus:
        editorialStatus && editorialStatus.map((el) => el.text),
      selectedSecondSecCategory:
        sectionCategory && sectionCategory.map((el) => el.text),
      selectedSecondCountries: countries && countries.map((el) => el.text),
      selectedSecondArticleFlags:
        articleFlags && articleFlags.map((el) => el.text),
      selectedSecondStageName:
        stageName && stageName.map((el) => el.text),
      selectedSecondLetterStages:
        letterStages && letterStages.map((el) => el.text),
      selectedSecondPrimaryCategory:
        primaryCategory && primaryCategory.map((el) => el.text),
      selectedSecondInitialDecisions:
        initialDecisions && initialDecisions.map((el) => el.text),
      selectedSecondPublicationType:
        publicationType && publicationType.map((el) => el.text),
      selectedSecondCurrentStage:
        currentStage && currentStage.map((el) => el.text),
      startDate: entireStart && entireStart,
      endDate: entireEnd && entireEnd,
      reportType,
      journalName: journalName && journalName,
      choosenRevisionNumbers: [0],
      year,
      selectedSecondInitialDecisionTypes:
        initialDecisionTypes && initialDecisionTypes.map((el) => el.text),
      selectedSecondFinalDecisionType:
        finalDecisionType && finalDecisionType.map((el) => el.text),
      selectedSecondEditorRoleType:
        editorRoleType && editorRoleType.map((el) => el.text),
      selectedSecondReviewerRoleType:
        reviewerRoleType && reviewerRoleType.map((el) => el.text),
      selectedSecondUserFlagsType:
        userFlagsType && userFlagsType.map((el) => el.text),
      selectedSecondInvitationReviewer:
        reviewerInvitationType && reviewerInvitationType.map((el) => el.text),
      selectedSecondReviewerPeopleIDType:
        reviewerPeopleIDType && reviewerPeopleIDType.map((el) => el.text),
      selectedSecondReviewerIsBoardMemberType:
        reviewerIsBoardMemberType &&
        reviewerIsBoardMemberType.map((el) => el.text),
    },
  };
}
function getDate(id, date = {}, entireStart, entireEnd) {
  const { value, key } = date;
  let start = "",
    end = entireEnd;
  if (id === 1) {
    start = moment
      .utc(new Date(entireEnd))
      .quarter(moment.utc(new Date(entireEnd)).quarter() - value + 1)
      .startOf("quarter");
    const endOfQuarter = moment.utc(new Date(entireEnd)).endOf("quarter");
    if (!endOfQuarter.isSame(new Date(entireEnd))) {
      start = moment
        .utc(new Date(entireEnd))
        .quarter(moment.utc(new Date(entireEnd)).quarter() - value)
        .startOf("quarter");
      end = moment
        .utc(new Date(entireEnd))
        .quarter(moment.utc(new Date(entireEnd)).quarter() - 1)
        .endOf("quarter");
    }
  } else if (id === 2) {
    start = moment
      .utc(new Date(entireEnd))
      .month(moment.utc(new Date(entireEnd)).month() - value + 1)
      .startOf("month");
    const endOfMonth = moment.utc(new Date(entireEnd)).endOf("month");
    if (!endOfMonth.isSame(new Date(entireEnd))) {
      start = moment
        .utc(new Date(entireEnd))
        .month(moment.utc(new Date(entireEnd)).month() - value)
        .startOf("month");
      end = moment
        .utc(new Date(entireEnd))
        .month(moment.utc(new Date(entireEnd)).month() - 1)
        .endOf("month");
    }
  } else if (id === 3) {
    start = moment.utc(new Date(String(key) + "-01-15")).startOf("year");
    if (value === 1) {
      end = entireEnd;
    } else {
      end = moment.utc(new Date(String(key) + "-01-15")).endOf("year");
    }
  } else {
    start = entireStart;
  }
  return {
    start: moment.utc(new Date(start)).format("MM/DD/YYYY"),
    end: moment.utc(new Date(end)).format("MM/DD/YYYY"),
  };
}

function fixDataSelectionConfigOnEdit(oldList = [], savedList = []) {
  let id = -1;
  const newList = oldList.map((item) => {
    id++;
    const findedItem = savedList.find((newItem) => {
      return newItem.text === item.text;
    });
    if (findedItem) {
      return {
        ...findedItem,
        id,
        count: item.count,
      };
    }

    return {
      ...item,
      id,
    };
  });
  const filterGroups = savedList.filter((item) => item.type === "group");
  return [...newList, ...filterGroups];
}

function updateDataSelectionConfigOnEdit(oldConfig = {}, savedConfig = {}) {
  const newConfig = {
    ...oldConfig,
    list: [],
    searchList: [],
    radioChecked: savedConfig.radioChecked || 0,
    renamedFields: savedConfig.renamedFields || {},
  };
  newConfig.list = fixDataSelectionConfigOnEdit(
    oldConfig.list,
    savedConfig.list
  );
  newConfig.searchList = fixDataSelectionConfigOnEdit(
    oldConfig.searchList,
    savedConfig.searchList
  );
  return newConfig;
}

function fixSelectedItems(list = [], type, renamedFields = {}) {
  if (type === "exclude") {
    return list
      .filter((item) => !item[type])
      .map((item) => {
        return renamedFields[item.text] || item.text;
      });
  }
  return list
    .filter((item) => item[type])
    .map((item) => {
      return renamedFields[item.text] || item.text;
    });
}

function updateDataSelectionListCounts(
  oldValue = [],
  dynamicValues = {},
  type,
  renamedFields = {}
) {
  return oldValue
    ?.map((item) => {
      if (dynamicValues[item.text] || dynamicValues[renamedFields[item.text]]) {
        let count = dynamicValues[item.text]?.count || 0;
        if (renamedFields[item.text]) {
          count = dynamicValues[renamedFields[item.text]]?.count;
        }
        return {
          ...item,
          count: count,
        };
      }
      if (item.type === "group") {
        let countSum = 0;
        oldValue.forEach((oldItem) => {
          if (oldItem.groupName === item.text) {
            if (dynamicValues[oldItem.text]) {
              countSum += dynamicValues[oldItem.text]?.count;
            }
          }
        });
        return {
          ...item,
          count: countSum,
        };
      }
      return {
        ...item,
        count: 0,
      };
    })
    .sort((a, b) => {
      if (b.text === "No Value") return -1;
      if (a.text === "No Value") return 1;
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      if (type === "number") {
        const bNumber = +b.text.replace(",", "");
        const aNumber = +a.text.replace(",", "");
        return aNumber - bNumber;
      }
      return 0;
    })
    .sort((a, b) => {
      const sub = b.count - a.count;
      return sub;
    });
}

export default (state = defaultState, action) => {
  const dateObj = {
    1: {
      startMonth: "01",
      endMonth: "12",
      endDay: "31",
    },
    2: {
      startMonth: "04",
      endMonth: "03",
      endDay: "31",
    },
    3: {
      startMonth: "07",
      endMonth: "06",
      endDay: "30",
    },
    4: {
      startMonth: "10",
      endMonth: "09",
      endDay: "30",
    },
  };
  const checkDate = state.yearStart ? state.yearStart.radioChecked.value : "1";
  switch (action.type) {
    case CHANGE_DEFAULT_CONFIGURATION_STP:
      return {
        ...state,
        data: {
          ...state.data,
          revisionType: 1,
        },
        manuscriptVersions: {
          ...state.manuscriptVersions,
          radioChecked: 1,
          // list:[...state.manuscriptVersions.list.map(el=>{
          //     if(el.text === "No revision Number"){
          //         el.include =true
          //     }
          //     return el
          // })],
          // searchList:[...state.manuscriptVersions.searchList.map(el=>{
          //     if(el.text === "No revision Number"){
          //         el.include =true
          //     }
          //     return el
          // })]
        },
      };
    case actions.GENERATE_FROM_FAVORITE: {
      //fix manuscript types
      const {
        editors: oldEditors,
        articleFlags: oldArticleFlags,
        stageName: oldStageName,
        letterStages: oldLetterStages,
        primaryCategory: oldPrimaryCategory,
        initialDecisions: oldInitialDecisions,
        publicationType: oldPublicationType,
        currentStage: oldCurrentStage,
        reviewers: oldReviewers,
        manuscriptTypes: oldManTypes,
        editorialStatus: oldEditorialStatus,
        sectionCategory: oldSectionCategory,
        decisionTypes: oldDecisionTypes,
        initialDecisionTypes: oldInitialDecisionTypes,
        finalDecisionType: oldFinalDecisionType,
        editorRoleType: oldEditorRoleType,
        reviewerRoleType: oldReviewerRoleType,
        userFlagsType: oldUserFlagsType,
        reviewerInvitationType: oldReviewerInvitationType,
        reviewerPeopleIDType: oldReviewerPeopleIDType,
        reviewerIsBoardMemberType: oldReviewerIsBoardMemberType,
        countries: oldcountries,
        continents: oldContinents,
        // continentsReviewer: oldContinentsReviewer
      } = state;
      const {
        editors: saveEditors,
        articleFlags: saveArticleFlags,
        stageName: saveStageName,
        letterStages: saveLetterStages,
        primaryCategory: savePrimaryCategory,
        initialDecisions: saveInitialDecisions,
        publicationType: savePublicationType,
        currentStage: saveCurrentStage,
        reviewers: saveReviewers,
        manuscriptTypes: savedManTypes,
        editorialStatus: saveEditorialStatus,
        sectionCategory: saveSectionCategory,
        decisionTypes: saveDecisionTypes,
        initialDecisionTypes: saveInitialDecisionTypes,
        finalDecisionType: saveFinalDecisionType,
        editorRoleType: saveEditorRoleType,
        reviewerRoleType: saveReviewerRoleType,
        userFlagsType: saveUserFlagsType,
        reviewerInvitationType: saveReviewerInvitationType,
        reviewerPeopleIDType: saveReviewerPeopleIDType,
        reviewerIsBoardMemberType: saveReviewerIsBoardMemberType,
        countries: savedcountries,
        continents: saveContinents,
        mostRecentYears,
        datesForGraph,
        dateRangeX,
        mostRecentMonth,
        mostRecentQuarters,
        startYearData,
        dataForPrevious,
        mostRecentYearsSelect,
      } = action.payload.item.dataSelection;

      const wantedQuarterList =
        state.mostRecentQuarters.mostRecentQuartersSelect.list;
      const wantedMonthList = state.mostRecentMonth.mostRecentMonthSelect.list;
      const wantedYearList = state.mostRecentYears.mostRecentYearsSelect.list;
      const customStart = state.dateRange.start;
      const customEnd = state.dateRange.end;
      let newMostRecentYears = mostRecentYears
        ? { ...mostRecentYears }
        : { ...state.mostRecentYears };
      let newShowTimeAs = { ...state.showTimeAs };
      let newDateRangeX = dateRangeX
        ? { ...dateRangeX }
        : { ...state.dateRangeX };
      let newMostRecentMonth = mostRecentMonth
        ? { ...mostRecentMonth }
        : {
          ...state.mostRecentMonth,
          mostRecentYearsSelect: {
            ...state.mostRecentMonth.mostRecentYearsSelect,
            option: mostRecentYearsSelect,
          },
        };

      let newMostRecentStartYear = startYearData
        ? { ...startYearData }
        : { ...state.startYearData };

      let newMostRecentQuarters = mostRecentQuarters
        ? { ...mostRecentQuarters }
        : { ...state.mostRecentQuarters };
      let newDataForPrevious = dataForPrevious
        ? { ...dataForPrevious }
        : { ...state.dataForPrevious };
      if (datesForGraph.radioChecked === 3) {
        newShowTimeAs.radioChecked = 0;
        newDateRangeX.radioChecked = 2;
        let value = datesForGraph.datesForGraphList[0].selectedItem;
        newDataForPrevious.specificYear.selectedItem = value;
        newDataForPrevious.radioChecked = 2;
      }
      if (datesForGraph.radioChecked === 2) {
        newShowTimeAs.radioChecked = 2;
        newDateRangeX.radioChecked = 5;
        let value = datesForGraph.datesForGraphList[2].selectedItem.value;
        newMostRecentMonth.mostRecentMonthSelect.option = { value, key: value };
      }
      if (datesForGraph.radioChecked === 1) {
        newShowTimeAs.radioChecked = 1;
        newDateRangeX.radioChecked = 4;
        let value = datesForGraph.datesForGraphList[1].selectedItem.value;
        newMostRecentQuarters.mostRecentQuartersSelect.option = {
          value,
          key: value,
        };
      }
      const manuscriptTypes = updateDataSelectionConfigOnEdit(
        oldManTypes,
        savedManTypes
      ),
        countries = updateDataSelectionConfigOnEdit(
          oldcountries,
          savedcountries
        ),
        editors = updateDataSelectionConfigOnEdit(oldEditors, saveEditors),
        reviewers = updateDataSelectionConfigOnEdit(
          oldReviewers,
          saveReviewers
        ),
        editorialStatus = updateDataSelectionConfigOnEdit(
          oldEditorialStatus,
          saveEditorialStatus
        ),
        sectionCategory = updateDataSelectionConfigOnEdit(
          oldSectionCategory,
          saveSectionCategory
        ),
        articleFlags = updateDataSelectionConfigOnEdit(
          oldArticleFlags,
          saveArticleFlags
        ),
        stageName = updateDataSelectionConfigOnEdit(
          oldStageName,
          saveStageName
        ),
        letterStages = updateDataSelectionConfigOnEdit(
          oldLetterStages,
          saveLetterStages
        ),
        primaryCategory = updateDataSelectionConfigOnEdit(
          oldPrimaryCategory,
          savePrimaryCategory
        ),
        initialDecisions = updateDataSelectionConfigOnEdit(
          oldInitialDecisions,
          saveInitialDecisions
        ),
        publicationType = updateDataSelectionConfigOnEdit(
          oldPublicationType,
          savePublicationType
        ),
        currentStage = updateDataSelectionConfigOnEdit(
          oldCurrentStage,
          saveCurrentStage
        ),
        decisionTypes = updateDataSelectionConfigOnEdit(
          oldDecisionTypes,
          saveDecisionTypes
        ),
        continents = updateDataSelectionConfigOnEdit(
          oldContinents,
          saveContinents
        ),
        // continentsReviewer=updateDataSelectionConfigOnEdit(oldContinentsReviewer, saveContinentsReviewer),
        initialDecisionTypes = updateDataSelectionConfigOnEdit(
          oldInitialDecisionTypes,
          saveInitialDecisionTypes
        ),
        finalDecisionType = updateDataSelectionConfigOnEdit(
          oldFinalDecisionType,
          saveFinalDecisionType
        ),
        editorRoleType = updateDataSelectionConfigOnEdit(
          oldEditorRoleType,
          saveEditorRoleType
        ),
        reviewerRoleType = updateDataSelectionConfigOnEdit(
          oldReviewerRoleType,
          saveReviewerRoleType
        ),
        userFlagsType = updateDataSelectionConfigOnEdit(
          oldUserFlagsType,
          saveUserFlagsType
        ),
        reviewerInvitationType = updateDataSelectionConfigOnEdit(
          oldReviewerInvitationType,
          saveReviewerInvitationType
        ),
        reviewerPeopleIDType = updateDataSelectionConfigOnEdit(
          oldReviewerPeopleIDType,
          saveReviewerPeopleIDType
        ),
        reviewerIsBoardMemberType = updateDataSelectionConfigOnEdit(
          oldReviewerIsBoardMemberType,
          saveReviewerIsBoardMemberType
        );
      const allEditors = editors.list.map((item) => item.text);
      const selectedEditors = fixSelectedItems(
        editors.list,
        "include",
        editors.renamedFields
      );
      const excludedEditors = fixSelectedItems(
        editors.list,
        "exclude",
        editors.renamedFields
      );
      const dataEditors =
        editors.radioChecked === 0
          ? allEditors
          : editors.radioChecked === 1
            ? selectedEditors
            : excludedEditors;

      const allReviewers = reviewers.list.map((item) => item.text);
      const selectedReviewers = fixSelectedItems(
        reviewers.list,
        "include",
        reviewers.renamedFields
      );
      const excludedReviewers = fixSelectedItems(
        reviewers.list,
        "exclude",
        reviewers.renamedFields
      );
      const dataReviewers =
        reviewers.radioChecked === 0
          ? allReviewers
          : reviewers.radioChecked === 1
            ? selectedReviewers
            : excludedReviewers;
      return {
        ...state,
        ...action.payload.item.dataSelection,
        manuscriptTypes,
        countries,
        editors,
        reviewers,
        editorialStatus,
        sectionCategory,
        articleFlags,
        stageName,
        letterStages,
        primaryCategory,
        initialDecisions,
        publicationType,
        currentStage,
        decisionTypes,
        continents,
        //  continentsReviewer,
        initialDecisionTypes,
        finalDecisionType,
        editorRoleType,
        reviewerRoleType,
        userFlagsType,
        reviewerInvitationType,
        reviewerPeopleIDType,
        reviewerIsBoardMemberType,

        // date: {
        //     ...state.date
        // },
        datesForGraph: {
          ...action.payload.item.dataSelection.datesForGraph,
          datesForGraphList: [...state.datesForGraph.datesForGraphList],
        },
        dateRange: {
          ...action.payload.item.dataSelection.dateRange,
          // start: moment.utc(state.dateRange.entireStart,).format("MM/DD/YYYY"),
          // end: moment.utc(state.dateRange.entireEnd,).format("MM/DD/YYYY"),
          entireStart: state.dateRange.entireStart,
          entireEnd: state.dateRange.entireEnd,
          start: customStart,
          end: customEnd,
        },
        dateRangeX: {
          ...newDateRangeX,
        },
        showTimeAs: {
          ...newShowTimeAs,
        },
        dataForPrevious: {
          ...newDataForPrevious,
        },
        mostRecentMonth: {
          ...newMostRecentMonth,
          mostRecentMonthSelect: {
            ...state.mostRecentMonth.mostRecentMonthSelect,
            list: wantedMonthList,
          },
        },
        mostRecentQuarters: {
          ...newMostRecentQuarters,
          mostRecentQuartersSelect: {
            ...state.mostRecentQuarters.mostRecentQuartersSelect,
            list: wantedQuarterList,
          },
        },
        mostRecentYears: {
          ...newMostRecentYears,
          mostRecentYearsSelect: {
            ...state.mostRecentYears.mostRecentYearsSelect,
            list: wantedYearList,
          },
        },
        data: {
          ...state.data,
          ...action.payload.item.dataSelection.data,
          manuscriptSystem: state.data.manuscriptSystem,
          selectedFirstManTypes: fixSelectedItems(
            manuscriptTypes.list,
            "include",
            manuscriptTypes.renamedFields
          ),
          selectedSecondManTypes: fixSelectedItems(
            manuscriptTypes.list,
            "exclude",
            manuscriptTypes.renamedFields
          ),
          mostRecentYearsSelect: {
            ...mostRecentYearsSelect,
          },
          selectedSecondCountries: fixSelectedItems(
            countries.list,
            "exclude",
            countries.renamedFields
          ),
          selectedFirstCountries: fixSelectedItems(
            countries.list,
            "include",
            countries.renamedFields
          ),
          selectedSecondArticleFlags: fixSelectedItems(
            articleFlags.list,
            "exclude",
            articleFlags.renamedFields
          ),
          selectedFirstArticleFlags: fixSelectedItems(
            articleFlags.list,
            "include",
            articleFlags.renamedFields
          ),
          selectedSecondStageName: fixSelectedItems(
            stageName.list,
            "exclude",
            stageName.renamedFields
          ),
          selectedFirstStageName: fixSelectedItems(
            stageName.list,
            "include",
            stageName.renamedFields
          ),
          selectedSecondLetterStages: fixSelectedItems(
            letterStages.list,
            "exclude",
            letterStages.renamedFields
          ),
          selectedFirstLetterStages: fixSelectedItems(
            letterStages.list,
            "include",
            letterStages.renamedFields
          ),
          selectedSecondPrimaryCategory: fixSelectedItems(
            primaryCategory.list,
            "exclude",
            primaryCategory.renamedFields
          ),
          selectedFirstPrimaryCategory: fixSelectedItems(
            primaryCategory.list,
            "include",
            primaryCategory.renamedFields
          ),
          selectedSecondInitialDecisions: fixSelectedItems(
            initialDecisions.list,
            "exclude",
            initialDecisions.renamedFields
          ),
          selectedFirstInitialDecisions: fixSelectedItems(
            initialDecisions.list,
            "include",
            initialDecisions.renamedFields
          ),
          selectedSecondPublicationType: fixSelectedItems(
            publicationType.list,
            "exclude",
            publicationType.renamedFields
          ),
          selectedFirstPublicationType: fixSelectedItems(
            publicationType.list,
            "include",
            publicationType.renamedFields
          ),
          selectedSecondCurrentStage: fixSelectedItems(
            currentStage.list,
            "exclude",
            currentStage.renamedFields
          ),
          selectedFirstCurrentStage: fixSelectedItems(
            currentStage.list,
            "include",
            currentStage.renamedFields
          ),
          selectedSecondCountryReviewer: fixSelectedItems(
            countries.list,
            "include"
          ),
          selectedFirstCountryReviewer: fixSelectedItems(
            countries.list,
            "exclude"
          ),
          editors: dataEditors,
          reviewers: dataReviewers,
          selectedFirstEditStatus: fixSelectedItems(
            editorialStatus.list,
            "include",
            editorialStatus.renamedFields
          ),
          selectedSecondEditStatus: fixSelectedItems(
            editorialStatus.list,
            "exclude",
            editorialStatus.renamedFields
          ),

          selectedFirstSecCategory: fixSelectedItems(
            sectionCategory.list,
            "include",
            sectionCategory.renamedFields
          ),
          selectedSecondSecCategory: fixSelectedItems(
            sectionCategory.list,
            "exclude",
            sectionCategory.renamedFields
          ),

          selectedDecisionTypes: fixSelectedItems(
            decisionTypes.list,
            "include",
            decisionTypes.renamedFields
          ),
          selectedArticleFlags: fixSelectedItems(
            articleFlags.list,
            "include",
            articleFlags.renamedFields
          ),
          selectedStageName: fixSelectedItems(
            stageName.list,
            "include",
            stageName.renamedFields
          ),
          selectedLetterStages: fixSelectedItems(
            letterStages.list,
            "include",
            letterStages.renamedFields
          ),
          selectedPrimaryCategory: fixSelectedItems(
            primaryCategory.list,
            "include",
            primaryCategory.renamedFields
          ),
          selectedInitialDecisions: fixSelectedItems(
            initialDecisions.list,
            "include",
            initialDecisions.renamedFields
          ),
          selectedPublicationType: fixSelectedItems(
            publicationType.list,
            "include",
            publicationType.renamedFields
          ),
          selectedCurrentStage: fixSelectedItems(
            currentStage.list,
            "include",
            currentStage.renamedFields
          ),
          selectedSecondInitialDecisionTypes: fixSelectedItems(
            initialDecisionTypes.list,
            "exclude",
            initialDecisionTypes.renamedFields
          ),
          selectedFirstInitialDecisionTypes: fixSelectedItems(
            initialDecisionTypes.list,
            "include",
            initialDecisionTypes.renamedFields
          ),

          selectedSecondFinalDecisionType: fixSelectedItems(
            finalDecisionType.list,
            "exclude",
            finalDecisionType.renamedFields
          ),
          selectedFirstFinalDecisionType: fixSelectedItems(
            finalDecisionType.list,
            "include",
            finalDecisionType.renamedFields
          ),

          selectedSecondEditorRoleType: fixSelectedItems(
            editorRoleType.list,
            "exclude",
            editorRoleType.renamedFields
          ),
          selectedFirstEditorRoleType: fixSelectedItems(
            editorRoleType.list,
            "include",
            editorRoleType.renamedFields
          ),

          selectedSecondReviewerRoleType: fixSelectedItems(
            reviewerRoleType.list,
            "exclude",
            reviewerRoleType.renamedFields
          ),
          selectedFirstReviewerRoleType: fixSelectedItems(
            reviewerRoleType.list,
            "include",
            reviewerRoleType.renamedFields
          ),
          selectedSecondUserFlagsType: fixSelectedItems(
            userFlagsType.list,
            "exclude",
            userFlagsType.renamedFields
          ),
          selectedFirstUserFlagsType: fixSelectedItems(
            userFlagsType.list,
            "include",
            userFlagsType.renamedFields
          ),
          selectedSecondReviewerInvitationType: fixSelectedItems(
            reviewerInvitationType.list,
            "exclude",
            reviewerInvitationType.renamedFields
          ),
          selectedFirstReviewerInvitationType: fixSelectedItems(
            reviewerInvitationType.list,
            "include",
            reviewerInvitationType.renamedFields
          ),

          selectedSecondReviewerPeopleIDType: fixSelectedItems(
            reviewerPeopleIDType.list,
            "exclude",
            reviewerPeopleIDType.renamedFields
          ),
          selectedFirstReviewerPeopleIDType: fixSelectedItems(
            reviewerPeopleIDType.list,
            "include",
            reviewerPeopleIDType.renamedFields
          ),

          selectedSecondReviewerIsBoardMemberType: fixSelectedItems(
            reviewerIsBoardMemberType.list,
            "exclude"
          ),
          selectedFirstReviewerIsBoardMemberType: fixSelectedItems(
            reviewerIsBoardMemberType.list,
            "include"
          ),
        },
      };
    }
    case actions.HANDLE_INCLUDE_MANUSCRIPT_REVIEWS: {
      return {
        ...state,
        data: {
          ...state.data,
          includeManuscriptsWithNoReviews: action.value,
        },
      };
    }
    case actions.HANDLE_CHANGE_TABLE_SORTING: {
      return {
        ...state,
        tableSorting: {
          ...action.payload,
        },
      };
    }
    case actions.UPLOAD_CSV:
      const addYearsInitial = (start, end, check) => {
        const wantedStart = new Date(start);
        const wantedEndDate = new Date(end);
        const newYear = {
          newStartYear: +wantedStart.getFullYear(),
          newEndYear: +wantedEndDate.getFullYear(),
        };
        if (+check !== 1) {
          if (+wantedStart.getMonth() + 1 > +dateObj[check].startMonth) {
            newYear.newStartYear = +wantedStart.getFullYear() + 1;
          }
          if (+wantedEndDate.getMonth() + 1 > +dateObj[check].endMonth) {
            newYear.newEndYear = +wantedEndDate.getFullYear() + 1;
          }
        }
        const quantity = newYear.newEndYear - newYear.newStartYear;
        const resultFY = [];
        const resultAllDates = [];
        for (let i = quantity; i >= 0; i--) {
          resultFY.push({
            value: quantity - i + 1,
            key: newYear.newStartYear + i,
          });
          resultAllDates.push({
            value: `${quantity - i + 1}`,
            key: `${quantity - i + 1}`,
          });
        }

        return { resultFY, resultAllDates };
      };
      defaultData = action.payload;
      let stateData = fillDataToState(action.payload, state);
      let wantedDataInitial = {};
      stateData.date.defaultStart = stateData?.date?.start;
      stateData.date.defaultEnd = stateData?.date?.end;

      const startDate = new Date(stateData.date.defaultStart);
      const endDate = new Date(stateData.date.defaultEnd);
      const monthsBetween =
        (endDate?.getFullYear() - startDate?.getFullYear()) * 12 +
        (endDate?.getMonth() - startDate?.getMonth());
      let list = [];
      let monthList = [];

      for (let i = 0; i < Math.ceil(monthsBetween / 4); i++) {
        list.push({ value: `${i + 1}`, key: `${i + 1}` });
      }
      for (let i = 0; i < monthsBetween; i++) {
        monthList.push({ value: `${i + 1}`, key: `${i + 1}` });
      }

      const wantedAddYearsInitial = addYearsInitial(
        startDate,
        endDate,
        checkDate
      );
      let wantedListInitial = state.datesForGraph.datesForGraphList;
      wantedListInitial[0].list = wantedAddYearsInitial.resultFY;
      let wantedDatesListInitial = wantedAddYearsInitial.resultAllDates;

      if (
        state.datesForGraph.datesForGraphList[0].selectedItem?.key >
        wantedListInitial[0].list[0]?.key
      ) {
        wantedListInitial[0].selectedItem.key =
          wantedListInitial[0].list[0]?.key;
      }
      if (
        state.datesForGraph.datesForGraphList[0].selectedItem?.key <
        wantedListInitial[0].list[wantedListInitial[0].list?.length - 1]?.key
      ) {
        wantedListInitial[0].selectedItem.key =
          wantedListInitial[0].list[wantedListInitial[0].list?.length - 1]?.key;
      }
      if (
        state.mostRecentYears.mostRecentYearsSelect.option?.key >
        wantedDatesListInitial[wantedDatesListInitial?.length - 1]?.key
      ) {
        newChangeDropdownSecond =
          wantedDatesListInitial[wantedDatesListInitial?.length - 1];
        wantedDataInitial.mostRecentYearsSelect =
          wantedDatesListInitial[wantedDatesListInitial?.length - 1];
      }

      stateData = {
        ...stateData,
        mostRecentQuarters: {
          ...stateData.mostRecentQuarters,
          mostRecentQuartersSelect: {
            ...stateData.mostRecentYears.mostRecentQuartersSelect,
            list,
          },
        },
        mostRecentMonth: {
          ...stateData.mostRecentMonth,
          mostRecentMonthSelect: {
            ...stateData.mostRecentMonth.mostRecentMonthSelect,
            list: monthList,
          },
        },
        mostRecentYears: {
          ...stateData.mostRecentYears,
          mostRecentYearsSelect: {
            ...stateData.mostRecentYears.mostRecentYearsSelect,
            list: wantedDatesListInitial,
          },
        },
        datesForGraph: {
          ...state.datesForGraph,
          datesForGraphList: wantedListInitial,
        },
      };
      return stateData;
    case actions.CHOOSE_CHART_SYSTEM:
      let reportType = state.data.reportType;
      let manuscriptSystem = state.data.manuscriptSystem;
      if (action.n === 1) {
        manuscriptSystem = action.item;
      } else if (action.n === 2) {
        reportType = action.item.value;
      }
      return {
        ...state,
        data: {
          ...state.data,
          reportType,
          manuscriptSystem,
        },
      };
    case actions.HANDLE_CHOOSE_COUNTRY:
      const updatedCountriesList = state.countries.list.map((el) => {
        if (el.id === action.country.id) {
          el.include = true;
        } else {
          el.include = false;
        }
        return el;
      });
      return {
        ...state,
        countries: {
          ...state.countries,
          radioChecked: 1,
          list: updatedCountriesList,
        },
        data: {
          ...state.data,
          countryType: 1,
          selectedFirstCountries: [action.country.text],
        },
      };
    case actions.SELECT_CHART:
      return {
        ...state,
        data: {
          ...state.data,
          chartType: action.item,
          fileName: action.fileName,
        },
      };
    case actions.SET_DATE_MANUALLY:
      return {
        ...state,
        data: {
          ...state.data,
          dateType: action.value,
        },
      };
    case actions.GENERATE_CHART:

      const wantedReportType = localStorage.getItem("wantedReportType")
      let neededReportType = action.reportType
      if (wantedReportType) {
        neededReportType = +JSON.parse(wantedReportType).value
      }
      const configData = chartsSidebarConfig.find(
        (item) =>
          +item.reportType === +neededReportType &&
          item.chartType === action.chartType
      );

      const {
        dataSelectionDynamic: {
          countries: dynamicCountries,
          // countryReviewer: dynamicCountryReviewer,
          manuscriptTypes: dynamicManTypes,
          sectionCategory: dynamicSecCat,
          editorialStatus: dynamicEditorialStatus,
          decisions: dynamicDecisions,
          articleFlags: dynamicArticleFlags,
          stageName: dynamicStageName,
          letterStages: dynamicLetterStages,
          primaryCategory: dynamicPrimaryCategory,
          initialDecisions: dynamicInitialDecisions,
          publicationType: dynamicPublicationType,
          currentStage: dynamicCurrentStage,
          editors: dynamicEditors,
          initialDecisionTypes: dynamicInitialDecisionTypes,
          finalDecisionType: dynamicFinalDecisionType,
          editorRoleType: dynamicEditorRoleType,
          reviewerRoleType: dynamicReviewerRoleType,
          userFlagsType: dynamicUserFlagsType,
          reviewerInvitationType: dynamicReviewerInvitationType,
          reviewerPeopleIDType: dynamicReviewerPeopleIDType,
          reviewerIsBoardMemberType: dynamicReviewerIsBoardMemberType,
          mostRecentQuartersSelect,
          mostRecentMonthSelect,
          mostRecentYearsSelect,
          startYearDataSelect,
        },
      } = action.payload;
      const {
        countries: { searchList: countriesSL, list: countriesL },
        // countryReviewer:{
        //     searchList: countriesRVSL,
        //     list: countriesRVL
        // },
        manuscriptTypes: {
          searchList: manuscriptTypesSl,
          list: manuscriptTypesL,
          renamedFields = {},
        },
        sectionCategory: { searchList: secCatSL, list: secCatL },
        editorialStatus,
        decisionTypes,
        articleFlags,
        stageName,
        letterStages,
        primaryCategory,
        initialDecisions,
        publicationType,
        currentStage,
        editors: stateEditors,
        initialDecisionTypes,
        finalDecisionType,
        editorRoleType,
        reviewerRoleType,
        userFlagsType,
        reviewerInvitationType,
        reviewerPeopleIDType,
        reviewerIsBoardMemberType,
      } = state;

      return {
        ...state,
        config: configData?.["data-selection-config"],
        countries: {
          ...state.countries,
          searchList: updateDataSelectionListCounts(
            countriesSL,
            dynamicCountries
          ),
          list: updateDataSelectionListCounts(countriesL, dynamicCountries),
        },
        // countryReviewer:{
        //     ...state.countryReviewer,
        //     searchList: updateDataSelectionListCounts(countriesRVSL, dynamicCountryReviewer),
        //     list: updateDataSelectionListCounts(countriesRVL, dynamicCountryReviewer)
        // },
        manuscriptTypes: {
          ...state.manuscriptTypes,
          searchList: updateDataSelectionListCounts(
            manuscriptTypesSl,
            dynamicManTypes,
            "manuscriptTypes",
            renamedFields
          ),
          list: updateDataSelectionListCounts(
            manuscriptTypesL,
            dynamicManTypes,
            "manuscriptTypes",
            renamedFields
          ),
        },
        mostRecentQuartersSelect: {
          ...mostRecentQuartersSelect,
        },
        mostRecentMonthSelect: {
          ...mostRecentMonthSelect,
        },
        startYearDataSelect: {
          ...startYearDataSelect,
        },
        mostRecentYearsSelect: {
          ...mostRecentYearsSelect,
        },
        mostRecentMonth: {
          ...state.mostRecentMonth,
          mostRecentMonth: {
            ...state.mostRecentMonth.mostRecentMonthSelect,
            option: mostRecentMonthSelect,
          },
        },
        mostRecentYears: {
          ...state.mostRecentYears,
          mostRecentYearsSelect: {
            ...state.mostRecentYears.mostRecentYearsSelect,
            option: mostRecentYearsSelect,
          },
        },
        mostRecentQuarters: {
          ...state.mostRecentQuarters,
          mostRecentQuartersSelect: {
            ...state.mostRecentQuarters.mostRecentQuartersSelect,
            option: mostRecentQuartersSelect,
          },
        },
        startYearData: {
          ...state.startYearData,
          startYearDataSelect: {
            ...state.startYearData.startYearDataSelect,
            // option: startYearDataSelect
          },
        },
        sectionCategory: {
          ...state.sectionCategory,
          searchList: updateDataSelectionListCounts(
            secCatSL,
            dynamicSecCat,
            "sectionCategory",
            state.sectionCategory.renamedFields
          ),
          list: updateDataSelectionListCounts(
            secCatL,
            dynamicSecCat,
            "sectionCategory",
            state.sectionCategory.renamedFields
          ),
        },
        editorialStatus: {
          ...editorialStatus,
          searchList: updateDataSelectionListCounts(
            editorialStatus.searchList,
            dynamicEditorialStatus,
            "editorialStatus",
            editorialStatus.renamedFields
          ),
          list: updateDataSelectionListCounts(
            editorialStatus.list,
            dynamicEditorialStatus,
            "editorialStatus",
            editorialStatus.renamedFields
          ),
        },
        decisionTypes: {
          ...decisionTypes,
          searchList: updateDataSelectionListCounts(
            decisionTypes.searchList,
            dynamicDecisions,
            "decisionTypes",
            decisionTypes.renamedFields
          ),
          list: updateDataSelectionListCounts(
            decisionTypes.list,
            dynamicDecisions,
            "decisionTypes",
            decisionTypes.renamedFields
          ),
        },
        articleFlags: {
          ...articleFlags,
          searchList: updateDataSelectionListCounts(
            articleFlags.searchList,
            dynamicArticleFlags,
            "articleFlags",
            articleFlags.renamedFields
          ),
          list: updateDataSelectionListCounts(
            articleFlags.list,
            dynamicArticleFlags,
            "articleFlags",
            articleFlags.renamedFields
          ),
        },
        stageName: {
          ...stageName,
          searchList: updateDataSelectionListCounts(
            stageName.searchList,
            dynamicStageName,
            "stageName",
            stageName.renamedFields
          ),
          list: updateDataSelectionListCounts(
            stageName.list,
            dynamicStageName,
            "stageName",
            stageName.renamedFields
          ),
        },
        letterStages: {
          ...letterStages,
          searchList: updateDataSelectionListCounts(
            letterStages.searchList,
            dynamicLetterStages,
            "letterStages",
            letterStages.renamedFields
          ),
          list: updateDataSelectionListCounts(
            letterStages.list,
            dynamicLetterStages,
            "letterStages",
            letterStages.renamedFields
          ),
        },
        primaryCategory: {
          ...primaryCategory,
          searchList: updateDataSelectionListCounts(
            primaryCategory.searchList,
            dynamicPrimaryCategory,
            "primaryCategory",
            primaryCategory.renamedFields
          ),
          list: updateDataSelectionListCounts(
            primaryCategory.list,
            dynamicPrimaryCategory,
            "primaryCategory",
            primaryCategory.renamedFields
          ),
        },
        initialDecisions: {
          ...initialDecisions,
          searchList: updateDataSelectionListCounts(
            initialDecisions.searchList,
            dynamicInitialDecisions,
            "initialDecisions",
            initialDecisions.renamedFields
          ),
          list: updateDataSelectionListCounts(
            initialDecisions.list,
            dynamicInitialDecisions,
            "initialDecisions",
            initialDecisions.renamedFields
          ),
        },
        publicationType: {
          ...publicationType,
          searchList: updateDataSelectionListCounts(
            publicationType.searchList,
            dynamicPublicationType,
            "publicationType",
            publicationType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            publicationType.list,
            dynamicPublicationType,
            "publicationType",
            publicationType.renamedFields
          ),
        },
        currentStage: {
          ...currentStage,
          searchList: updateDataSelectionListCounts(
            currentStage.searchList,
            dynamicCurrentStage,
            "currentStage",
            currentStage.renamedFields
          ),
          list: updateDataSelectionListCounts(
            currentStage.list,
            dynamicCurrentStage,
            "currentStage",
            currentStage.renamedFields
          ),
        },
        editors: {
          ...stateEditors,
          searchList: updateDataSelectionListCounts(
            stateEditors.searchList,
            dynamicEditors,
            "editors",
            stateEditors.renamedFields
          ),
          list: updateDataSelectionListCounts(
            stateEditors.list,
            dynamicEditors,
            "editors",
            stateEditors.renamedFields
          ),
        },
        initialDecisionTypes: {
          ...initialDecisionTypes,
          searchList: updateDataSelectionListCounts(
            initialDecisionTypes.searchList,
            dynamicInitialDecisionTypes,
            "initialDecisionTypes",
            initialDecisionTypes.renamedFields
          ),
          list: updateDataSelectionListCounts(
            initialDecisionTypes.list,
            dynamicInitialDecisionTypes,
            "initialDecisionTypes",
            initialDecisionTypes.renamedFields
          ),
        },
        finalDecisionType: {
          ...finalDecisionType,
          searchList: updateDataSelectionListCounts(
            finalDecisionType.searchList,
            dynamicFinalDecisionType,
            "finalDecisionType",
            finalDecisionType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            finalDecisionType.list,
            dynamicFinalDecisionType,
            "finalDecisionType",
            finalDecisionType.renamedFields
          ),
        },
        editorRoleType: {
          ...editorRoleType,
          searchList: updateDataSelectionListCounts(
            editorRoleType.searchList,
            dynamicEditorRoleType,
            "editorRoleType",
            editorRoleType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            editorRoleType.list,
            dynamicEditorRoleType,
            "editorRoleType",
            editorRoleType.renamedFields
          ),
        },
        reviewerRoleType: {
          ...reviewerRoleType,
          searchList: updateDataSelectionListCounts(
            reviewerRoleType.searchList,
            dynamicReviewerRoleType,
            "reviewerRoleType",
            reviewerRoleType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            reviewerRoleType.list,
            dynamicReviewerRoleType,
            "reviewerRoleType",
            reviewerRoleType.renamedFields
          ),
        },
        userFlagsType: {
          ...userFlagsType,
          searchList: updateDataSelectionListCounts(
            userFlagsType.searchList,
            dynamicUserFlagsType,
            "userFlagsType",
            userFlagsType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            userFlagsType.list,
            dynamicUserFlagsType,
            "userFlagsType",
            userFlagsType.renamedFields
          ),
        },
        reviewerInvitationType: {
          ...reviewerInvitationType,
          searchList: updateDataSelectionListCounts(
            reviewerInvitationType.searchList,
            dynamicReviewerInvitationType,
            "reviewerInvitationType",
            reviewerInvitationType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            reviewerInvitationType.list,
            dynamicReviewerInvitationType,
            "reviewerInvitationType",
            reviewerInvitationType.renamedFields
          ),
        },
        reviewerPeopleIDType: {
          ...reviewerPeopleIDType,
          searchList: updateDataSelectionListCounts(
            reviewerPeopleIDType.searchList,
            dynamicReviewerPeopleIDType,
            "number",
            reviewerPeopleIDType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            reviewerPeopleIDType.list,
            dynamicReviewerPeopleIDType,
            "number",
            reviewerPeopleIDType.renamedFields
          ),
        },
        reviewerIsBoardMemberType: {
          ...reviewerIsBoardMemberType,
          searchList: updateDataSelectionListCounts(
            reviewerIsBoardMemberType.searchList,
            dynamicReviewerIsBoardMemberType,
            "reviewerIsBoardMemberType",
            reviewerIsBoardMemberType.renamedFields
          ),
          list: updateDataSelectionListCounts(
            reviewerIsBoardMemberType.list,
            dynamicReviewerIsBoardMemberType,
            "reviewerIsBoardMemberType",
            reviewerIsBoardMemberType.renamedFields
          ),
        },
      };
    case action.CHANGE_CUSTOM_DATE_RANGE:
      return {
        ...state,
        customYearRange: {
          ...state.customYearRange,
          customDate: action.checked,
        },
      };
    case actions.SIDE_BAR_RADIO_CHANGE:
      // Date het popoxutyunner aneluc nuyn popoxutyunnerty texapoxeq dateRagnegeController vor pdf generacneluc date chisht cuyc ta
      let currentDefaultDate = state.date;
      let wantedList;
      let wantedDatesList;
      const yearCount = state.data.mostRecentYearsSelect.value;

      const quarterCalc = (ckeck, quarterQount, year) => {
        let endDate = moment
          .utc(
            state.dateRange.radioChecked === 0
              ? state.dateRange.entireEnd
              : state.dateRange.end
          )
          .toDate();
        let startDate = moment.utc(
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireStart
            : state.dateRange.start
        );
        let quarter = listQuarters(new Date(startDate), new Date(endDate));
        const quarterX = quarterQount;

        if (ckeck === 1) {
          let month = new Date(endDate).getMonth();
          let wantedEndDate = endDate;
          if (month % 3 !== 0) {
            wantedEndDate = moment
              .utc(endDate)
              .subtract(Math.round(month % 3) + 1, "month")
              .endOf("month")
              .toDate();
          } else {
            wantedEndDate = moment
              .utc(endDate)
              .subtract(1, "month")
              .endOf("month")
              .toDate();
          }
          if (quarter.length < quarterQount) {
            quarterQount = quarter.length - 1;
          }
          let wantedStartDate = moment
            .utc(wantedEndDate)
            .subtract((quarterX - 1) * 3 - 1, "month")
            .startOf("month")
            .toDate();

          if (
            new Date(wantedStartDate).getTime() < new Date(startDate).getTime()
          ) {
            wantedStartDate = startDate;
          }

          currentDefaultDate = {
            start: wantedStartDate,
            end: endDate,
          };

          return { ...currentDefaultDate, quarterQount };
        }

        if (ckeck === 0) {
          let month = new Date(endDate).getMonth();
          let wantedEndDate = endDate;

          if (checkIfEndOfMonth(endDate) === 0 && (+month % 3 === 2)) {
            wantedEndDate = moment
              .utc(endDate)
              .endOf("month")
              .toDate();

          } else if (month % 3 !== 0) {
            wantedEndDate = moment
              .utc(endDate)
              .subtract(Math.round(month % 3) + 1, "month")
              .endOf("month")
              .toDate();
          } else {
            wantedEndDate = moment
              .utc(endDate)
              .subtract(1, "month")
              .endOf("month")
              .toDate();
          }
          if (quarter.length < quarterQount) {
            quarterQount = quarter.length - 1;
          }
          let wantedStartDate = moment
            .utc(wantedEndDate)
            .subtract(quarterX * 3 - 1, "month")
            .startOf("month")
            .toDate();

          if (
            new Date(wantedStartDate).getTime() < new Date(startDate).getTime()
          ) {
            wantedStartDate = startDate;
          }

          currentDefaultDate = {
            start: wantedStartDate,
            end: wantedEndDate,
          };
          return { ...currentDefaultDate, quarterQount };
        }
        if (year) {
          let month = new Date(endDate).getMonth();
          let wantedEndDate = endDate;

          if (checkIfEndOfMonth(endDate) === 0 && (+month % 3 === 2)) {
            wantedEndDate = moment
              .utc(endDate)
              .endOf("month")
              .toDate();

          } else if (month % 3 !== 0) {
            wantedEndDate = moment
              .utc(endDate)
              .subtract(Math.round(month % 3) + 1, "month")
              .endOf("month")
              .toDate();
          } else {
            wantedEndDate = moment
              .utc(endDate)
              .subtract(1, "month")
              .endOf("month")
              .toDate();
          }
          if (quarter.length < quarterQount) {
            quarterQount = quarter.length - 1;
          }
          let wantedStartDate = moment
            .utc(endDate)
            .subtract(quarterQount - 1, "year")
            .startOf("year")
            .toDate();

          if (new Date(wantedStartDate) < new Date(startDate)) {
            wantedStartDate = startDate;
          }

          currentDefaultDate = {
            start: wantedStartDate,
            end: wantedEndDate,
          };
          return { ...currentDefaultDate };
        }
      };
      const monthCalc = (ckeck, monthQount, year) => {
        let endDate = moment
          .utc(
            state.dateRange.radioChecked === 0
              ? state.dateRange.entireEnd
              : state.dateRange.end
          )
          .toDate();
        let startDate = moment
          .utc(
            state.dateRange.radioChecked === 0
              ? state.dateRange.entireStart
              : state.dateRange.start
          )
          .toDate();
        let end = endDate;
        let day = new Date(endDate).getDate();
        const monthQountX = monthQount;
        let month =
          new Date(endDate).getMonth() -
          new Date(startDate).getMonth() +
          12 *
          (new Date(endDate).getFullYear() -
            new Date(startDate).getFullYear());
        if (month <= monthQount) {
          monthQount = month;
        }
        if (ckeck === 1) {
          let start = moment
            .utc(endDate)
            .subtract(monthQountX - 1, "month")
            .startOf("month")
            .toDate();

          if (new Date(start) < new Date(startDate)) {
            start = startDate;
          }

          currentDefaultDate = {
            start: start,
            end: end,
          };
          newData.month = monthQount;

          return { ...currentDefaultDate, monthQount };
        }
        if (ckeck === 0) {

          let wantedEndDate = moment
            .utc(endDate)
            .subtract(checkIfEndOfMonth(endDate), "month")
            .endOf("month")
            .toDate();

          let start = moment
            .utc(wantedEndDate)
            .subtract(monthQountX - 1, "month")
            .startOf("month")
            .toDate();
          if (new Date(start) < new Date(startDate)) {
            start = startDate;
          }
          currentDefaultDate = {
            start: start,
            end: wantedEndDate,
          };
          newData.month = monthQount;

          return { ...currentDefaultDate, monthQount };
        }
        if (year) {
          let wantedEndDate = moment
            .utc(endDate)
            .subtract(checkIfEndOfMonth(endDate), "month")
            .endOf("month")
            .toDate();

          if (
            +new Date(endDate).getFullYear() -
            +new Date(startDate).getFullYear() >=
            +monthQount
          ) {
            startDate = moment
              .utc(`01/01/${+new Date(endDate).getFullYear() + 1}`)
              .subtract(+monthQount, "years")
              .toDate();
          }
          currentDefaultDate = {
            start: startDate,
            end: wantedEndDate,
          };
          return { ...currentDefaultDate };
        }
        newData.dateRangeType = 1;
        newData.dateType = 2;
        newData.dataForPrevious = -1;
      };
      const addYears = (start, end, check) => {
        const wantedStart = new Date(start);
        const wantedEndDate = new Date(end);
        const newYear = {
          newStartYear: +wantedStart.getFullYear(),
          newEndYear: +wantedEndDate.getFullYear(),
        };
        if (+check !== 1) {
          if (+wantedStart.getMonth() + 1 > +dateObj[check].startMonth) {
            newYear.newStartYear = +wantedStart.getFullYear() + 1;
          }
          if (+wantedEndDate.getMonth() + 1 > +dateObj[check].endMonth) {
            newYear.newEndYear = +wantedEndDate.getFullYear() + 1;
          }
        }
        const quantity = newYear.newEndYear - newYear.newStartYear;

        const resultFY = [];
        const resultAllDates = [];
        for (let i = quantity; i >= 0; i--) {
          resultFY.push({
            value: quantity - i + 1,
            key: newYear.newStartYear + i,
          });
          resultAllDates.push({
            value: `${quantity - i + 1}`,
            key: `${quantity - i + 1}`,
          });
        }

        return { resultFY, resultAllDates };
      };

      let dateRangeCustom = state.dateRange;
      let dateRangeX = state.dateRangeX;
      const radioName = nameChange(action.name);
      const newData = handleNewData(
        action.checked,
        radioName,
        action.name,
        state
      );
      if (action.name !== "dateRange" && action.name !== "yearStart") {
        newData.previusCheck = action;
      }
      if (
        state.dateRange.end === "Invalid date" ||
        state.dateRange.start === "Invalid date"
      ) {
        state.dateRange.end = state.dateRange.entireEnd;
        state.dateRange.start = state.dateRange.entireStart;
      }
      let newdataForPrevious = state.dataForPrevious.radioChecked;
      let newmostRecentYears = state.mostRecentYears.radioChecked;
      let newmostRecentQuarters = state.mostRecentQuarters.radioChecked;
      let newstartYearData = state.startYearData.radioChecked;
      let newmostRecentMonth = state.mostRecentMonth.radioChecked;
      let newChangeDropdown = state.data.mostRecentMonthSelect;
      let newChangeDropdownQuarter = state.data.mostRecentQuartersSelect;
      let newChangeDropdownMonth = state.data.mostRecentMonthSelect;
      let newChangeDropdownStartYear = state.data.mostRecentStartYearSelect;
      // StartYearData
      let selectedDate;
      selectedDate = state.datesForGraph.datesForGraphList[0].list[0];
      if (action.name === "changeDropdownQuarter") {
        newChangeDropdownQuarter = action.checked;
        newData.mostRecentQuartersSelect = action.checked;
        const data = quarterCalc(
          +state.mostRecentQuarters.radioChecked,
          +action.checked.value
        );
        newData.startDate = data.start;
        newData.endDate = data.end;
        newData.dateRangeType = 1;
        newData.quarter = data.quarterQount;
        newData.dateType = 1;
        newData.dataForPrevious = -1;
      }
      if (action.name === "changeDropdownMonth") {
        newChangeDropdownMonth = action.checked;
        newData.mostRecentMonthSelect = action.checked;
        const data = monthCalc(
          +state.mostRecentMonth.radioChecked,
          +action.checked.value
        );
        newData.month = data.monthQount;
        newData.startDate = data.start;
        newData.endDate = data.end;
        newData.dateRangeType = 1;
        newData.dateType = 2;
        newData.dataForPrevious = -1;
      }
      if (action.name === "mostRecentMonth") {
        newdataForPrevious = -1;
        const data = monthCalc(
          +action.checked,
          +state.data.mostRecentMonthSelect.value
        );
        newData.month = data.monthQount;
        newData.startDate = data.start;
        newData.endDate = data.end;
        newData.dateRangeType = 1;
        newData.dateType = 2;
        newData.dataForPrevious = -1;
      }
      if (action.name === "yearStart") {
        //  dataForPrevious worked uncorrectly, which I change for my needs
        newChangeDropdownStartYear = action.checked;
        newData.mostRecentStartYearSelect = action.checked;
        let endDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireEnd
            : state.dateRange.end;
        let startDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireStart
            : state.dateRange.start;

        const wantedAddYears = addYears(
          startDate,
          endDate,
          action.checked.value
        );
        wantedList = state.datesForGraph.datesForGraphList;
        wantedList[0].list = wantedAddYears.resultFY;
        wantedDatesList = wantedAddYears.resultAllDates;

        if (
          state.datesForGraph.datesForGraphList[0].selectedItem?.key >
          wantedList[0].list[0]?.key
        ) {
          wantedList[0].selectedItem.key = wantedList[0].list[0]?.key;
        }
        if (
          state.datesForGraph.datesForGraphList[0].selectedItem?.key <
          wantedList[0].list[wantedList[0].list?.length - 1]?.key
        ) {
          wantedList[0].selectedItem.key =
            wantedList[0].list[wantedList[0].list?.length - 1]?.key;
        }
        if (
          state.mostRecentYears.mostRecentYearsSelect.option?.key >
          wantedDatesList[wantedDatesList?.length - 1]?.key
        ) {
          newChangeDropdown = wantedDatesList[wantedDatesList?.length - 1];
          newData.mostRecentYearsSelect =
            wantedDatesList[wantedDatesList?.length - 1];
        }
        const checkDateDifference =
          new Date(
            `${dateObj[action.checked.value].startMonth}/01/${+new Date(
              endDate
            ).getFullYear()}`
          ) > new Date(endDate)
            ? 1
            : 0;

        if (state.data.dataForPrevious === 5) {
          const wantedStartDate = `${dateObj[action.checked.value].startMonth
            }/01/${+new Date(endDate).getFullYear() - (checkDateDifference + 1)}`;
          const wantedEndDate = `${dateObj[action.checked.value].endMonth}/${dateObj[action.checked.value].endDay
            }/${+new Date(endDate).getFullYear() +
            (action.checked.value !== "1" ? 0 : -1) -
            checkDateDifference
            }`;

          newData.startDate = moment.utc(wantedStartDate).toDate();
          newData.endDate = moment.utc(wantedEndDate).toDate();
        }
        if (state.data.dataForPrevious === 6) {
          newData.startDate = moment.utc(
            `${dateObj[action.checked.value].startMonth}/01/${+new Date(endDate).getFullYear() - checkDateDifference
            }`
          );
          newData.endDate = endDate;
        }

        if (state.data.dataForPrevious === 7) {
          let startYear;
          if (state.dataForPrevious.specificYear.selectedItem.key) {
            startYear = +state.dataForPrevious.specificYear.selectedItem.key;
          } else {
            startYear = +new Date(endDate).getFullYear();
          }
          newData.startDate = moment
            .utc(
              `${dateObj[action.checked.value].startMonth}/01/${+startYear + (action.checked.value === "1" ? 0 : -1)
              }`
            )
            .toDate();
          newData.endDate = moment
            .utc(
              `${dateObj[action.checked.value].startMonth}/01/${+startYear + 1 + (action.checked.value === "1" ? 0 : -1)
              }`
            )
            .subtract(1, "days")
            .toDate();
        }

        if (state.data.dataForPrevious === 8) {
          // if ((new Date(endDate).getFullYear() - new Date(startDate).getFullYear()) >= +yearCount) {
          //     startDate = moment.utc(`01/01/${+new Date(endDate).getFullYear() + 1}`).subtract(+yearCount, "years").toDate();
          // }
          newData.startDate = moment
            .utc(
              `${dateObj[action.checked.value].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = endDate;
        }
        if (state.data.dataForPrevious === 9) {
          const wantedCheckDate = (+dateObj[action.checked.value].endMonth === (+moment.utc(endDate).get('month') + 1) && checkIfEndOfMonth(endDate) === 0) ? 1 : 0
          newData.startDate = moment
            .utc(
              `${dateObj[action.checked.value].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount + 1, "years")
            .toDate();
          newData.endDate = moment
            .utc(
              `${dateObj[action.checked.value].startMonth}/01/${new Date(endDate).getFullYear() - checkDateDifference + wantedCheckDate
              }`
            )
            .subtract(1, "month")
            .endOf("month")
            .toDate();
        }
        if (state.data.dataForPrevious === 10) {
          const data = quarterCalc(
            null,
            +state.data.mostRecentYearsSelect.value,
            true
          );

          newData.startDate = moment
            .utc(
              `${dateObj[action.checked.value].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = data.end;
        }
        if (state.data.dataForPrevious === 11) {
          const data = monthCalc(
            null,
            +state.data.mostRecentYearsSelect.value,
            true
          );
          newData.startDate = moment
            .utc(
              `${dateObj[action.checked.value].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = data.end;
        }

        if (new Date(endDate) < new Date(newData.endDate)) {
          newData.endDate = endDate;
        }

        if (new Date(startDate) > new Date(newData.startDate)) {
          newData.startDate = startDate;
        }

        newData.mostRecentStartYearSelect = action.checked;
        currentDefaultDate = {
          start: newData.startDate,
          end: newData.endDate,
        };
      }
      if (action.name === "mostRecentQuarters") {
        newChangeDropdownQuarter = action.checked;
        const data = quarterCalc(
          +action.checked,
          +state.data.mostRecentQuartersSelect.value
        );
        newData.startDate = data.start;
        newData.endDate = data.end;
        newData.dateRangeType = 1;
        newData.quarter = data.quarterQount;
        newData.dateType = 1;
        newData.dataForPrevious = -1;
      }
      if (action.name === "changeDropdown") {
        const yearCount = +action.checked.value;
        newChangeDropdown = action.checked;
        newData.mostRecentYearsSelect = action.checked;
        let endDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireEnd
            : state.dateRange.end;
        let startDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireStart
            : state.dateRange.start;
        //    if([2,3].includes(state.mostRecentYears.radioChecked))?  :newdataForPrevious = 3
        newdataForPrevious = -1;
        if (
          action.checked.value === "1" &&
          (state.mostRecentYears.radioChecked === 0 ||
            state.mostRecentYears.radioChecked === 1)
        ) {
          newdataForPrevious = 3;
        }
        const checkDateDifference =
          new Date(
            `${dateObj[checkDate].startMonth}/01/${+new Date(
              endDate
            ).getFullYear()}`
          ) > new Date(endDate)
            ? 1
            : 0;
        if (state.mostRecentYears.radioChecked === 0) {
          newData.dateRangeType = 1;
          newData.dataForPrevious = 8;
          newData.dateType = 0;
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = endDate;
        }
        if (state.mostRecentYears.radioChecked === 1) {
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount + 1, "years")
            .toDate();
          newData.endDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${new Date(endDate).getFullYear() - checkDateDifference
              }`
            )
            .subtract(1, "month")
            .endOf("month")
            .toDate();

          newData.dateRangeType = 1;
          newData.dataForPrevious = 9;
          newData.dateType = 0;
        }
        if (+state.mostRecentYears.radioChecked === 2) {
          const data = quarterCalc(null, +action.checked.value, true);
          newData.dateRangeType = 1;
          newData.dataForPrevious = 10;
          newData.dateType = 0;
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = data.end;
        }
        if (+state.mostRecentYears.radioChecked === 3) {
          const data = monthCalc(null, +action.checked.value, true);
          newData.dateRangeType = 1;
          newData.dataForPrevious = 11;
          newData.dateType = 0;
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              }`
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = data.end;
        }
        if (new Date(endDate) < new Date(newData.endDate)) {
          newData.endDate = endDate;
        }

        if (new Date(startDate) > new Date(newData.startDate)) {
          newData.startDate = startDate;
        }
        currentDefaultDate = { start: newData.startDate, end: newData.endDate };
      }
      if (action.name === "dateRangeX") {
        let endDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireEnd
            : state.dateRange.end;
        let startDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireStart
            : state.dateRange.start;
        const checkDateDifference =
          new Date(
            `${dateObj[checkDate].startMonth}/01/${+new Date(
              endDate
            ).getFullYear()}`
          ) > new Date(endDate)
            ? 1
            : 0;
        //const customName = nameChange("dateRange");
        // handleNewData(
        //     1,
        //     customName,
        //     "dateRange",
        //     state
        // );
        // dateRangeCustom = { ...dateRangeCustom, radioChecked: 1 }
        if (action.checked === 5) {
          newmostRecentMonth = 1;
          const data = monthCalc(1, +state.data.mostRecentMonthSelect.value);
          newData.month = data.monthQount;
          newData.startDate = data.start;
          newData.endDate = data.end;
          newData.dateRangeType = 1;
          newData.dateType = 2;
          newData.dataForPrevious = -1;
        }
        if (action.checked === 4) {
          newmostRecentQuarters = 1;
          newChangeDropdownQuarter = action.checked;
          const data = quarterCalc(
            1,
            +state.data.mostRecentQuartersSelect.value
          );
          newData.startDate = data.start;
          newData.endDate = data.end;
          newData.dateRangeType = 1;
          newData.quarter = data.quarterQount;
          newData.dateType = 1;
          newData.dataForPrevious = -1;
        }
        if (3 === action.checked) {
          newmostRecentYears = 0;
          const yearCount = state.data.mostRecentYearsSelect.value;
          if (+yearCount === 1) {
            newdataForPrevious = 3;

            newData.startDate = moment
              .utc(
                `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() - checkDateDifference
                }`
              )
              .toDate();
            newData.endDate = endDate;
            newData.dateRangeType = 2;
            newData.dataForPrevious = 6;
            newData.dateType = 0;
            newData.year = new Date(endDate).getFullYear();
          } else {
            newData.dateRangeType = 1;
            newData.dataForPrevious = 8;
            newData.dateType = 0;
            newData.startDate = moment
              .utc(
                `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
                }`
              )
              .subtract(+yearCount, "years")
              .toDate();
            newData.endDate = currentDefaultDate.end;
          }
        }
        if (new Date(endDate) < new Date(newData.endDate)) {
          newData.endDate = endDate;
        }

        if (new Date(startDate) > new Date(newData.startDate)) {
          newData.startDate = startDate;
        }

        currentDefaultDate = {
          start: newData.startDate,
          end: newData.endDate,
        };
      }
      if (action.name === "dataForPrevious") {
        //  dataForPrevious worked uncorrectly, which I change for my needs

        let endDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireEnd
            : state.dateRange.end;
        let startDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireStart
            : state.dateRange.start;
        newmostRecentYears = 0;

        const checkDateDifference =
          new Date(
            `${dateObj[checkDate].startMonth}/01/${+new Date(
              endDate
            ).getFullYear()}`
          ) > new Date(endDate)
            ? 1
            : 0;

        if (action.checked === 1) {
          // newData.startDate = moment.utc(`${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear()}`).subtract(1, "years").toDate();
          // newData.endDate = moment.utc(`${dateObj[checkDate].endMonth}/${dateObj[checkDate].endDay}/${+new Date(endDate).getFullYear() + (checkDate !== '1' ? 1 : 0)}`).toDate();
          const wantedStartDate = `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() - (checkDateDifference + 1)
            }`;
          const wantedEndDate = `${dateObj[checkDate].endMonth}/${dateObj[checkDate].endDay
            }/${+new Date(endDate).getFullYear() +
            (checkDate !== "1" ? 0 : -1) -
            checkDateDifference
            }`;

          newData.startDate = moment.utc(wantedStartDate).toDate();
          newData.endDate = moment.utc(wantedEndDate).toDate();
          newData.dateRangeType = 1;
          newData.dataForPrevious = 5;
          newData.dateType = 0;
          newData.year = new Date(newData.endDate).getFullYear();
          currentDefaultDate = {
            start: newData.startDate,
            end: newData.endDate,
          };
        } else if (action.checked === 3) {
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${+new Date(endDate).getFullYear() - checkDateDifference
              }`
            )
            .toDate();
          newData.endDate = endDate;
          newData.dateRangeType = 1;
          newData.dataForPrevious = 6;
          newData.dateType = 0;
          newData.year = new Date(endDate).getFullYear();
        } else {
          let startYear;

          if (state.dataForPrevious.specificYear.selectedItem.key) {
            startYear = +state.dataForPrevious.specificYear.selectedItem.key;
          } else {
            startYear = +new Date(endDate).getFullYear();
          }

          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${+startYear + (checkDate === "1" ? 0 : -1)
              }`
            )
            .toDate();
          newData.endDate = moment
            .utc(
              `${dateObj[checkDate].startMonth}/01/${+startYear + 1 + (checkDate === "1" ? 0 : -1)
              } `
            )
            .subtract(1, "days")
            .toDate();
          newData.dateRangeType = 1;
          newData.dataForPrevious = 7;
          newData.dateType = 0;
        }
        if (new Date(endDate) < new Date(newData.endDate)) {
          newData.endDate = endDate;
        }

        if (new Date(startDate) > new Date(newData.startDate)) {
          newData.startDate = startDate;
        }

        currentDefaultDate = {
          start: newData.startDate,
          end: newData.endDate,
        };
      }
      if (action.name === "mostRecentYears") {
        let endDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireEnd
            : state.dateRange.end;
        let startDate =
          state.dateRange.radioChecked === 0
            ? state.dateRange.entireStart
            : state.dateRange.start;
        const yearCount = state.data.mostRecentYearsSelect.value;
        newdataForPrevious = -1;

        const checkDateDifference =
          new Date(
            `${dateObj[checkDate].startMonth} /01/${+new Date(
              endDate
            ).getFullYear()} `
          ) > new Date(endDate)
            ? 1
            : 0;
        if (action.checked === 0) {
          // if ((new Date(endDate).getFullYear() - new Date(startDate).getFullYear()) >= +yearCount) {
          //     startDate = moment.utc(`01 /01 / ${ +new Date(endDate).getFullYear() + 1 } `).subtract(+yearCount, "years").toDate();
          // }
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth} /01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              } `
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = endDate;

          newData.dateRangeType = 1;
          newData.dataForPrevious = 8;
          newData.dateType = 0;
        }
        if (action.checked === 1) {

          const wantedCheckDate = (+dateObj[checkDate].endMonth === (+moment.utc(endDate).get('month') + 1) && checkIfEndOfMonth(endDate) === 0) ? 1 : 0
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth} /01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              } `
            )
            .subtract(+yearCount + 1, "years")
            .toDate();
          newData.endDate = moment
            .utc(
              `${dateObj[checkDate].startMonth} /01/${new Date(endDate).getFullYear() - checkDateDifference + wantedCheckDate
              } `
            )
            .subtract(1, "month")
            .endOf("month")
            .toDate();
          newData.dateRangeType = 1;
          newData.dataForPrevious = 9;
          newData.dateType = 0;
        }
        if (+action.checked === 2) {
          const data = quarterCalc(
            null,
            +state.data.mostRecentYearsSelect.value,
            true
          );
          newData.dateRangeType = 1;
          newData.dataForPrevious = 10;
          newData.dateType = 0;
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth} /01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              } `
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = data.end;
        }
        if (+action.checked === 3) {
          const data = monthCalc(
            null,
            +state.data.mostRecentYearsSelect.value,
            true
          );
          newData.dateRangeType = 1;
          newData.dataForPrevious = 11;
          newData.dateType = 0;
          newData.startDate = moment
            .utc(
              `${dateObj[checkDate].startMonth} /01/${+new Date(endDate).getFullYear() + 1 - checkDateDifference
              } `
            )
            .subtract(+yearCount, "years")
            .toDate();
          newData.endDate = data.end;
        }

        if (new Date(endDate) < new Date(newData.endDate)) {
          newData.endDate = endDate;
        }

        if (new Date(startDate) > new Date(newData.startDate)) {
          newData.startDate = startDate;
        }
        currentDefaultDate = { start: newData.startDate, end: newData.endDate };
      }
      if (action.name === "datesForGraph") {
        currentDefaultDate = getDate(
          action.checked,
          selectedDate,
          state.dateRange.entireStart,
          state.dateRange.entireEnd
        );
        newData.dataForPrevious = -1;
      }
      if (action.name === "dateRange") {
        let { dateRange } = state;
        if (action.checked === 1) {
          currentDefaultDate = {
            start: dateRange.start || dateRange.entireStart,
            end: dateRange.end || dateRange.entireEnd,
          };
          newData.startDate = currentDefaultDate.start;
          newData.endDate = currentDefaultDate.end;

          newData.dateRangeType = 1;
          newData.dataForPrevious = -1;
          newData.dateType = 0;
          newData.customDateRange = 1;
        }
        if (0 === action.checked) {
          dateRangeX = { ...dateRangeX, radioChecked: -1 };
          currentDefaultDate = {
            start: state.dateRange.entireStart,
            end: state.dateRange.entireEnd,
          };
          newData.dateRangeType = 0;
          newData.dataForPrevious = -1;
          newData.dateType = 0;
          newData.startDate = currentDefaultDate.start;
          newData.endDate = currentDefaultDate.end;
          newData.customDateRange = 0;
        }
        const wantedAddYears = addYears(
          currentDefaultDate.start,
          currentDefaultDate.end,
          checkDate
        );
        wantedList = state.datesForGraph.datesForGraphList;
        wantedList[0].list = wantedAddYears.resultFY;
        wantedDatesList = wantedAddYears.resultAllDates;

        if (
          state.datesForGraph.datesForGraphList[0].selectedItem?.key >
          wantedList[0].list[0]?.key
        ) {
          wantedList[0].selectedItem.key = wantedList[0].list[0]?.key;
        }
        if (
          state.datesForGraph.datesForGraphList[0].selectedItem?.key <
          wantedList[0].list[wantedList[0].list?.length - 1]?.key
        ) {
          wantedList[0].selectedItem.key =
            wantedList[0].list[wantedList[0].list?.length - 1]?.key;
        }
        if (
          state.mostRecentYears.mostRecentYearsSelect.option?.key >
          wantedDatesList[wantedDatesList?.length - 1]?.key
        ) {
          newChangeDropdown = wantedDatesList[wantedDatesList?.length - 1];
          newData.mostRecentYearsSelect =
            wantedDatesList[wantedDatesList?.length - 1];
        }
      }

      currentDefaultDate.start = moment
        .utc(currentDefaultDate.start)
        .startOf("day")
        .toDate();
      currentDefaultDate.end = moment
        .utc(currentDefaultDate.end)
        .endOf("day")
        .toDate();
      if (moment.utc(currentDefaultDate.start).isAfter(moment.utc(new Date()).subtract(1, 'days').endOf('day'))) {
        currentDefaultDate.start = moment.utc(new Date()).subtract(1, 'days').endOf('day').toDate()
      }

      if (moment.utc(currentDefaultDate.end).isAfter(moment.utc(new Date()).subtract(1, 'days').endOf('day'))) {
        currentDefaultDate.end = moment.utc(new Date()).subtract(1, 'days').endOf('day').toDate()
      }

      return {
        ...state,
        dateRange: { ...dateRangeCustom },
        dateRangeX,
        datesForGraph: {
          ...state.datesForGraph,
          datesForGraphList: wantedList
            ? wantedList
            : state.datesForGraph.datesForGraphList,
        },
        dataForPrevious: {
          ...state.dataForPrevious,
          specificYear: {
            ...state.dataForPrevious.specificYear,
            list: wantedList
              ? wantedList[0].list
              : state.datesForGraph.datesForGraphList[0].list,
          },
          radioChecked: newdataForPrevious,
        },
        mostRecentYears: {
          ...state.mostRecentYears,
          radioChecked: newmostRecentYears,
          mostRecentYearsSelect: {
            ...state.mostRecentYears.mostRecentYearsSelect,
            option: newChangeDropdown,
            list: wantedDatesList
              ? wantedDatesList
              : state.mostRecentYears.mostRecentYearsSelect.list,
          },
        },
        mostRecentQuarters: {
          ...state.mostRecentQuarters,
          radioChecked: newmostRecentQuarters,
          mostRecentQuartersSelect: {
            ...state.mostRecentQuarters.mostRecentQuartersSelect,
            option: newChangeDropdownQuarter,
          },
        },
        mostRecentMonth: {
          ...state.mostRecentMonth,
          radioChecked: newmostRecentMonth,
          mostRecentMonthSelect: {
            ...state.mostRecentMonth.mostRecentMonthSelect,
            option: newChangeDropdownMonth,
          },
        },
        startYearData: {
          ...state.startYearData,
          startYearDataSelect: {
            ...state.startYearData.startYearDataSelect,
            option: newChangeDropdownStartYear,
          },
        },
        [action.name]: { ...state[action.name], radioChecked: action.checked },
        data: {
          ...newData,
          startDate: currentDefaultDate.start,
          endDate: currentDefaultDate.end,
        },
        date: { ...state.date, ...currentDefaultDate },
      };
    case actions.SIDE_BAR_HANDLE_FOCUS:
      return {
        ...state,
        [action.name]: { ...state[action.name], focus: action.focus },
      };
    case actions.START_YEAR_CHANGE_REVIEW: {
      return {
        ...state,
        startYearData: {
          ...state.startYearData,
          startYearDataSelect: {
            ...state.startYearData.startYearDataSelect,
            option: { value: "4", key: "Oct. 1 - Sept. 30" },
          },
        },
        yearStart: {
          ...state.yearStart,
          radioChecked: { value: "4", key: "Oct. 1 - Sept. 30" },
        },
        data: {
          ...state.data,
          mostRecentStartYearSelect: { value: "4", key: "Oct. 1 - Sept. 30" },
          yearStart: { value: "4", key: "Oct. 1 - Sept. 30" },
        },
      };
    }
    case actions.SIDE_BAR_HANDLE_SEARCH:
      return {
        ...state,
        [action.name]: { ...state[action.name], search: action.value },
      };
    case actions.SIDE_BAR_FILTER_EXCLUDE_LIST:
      const updatedList = state[action.name].list.filter((el) => {
        return el.text
          .toLowerCase()
          .includes(state[action.name].search.toLowerCase());
      });

      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          searchList: updatedList,
        },
      };
    case actions.SIDE_BAR_EXCLUDE_CHANGE: {
      const { name: excludeName, item: { id: excludeId, type, text } = {} } =
        action;
      const updatedExcludesList = state[excludeName].list.map((el) => {
        if (type === "group") {
          if (el.groupName === text) {
            el.include = !el.include;
          }
        }
        if (el.id === excludeId) {
          el.exclude = !el.exclude;
        }
        return el;
      });
      const excludeOldName = excludeNameItem(excludeName);
      const excludeFinalList = updatedExcludesList
        .filter((el) => {
          return !el.exclude && el.hide !== true;
        })
        .map((el) => {
          // if(state[excludeName].renameName[el.text]){
          //     return state[excludeName].renameName[el.text];
          // }
          return el.text;
        });
      return {
        ...state,
        [excludeName]: { ...state[excludeName], list: updatedExcludesList },
        data: {
          ...state.data,
          [excludeOldName]: excludeFinalList,
        },
      };
    }
    case actions.AUTO_INCLUDE_SIDE_BAR_RADIO_CHANGE: {
      const includeIdArr = action.items
        .map((item) => item.id)
        .slice(0, action.range);
      const includeName = action.name;
      const updatedIncludeList = state[includeName].list.map((el) => {
        const newEl = { ...el };
        if (includeIdArr.includes(el.id)) {
          newEl.include = true;
        } else {
          newEl.include = false;
        }
        return newEl;
      });
      const renamedFields = state[includeName].renamedFields || {};
      const includeOldName = includeNameItem(includeName);
      const includeFinalList = updatedIncludeList
        .filter((el) => el.include)
        .map((el) => {
          return renamedFields[el.text] || el.text;
        });
      return {
        ...state,
        [includeName]: { ...state[includeName], list: updatedIncludeList },
        data: {
          ...state.data,
          [includeOldName]: includeFinalList,
        },
      };
    }
    case actions.SIDE_BAR_INCLUDE_CHANGE_RETDIAE: {
      const { name: includeName, bool, item } = action;
      const updatedIncludeList = state[includeName].list.map((el) => {
        item.forEach((i) => {
          if (el.id === i.id && bool === true) {
            bool === true ? (el.include = true) : (el.include = false);
          }
          if (bool === false) {
            el.include = false;
          }
        });
        return el;
      });
      const renamedFields = state[includeName].renamedFields || {};

      const includeOldName = includeNameItem(includeName);
      const includeFinalList = updatedIncludeList
        .filter((el) => {
          if (el.type === "group") {
            return el.include;
          }
          return el.include;
        })
        .map((el) => {
          if (includeName === "manuscriptVersions") {
            return el.value;
          }
          return renamedFields[el.text] || el.text;
        });
      return {
        ...state,
        [includeName]: { ...state[includeName], list: updatedIncludeList },
        data: {
          ...state.data,
          [includeOldName]: includeFinalList,
        },
      };
    }
    case actions.SIDE_BAR_INCLUDE_CHANGE: {
      const { name: includeName, item: { id: includeId, text } = {} } = action;

      const updatedIncludeList = state[includeName].list.map((el) => {
        if (el.id === includeId) {
          el.include = !el.include;
        } else if (el.text === text && text === "No Revision Number") {
          el.include = true;
        }
        return el;
      });
      const renamedFields = state[includeName].renamedFields || {};

      const includeOldName = includeNameItem(includeName);
      const includeFinalList = updatedIncludeList
        .filter((el) => {
          return el.include === true && el.hide !== true;
        })
        .map((el) => {
          if (includeName === "manuscriptVersions") {
            return el.value;
          }
          return renamedFields[el.text] || el.text;
        });
      return {
        ...state,
        [includeName]: { ...state[includeName], list: updatedIncludeList },
        data: {
          ...state.data,
          [includeOldName]: includeFinalList,
        },
      };
    }
    case actions.REVSION_NUMBER_ALL: {
      const result = [];
      let wantedmanuscriptVersionsList = [];
      for (let i = 0; i < state.manuscriptVersions.list.length - 1; i++) {
        result.push(+state.manuscriptVersions.list[i]?.value);
        wantedmanuscriptVersionsList = state.manuscriptVersions.list.map(
          (item) => {
            let include = false;
            if (Number(item.value) || Number(item.value) === 0) {
              include = true;
            }
            return {
              ...item,
              include,
            };
          }
        );
      }

      return {
        ...state,
        manuscriptVersions: {
          ...state.manuscriptVersions,
          list: wantedmanuscriptVersionsList,
        },
        data: {
          ...state.data,
          revisionType: 1,
          choosenRevisionNumbers: result,
        },
      };
    }
    case SIDE_BAR_INCLUDE_CHANGE_SECOND:
      const { name: includeName, item: { id: includeId, text, type } = {} } =
        action.payload;
      const updatedIncludeList = state[includeName].list.map((el) => {
        if (el.id === includeId) {
          el.include = !el.include;
        }
        if (type === "group") {
          if (el.groupName === text) {
            el.include = !el.include;
          }
        }
        return el;
      });
      const renamedFields1 = state[includeName].renamedFields || {};

      const includeOldName = includeNameItem(includeName);
      const includeFinalList = updatedIncludeList
        .filter((el) => {
          if (el.type === "group") {
            return false;
          }
          return el.include;
        })
        .map((el) => {
          if (includeName === "manuscriptVersions") {
            return el.value;
          }
          return renamedFields1[el.text] || el.text;
        });
      return {
        ...state,
        [includeName]: { ...state[includeName], list: updatedIncludeList },
        data: {
          ...state.data,
          [includeOldName]: includeFinalList,
        },
      };
    case actions.SIDE_BAR_DATE_CHANGE:
      const addYearsSecond = (start, end, check) => {
        const wantedStart = new Date(start);
        const wantedEndDate = new Date(end);
        const newYear = {
          newStartYear: +wantedStart.getFullYear(),
          newEndYear: +wantedEndDate.getFullYear(),
        };

        if (+check !== 1) {
          if (+wantedStart.getMonth() + 1 > +dateObj[check].startMonth) {
            newYear.newStartYear = +wantedStart.getFullYear() + 1;
          }
          if (+wantedEndDate.getMonth() + 1 > +dateObj[check].endMonth) {
            newYear.newEndYear = +wantedEndDate.getFullYear() + 1;
          }
        }
        const quantity = newYear.newEndYear - newYear.newStartYear;
        const resultFY = [];
        const resultAllDates = [];
        for (let i = quantity; i >= 0; i--) {
          resultFY.push({
            value: quantity - i + 1,
            key: newYear.newStartYear + i,
          });
          resultAllDates.push({
            value: `${quantity - i + 1}`,
            key: `${quantity - i + 1}`,
          });
        }

        return { resultFY, resultAllDates };
      };
      let updatedDate = moment.utc(action.date).toDate();
      let wantedData = {};
      let newChangeDropdownSecond = state.data.mostRecentMonthSelect;
      const maxYear =
        action.name === "end"
          ? new Date(moment.utc(action.date).toDate())
          : new Date(moment.utc(state.data.endDate).toDate());
      const minYear =
        action.name === "start"
          ? new Date(moment.utc(action.date).toDate())
          : new Date(moment.utc(state.data.startDate).toDate());

      const wantedAddYears = addYearsSecond(minYear, maxYear, checkDate);
      let wantedListSecond = state.datesForGraph.datesForGraphList;
      wantedListSecond[0].list = wantedAddYears.resultFY;
      let wantedDatesListSecond = wantedAddYears.resultAllDates;

      if (
        state.datesForGraph.datesForGraphList[0].selectedItem?.key >
        wantedListSecond[0].list[0]?.key
      ) {
        wantedListSecond[0].selectedItem.key = wantedListSecond[0].list[0]?.key;
      }
      if (
        state.datesForGraph.datesForGraphList[0].selectedItem?.key <
        wantedListSecond[0].list[wantedListSecond[0].list?.length - 1]?.key
      ) {
        wantedListSecond[0].selectedItem.key =
          wantedListSecond[0].list[wantedListSecond[0].list?.length - 1]?.key;
      }
      if (
        state.mostRecentYears.mostRecentYearsSelect.option?.key >
        wantedDatesListSecond[wantedDatesListSecond?.length - 1]?.key
      ) {
        newChangeDropdownSecond =
          wantedDatesListSecond[wantedDatesListSecond?.length - 1];
        wantedData.mostRecentYearsSelect =
          wantedDatesListSecond[wantedDatesListSecond?.length - 1];
      }

      if (moment.utc(action.date).isBefore(state.dateRange.entireStart)) {
        updatedDate = moment.utc(state.dateRange.entireStart).toDate();
      } else if (moment.utc(action.date).isAfter(state.dateRange.entireEnd)) {
        updatedDate = moment.utc(state.dateRange.entireEnd).toDate();
      }
      updatedDate = moment.utc(updatedDate).format("MM/DD/YYYY");
      return {
        ...state,
        dateRange: { ...state.dateRange, [action.name]: updatedDate },
        datesForGraph: {
          ...state.datesForGraph,
          datesForGraphList: wantedListSecond,
        },
        mostRecentYears: {
          ...state.mostRecentYears,
          mostRecentYearsSelect: {
            ...state.mostRecentYears.mostRecentYearsSelect,
            option: newChangeDropdownSecond,
            list: wantedDatesListSecond
              ? wantedDatesListSecond
              : state.mostRecentYears.mostRecentYearsSelect.list,
          },
        },
        data: {
          ...state.data,
          [action.name + "Date"]: updatedDate,
        },
        date: {
          ...state.date,
          ...wantedData,
          [action.name]: updatedDate,
        },
      };
    case actions.SIDE_BAR_DATE_CHANGE_DROPDOWN:
      let updatedDatesForGraphList = [...state.datesForGraph.datesForGraphList];
      let currentDate;
      let defaultEndDate =
        state.dateRange.radioChecked === 0
          ? state.dateRange.entireEnd
          : state.dateRange.end;
      let defaultStartDate =
        state.dateRange.radioChecked === 0
          ? state.dateRange.entireStart
          : state.dateRange.start;
      updatedDatesForGraphList = updatedDatesForGraphList.map((el) => {
        if (el.id === action.id) {
          el.selectedItem = action.item;
        }
        return el;
      });
      let date;
      let name;
      let selectedItem = state.dataForPrevious.specificYear.selectedItem;

      if (action.id === 3) {
        name = "year";
        date = action.item.key;
        selectedItem = action.item;

        let wantedStartDate = moment
          .utc(
            `${dateObj[checkDate].startMonth} /01/${+action.item.key + (checkDate === "1" ? 0 : -1)
            } `
          )
          .toDate();
        let wantedEndDate = moment
          .utc(
            `${dateObj[checkDate].startMonth} /01/${+action.item.key + 1 + (checkDate === "1" ? 0 : -1)
            } `
          )
          .subtract(1, "days")
          .endOf("day")
          .toDate();

        if (new Date(defaultStartDate) > new Date(wantedStartDate)) {
          wantedStartDate = defaultStartDate;
        }

        if (new Date(defaultEndDate) < new Date(wantedEndDate)) {
          wantedEndDate = defaultEndDate;
        }

        currentDate = {
          start: moment.utc(wantedStartDate).startOf("day").toDate(),
          end: moment.utc(wantedEndDate).endOf("day").toDate(),
        };
      } else if (action.id === 1) {
        name = "quarter";
        date = action.item.value;
        currentDate = getDate(
          action.id,
          action.item,
          state.dateRange.entireStart,
          state.dateRange.entireEnd
        );
      } else if (action.id === 2) {
        name = "month";
        date = action.item.value;
        currentDate = getDate(
          action.id,
          action.item,
          state.dateRange.entireStart,
          state.dateRange.entireEnd
        );
      }

      return {
        ...state,
        date: currentDate,
        datesForGraph: {
          ...state.datesForGraph,
          datesForGraphList: updatedDatesForGraphList,
        },
        dataForPrevious: {
          ...state.dataForPrevious,
          specificYear: {
            ...state.dataForPrevious.specificYear,
            selectedItem: selectedItem,
          },
        },
        data: {
          ...state.data,
          startDate: currentDate.start,
          endDate: currentDate.end,
          [name]: date,
        },
      };
    case actions.HANDLE_SORT_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          data_order_sort: action.value,
        },
      };
    case actions.HANDLE_SEPERATE_SORT_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          separate_sort_value: action.value,
        },
      };
    case actions.CHANGE_SEPARATE_SORT_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          separate_sort_value: action.value,
        },
      };
    case actions.CHANGE_DATA_ORDER_SORT_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          data_order_sort: action.value,
        },
      };
    case actions.CHANGE_PALETTE_COLORS:
      return {
        ...state,
        data: {
          ...state.data,
          paletteColors: action.colorsSet,
        },
      };
    case actions.CHANGE_GRAPH_DATE_TYPE:
      let newDate;
      let dateList;

      state.datesForGraph.datesForGraphList.forEach((el) => {
        if (el.id === action.value) {
          dateList = el.selectedItem;
        }
      });

      // newDate = getDate(
      //     action.value,
      //     dateList,
      //     state.dateRange.entireStart,
      //     state.dateRange.entireEnd
      // );
      return {
        ...state,
        datesForGraph: {
          ...state.datesForGraph,
          radioChecked: action.value,
        },
        data: {
          ...state.data,
          dateType: action.value,
        },
        date: { ...state.date, ...newDate },
      };
    case actions.RESET_CHART_OPTIONS:
      localStorage.removeItem("fromReport");
      const resetData = fillDataToState(
        defaultData,
        defaultState,
        action.reportType
      );
      const newResetData = fillLockedData(state, resetData);
      return {
        ...newResetData,
        data: {
          ...newResetData.data,
          manuscriptSystem: state.data.manuscriptSystem,
          zeroAcceptCountRadio: state.data.zeroAcceptCountRadio,
          zeroRejectCountRadio: state.data.zeroRejectCountRadio,
          activeContent: state.data.activeContent,
        },
      };
    case actions.TOGGLE_CONFIG_LOCK: {
      const { configType } = action;
      const newState = {
        ...state,
        [configType]: {
          ...state[configType],
          locked: !state[configType].locked,
        },
      };
      return {
        ...newState,
      };
    }
    case actions.HANDLE_ACCEPT_AND_REJECT_COUNT:
      const { accept, reject } = action;

      return {
        ...state,
        data: {
          ...state.data,
          activeContent: 1,
          zeroAcceptCountRadio: accept,
          zeroRejectCountRadio: reject,
        },
      };
    case actions.SET_SORT_SEQUENTIAL:
      return {
        ...state,
        data: {
          ...state.data,
          data_order_sort: {
            id: 6,
            text: "Sequential",
            value: "sequential",
          },
        },
      };
    case actions.SET_SORT_OLDEST_TO_NEWEST:
      return {
        ...state,
        data: {
          ...state.data,
          data_order_sort: {
            id: 4,
            text: "Time (oldest to newest)",
            value: "xAxisOldToNew",
          },
        },
      };
    case actions.SET_SORT_ALPHABETICAL:
      return {
        ...state,
        data: {
          ...state.data,
          data_order_sort: {
            id: 2,
            text: "'Text (A-Z)'",
            value: "ascText",
          },
        },
      };
    case actions.SET_SORT_LEGEND_ASC:
      return {
        ...state,
        data: {
          ...state.data,
          separate_sort_value: {
            id: 2,
            text: "Legend Text (A-Z)",
            value: "ascLegend",
          },
        },
      };
    case actions.SET_SORT_SEGMENT_DESC:
      return {
        ...state,
        data: {
          ...state.data,
          separate_sort_value: {
            id: 0,
            text: "Descending",
            value: "descSegment",
          },
        },
      };
    case actions.SET_SORT_DEFAULT:
      return {
        ...state,
        data: {
          ...state.data,
          data_order_sort: {
            id: 0,
            text: "Values (descending)",
            value: "descValue",
          },
        },
      };
    case actions.UPDATE_SELECTED_ITEMS_PREV_SET:
      return {
        ...state,
        selectedPrevItemSet: action.data,
      };
    case actions.TOGGLE_GROUP_SELECTION_MODAL: {
      const { currentName, currentType, actionType, newName } = action.payload;
      if (actionType === "rename") {
        return {
          ...state,
          showCreateGroupModal: !state.showCreateGroupModal,
          currentName: action.payload.currentName,
          currentType: action.payload.currentType,
          actionType: action.payload.actionType,
          newName,
        };
      } else if (actionType === "createGroup") {
        const includedValues = currentName.filter((item) => item.include);
        return {
          ...state,
          showCreateGroupModal:
            action.payload.needModal !== undefined
              ? action.payload.needModal
              : !state.showCreateGroupModal,
          currentType,
          currentGroupValues: includedValues,
          actionType,
          currentName: "",
        };
      }
      if (actionType === "cancelRenameModal") {
        return {
          ...state,
          showCreateGroupModal: false,
          showUnGroupModal: false,
        };
      }
      break;
    }
    case actions.TOGGLE_UN_GROUP_SELECTION_MODAL: {
      return {
        ...state,
        showUnGroupModal: action.payload.dontShowModal
          ? false
          : !state.showUnGroupModal,
        currentName: action.payload.currentName,
        currentType: action.payload.currentType,
        actionType: action.payload.actionType,
      };
    }
    case actions.HISTOGRAM_EDITORS_FILTER: {
      return {
        ...state,
        editors: action.editors,
      };
    }
    case actions.RESET_GROUPING: {
      const type = action.payload;
      const name = state[type].list.find((el) => el.groupValues)?.text || "";
      if (!name) return state;
      let dataSelectedItems = [];
      const renamedData = { ...state.data[renameName(type)] };
      const list = state[type].list
        .filter((item) => {
          if (item.text !== name) {
            return true;
          }
          return false;
        })
        .map((item) => {
          if (item.groupName === name) {
            dataSelectedItems.push(item.text);
            delete renamedData[item.text];
            return {
              ...item,
              groupName: undefined,
              hide: false,
            };
          }
          return item;
        });
      const includeOldName = includeNameItem(type);

      return {
        ...state,
        showUnGroupModal: false,
        [type]: {
          ...state[type],
          list,
          searchList: list,
          renamedFields: {
            ...renamedData,
          },
        },
        data: {
          ...state.data,
          [renameName(type)]: {
            ...renamedData,
          },
          [includeOldName]: dataSelectedItems,
        },
      };
    }

    case actions.DATA_CONFIG_NAME_CHANGE: {
      const { name, newName, type, actionType } = action.payload;
      const { currentGroupValues } = state;
      if (actionType === "unGroup") {
        let dataSelectedItems = [];
        const renamedData = { ...state.data[renameName(type)] };
        const list = state[type].list
          .filter((item) => {
            if (item.text !== name) {
              return true;
            }
            return false;
          })
          .map((item) => {
            if (item.groupName === name) {
              dataSelectedItems.push(item.text);
              delete renamedData[item.text];
              return {
                ...item,
                groupName: undefined,
                hide: false,
              };
            }
            return item;
          });
        const includeOldName = includeNameItem(type);

        return {
          ...state,
          showUnGroupModal: false,
          [type]: {
            ...state[type],
            list,
            searchList: list,
            renamedFields: {
              ...renamedData,
            },
          },
          data: {
            ...state.data,
            [renameName(type)]: {
              ...renamedData,
            },
            [includeOldName]: dataSelectedItems,
          },
        };
      }
      if (!type) {
        return {
          ...state,
          showCreateGroupModal: false,
        };
      }

      if (actionType === "createGroup") {
        const combinedArray = combineGroupValues(state[type].list);
        const groupTotalCount = calculateGroupTotalCount(currentGroupValues, combinedArray);
        const searchList = state[type].searchList;

        const newCurrentGroupValues = currentGroupValues
          .filter((i) => !combinedArray.includes(i.text))
          .map((item) => item.text);

        const newList = updateListWithNewGroup(state[type].list, newCurrentGroupValues, combinedArray, newName);

        const groupNames = {};
        const includeOldName = includeNameItem(type);
        const excludeOldName = excludeNameItem(type);
        let dataSelectedItems = state.data[includeOldName];
        let dataExcludeOldName = state.data[excludeOldName];
        const newSearchList = searchList.map((item) => {
          if (newCurrentGroupValues.includes(item.text)) {
            groupNames[item.text] = newName;
            dataSelectedItems = dataSelectedItems.map((i) => {
              if (i === item.text) {
                return newName;
              }
              const { renamedFields = {} } = state[type];
              if (i === renamedFields[item.text]) {
                return newName;
              }
              return i;
            });
            dataExcludeOldName = dataExcludeOldName.map((i) => {
              if (i === item.text) {
                return newName;
              }
              const { renamedFields = {} } = state[type];
              if (i === renamedFields[item.text]) {
                return newName;
              }
              return i;
            });
            return {
              ...item,
              hide: true,
              groupName: newName,
            };
          }
          return item;
        });
        dataSelectedItems = _.uniq(dataSelectedItems);

        return {
          ...state,
          showCreateGroupModal: false,
          [type]: {
            ...state[type],
            list: [
              {
                id: shortid.generate(),
                groupValues: newCurrentGroupValues,
                text: newName,
                count: groupTotalCount,
                exclude: false,
                include:
                  String(action.payload.except) === "undefined" ? true : false,
                type: "group",
              },
              ...newList,
            ],
            searchList: [
              {
                id: shortid.generate(),
                groupValues: newCurrentGroupValues,
                text: newName,
                count: groupTotalCount,
                exclude: false,
                include: true,
                type: "group",
              },
              ...newSearchList,
            ],
            renamedFields: {
              ...state[type].renamedFields,
              ...groupNames,
            },
          },
          data: {
            ...state.data,
            [renameName(type)]: {
              ...state[type].renamedFields,
              ...groupNames,
            },
            reviewInvitationOnly: {
              name: newName,
              arr: state.data[includeOldName],
            },
            [includeOldName]: dataSelectedItems,
            [excludeOldName]: dataExcludeOldName,
          },
        };
      }
      let isGroup = false;

      const finnedItem = state[type].list.find((item) => {
        if (item.type === "group" && item.text === name) {
          return true;
        }
        return false;
      });

      if (finnedItem) {
        isGroup = true;
      }

      if (isGroup) {
        const groupNames = {};
        const list = state[type].list.map((item) => {
          if (item.text === name) {
            return {
              ...item,
              text: newName,
            };
          }
          if (item.groupName === name) {
            groupNames[item.text] = newName;
            return {
              ...item,
              groupName: newName,
            };
          }
          return {
            ...item,
          };
        });
        const includeOldName = includeNameItem(type);
        const excludeOldName = excludeNameItem(type);

        let dataSelectedItems = state.data[includeOldName].map((i) => {
          return name === i ? newName : i;
        });
        let dataExcludeOldName = state.data[excludeOldName].map((i) => {
          return name === i ? newName : i;
        });
        const searchList = state[type].searchList.map((item) => {
          if (item.text === name) {
            return {
              ...item,
              text: newName,
            };
          }
          if (item.groupName === name) {
            return {
              ...item,
              groupName: newName,
            };
          }
          return {
            ...item,
          };
        });

        return {
          ...state,
          showCreateGroupModal: false,
          [type]: {
            ...state[type],
            list: list,
            searchList: searchList,
          },
          data: {
            ...state.data,
            [renameName(type)]: {
              ...state[type].renamedFields,
              ...groupNames,
            },
            [includeOldName]: dataSelectedItems,
            [excludeOldName]: dataExcludeOldName,
          },
        };
      }

      const currentRenamedFields = { ...state[type].renamedFields };

      const includeOldName = includeNameItem(type);
      const excludeOldName = excludeNameItem(type);

      let dataSelectedItems = state.data[includeOldName].map((i) => {
        if (currentRenamedFields[name]) {
          return currentRenamedFields[name] === i ? newName : i;
        }
        return name === i ? newName : i;
      });
      let dataExcludeOldName = state.data[excludeOldName].map((i) => {
        if (currentRenamedFields[name]) {
          return currentRenamedFields[name] === i ? newName : i;
        }
        return name === i ? newName : i;
      });

      return {
        ...state,
        showCreateGroupModal: false,
        [type]: {
          ...state[type],
          renamedFields: {
            ...state[type].renamedFields,
            [name]: newName,
          },
        },
        data: {
          ...state.data,
          [renameName(type)]: {
            ...state[type].renamedFields,
            [name]: newName,
          },
          [includeOldName]: dataSelectedItems,
          [excludeOldName]: dataExcludeOldName,
        },
      };
    }
    default:
      return state;

    case actions.SET_INITIAL_DECISION_MODAL_VALUES: {
      return {
        ...state,
        initialDecisionModalValues: {
          ...action.values,
        },
      };
    }
    case actions.SET_ALL_REVISION_VALUES: {
      const choosenRevisionNumbers = [];
      return {
        ...state,
        manuscriptVersions: {
          ...state.manuscriptVersions,
          list: state.manuscriptVersions.list.map((i) => {
            if (i.text !== "No Revision Number") {
              choosenRevisionNumbers.push(i.value);
              return {
                ...i,
                include: true,
              };
            }
            return i;
          }),
        },
        data: {
          ...state.data,
          choosenRevisionNumbers,
        },
      };
    }
    case actions.CHANGE_DATE_RANGE: {
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        date: {
          ...state.date,
          start: startDate,
          end: endDate,
        },
        dateRange: {
          ...state.dateRange,
          entireStart: startDate,
          entireEnd: endDate,
          end: endDate,
        },
      };
    }
  }
};
